import { Dropdown } from './Dropdown'
import logo from '../assets/images/nees.png'
import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import { getLocalUserData } from '../services/localStorage'

interface MainHeaderProps {
  username?: string
  subtitles: string[]
}
export function MainHeader({ subtitles }: MainHeaderProps) {
  const userData = getLocalUserData()
  const username = userData?.nome_usuario
  const navigate = useNavigate()

  const goToHome = () => {
    navigate('/')
  }
  const goToFAQ = () => {
    navigate('/contato')
  }
  const ContatoButton = styled(Button)({
    fontWeight: 'bold',
    fontSize: '0.8rem',
    // width: '125px',
    textTransform: 'none',
    backgroundColor: 'white',
    color: '#404554',
    '&:hover': {
      backgroundColor: '#EDEEF2',
    }
  })
  
  return (
    <div id='header-0' className='hidden w-full	tablet:flex px-28 py-5'>
      <div id='header-1' className='flex w-full flex-col'>
        <div id='header-3' className='flex  justify-between'>
            <div id='header-4' className='flex items-center'>
              <img
                id='header-5'
                style={styles.imgHeader}
                src={logo}
                alt='plataforma-logo'
                onClick={() => goToHome()}
              />
              <p id='header-title' className='text-xl1 font-semibold ml-4 text-black'>
                Piloto Plataforma
              </p>
            </div>
            <div id='header-13' className='flex items-center'>
              <div className='flex'>
                <ContatoButton variant='text' onClick={goToFAQ}>Contato</ContatoButton>
              </div>
              <Dropdown username={username} />
            </div>
        </div>
        <div
          id='header-15'
          className='flex pt-5 items-center justify-start'
        >
          <div id='header-18' className='text ml-2x'>
            <div
              id='header-19'
              className='title text-xl py-2 font-medium'
            >
              Plataforma de Recuperação da Aprendizagem e Redução do Abandono
            </div>
            <div id='header-20' className='subtitle text-sm	'>
              {subtitles}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const styles = {
  imgHeader: {
    maxHeight: '2.75rem',
    width: '9rem',
    cursor: 'pointer',
  },
}
