import jwt_decode from 'jwt-decode'
import { getLocalToken } from './localStorage'

interface JWT {
  exp: number
}

export default function isAuth() {
  const token: string | null = getLocalToken();

  if (token) {
    try {
      const jwt: JWT = jwt_decode(token)
      if (Date.now() >= jwt.exp * 1000) return false
      return true
    } catch (err) {
      console.log(err)
    }
  }

  return false
}
