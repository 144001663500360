/**
 * CREATE HERE THE BASIC COMPONENTS LIKE INPUT FIELDS, FORMGROUPS,
 * LABELS, BUTTONS OR ANY COMPONENT THAT CAN BE REUSED IN ALL APP
 */

import styled from 'styled-components'
import { FormHelperText } from '@mui/material'
import { Error } from '@mui/icons-material'

export const InputText = styled.input`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;

  width: 100%;
  height: 40px;

  background: #f7f8fa;
  border-radius: 4px;

  flex: none;

  align-self: stretch;
  flex-grow: 0;
`

type label = {
  color?: string
  fontWeight?: number
}

export const Label = styled.label<label>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
  font-size: 14px;

  color: ${(props) => (props.color ? props.color : '#5e6475')};

  flex: none;
  order: 0;
`
type GroupProps = {
  mdFlexDirection?: string
  gap?: number
  maxHeight?: string
  children: any
  style?: any
  id?: any
}

export const Group = (props: GroupProps) => (
  <div
    id={props.id}
    className={`flex flex-col items-start mt-0 max-h-[${props.maxHeight}px] md:flex-${props.mdFlexDirection} gap-[${props.gap}px]`}
    style={props.style}
  >
    {props.children}
  </div>
)

type ErrorProps = {
  children?: any
  error?: any
  id?: any
}

export const ErrorMessage = (props: ErrorProps) => (
  <FormHelperText
    id={props.id}
    sx={{
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '12px',
      lineHeight: '133%',
      color: '#920000',

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px',
      gap: '4px',
    }}
  >
    {
      <Error
        sx={{ display: props.error ? '' : 'none' }}
        className='text-[15px]'
      />
    }{' '}
    {props.children}
  </FormHelperText>
)

type SubGroupProps = {
  children: any
  id?: any
}

export const SubGroup = (props: SubGroupProps) => (
  <div id={props.id} className='grid grid-cols-1 content-start w-[100%]'>
    {props.children}
  </div>
)

export const InputSelect = styled.select`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: #9ea1b0;

  width: 100%;
  height: 40px;

  background: #f7f8fa;
  border-radius: 4px;

  flex: none;

  align-self: stretch;
  flex-grow: 0;
`
export const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #000000;
`
