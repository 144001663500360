import { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'

import { handleFirstName } from '../utils/string'

import arrowDown from '../assets/icons/arrowDown.svg'
import profileIcon from '../assets/icons/profile.svg'
import { ChangingProfile } from './ChangeProfile'

interface DropdownProps {
  username: string
}

export function Dropdown({ username }: DropdownProps) {
  const navigate = useNavigate()
  const [userName, setUserName] = useState('')
  const [isHidden, setIsHidden] = useState(true)

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  const handleSignOut = () => {
    navigate('/logout')
  }

  const handleOpen = () => {
    setIsHidden(false)
  }
  const handleClose = () => {
    setIsHidden(true)
  }
  const toProfile = () => navigate('/profile', { state: { username } })

  useEffect(() => {
    setUserName(handleFirstName(username))
  }, [username])

  return (
    <Menu
      id='menu-0'
      as='div'
      className='relative inline-block text-left ml-[10%] tablet:ml-[20%]'
    >
      <ChangingProfile hidden={isHidden} close={handleClose}/>
      <Menu.Button
        id='menu-1'
        className='flex flex-row justify-center flex-center p-[4px] gap-[6px] items-center h-[40px] bg-[#083CA6] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-warm-vivid-70 rounded-full'
      >
        <div
          id='menu-2'
          className='flex justify-end items-center w-[32px] h-[32px] p-[4px] gap-[10px]'
        >
          <img id='menu-3' src={profileIcon} alt={`Usuário: ${userName}`} />
        </div>
        <p
          id='menu-4'
          className='text-white text-profile m-[6px] font-medium hidden md:text-sm md:block'
        >
          {userName}
        </p>
        <div
          id='menu-5'
          className='flex justify-start items-center w-[32px] h-[32px] p-[4px] gap-[10px]'
        >
          <img id='menu-6' src={arrowDown} alt='Ícone de seta' className='' />
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          id='menu-8'
          className='origin-top-right absolute right-0 mt-2 w-56 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'
        >
          <div>
            <Menu.Item>
              {({ active }) => (
                <div
                  id='menu-10'
                  onClick={toProfile}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left py-2 text-sm rounded flex flex-row justify-start items-center cursor-pointer'
                  )}
                >
                  <svg
                    id='menu-11'
                    className='ml-5'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      id='menu-12'
                      d='M2 9.33333C2 8 4.66667 7.26667 6 7.26667C7.33333 7.26667 10 8 10 9.33333V10H2V9.33333ZM8 4C8 4.53043 7.78929 5.03914 7.41421 5.41421C7.03914 5.78929 6.53043 6 6 6C5.46957 6 4.96086 5.78929 4.58579 5.41421C4.21071 5.03914 4 4.53043 4 4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2C6.53043 2 7.03914 2.21071 7.41421 2.58579C7.78929 2.96086 8 3.46957 8 4ZM0 1.33333V10.6667C0 11.0203 0.140476 11.3594 0.390524 11.6095C0.640573 11.8595 0.979711 12 1.33333 12H10.6667C11.0203 12 11.3594 11.8595 11.6095 11.6095C11.8595 11.3594 12 11.0203 12 10.6667V1.33333C12 0.979711 11.8595 0.640573 11.6095 0.390524C11.3594 0.140476 11.0203 0 10.6667 0H1.33333C0.593333 0 0 0.6 0 1.33333Z'
                      fill='black'
                    />
                  </svg>
                  <button id='menu-13' type='button' className='ml-3'>
                    Gerenciar Perfil
                  </button>
                </div>
              )}
            </Menu.Item>
          </div>
          
          <div>
            <Menu.Item>
              {({ active }) => (
                <div
                  id='menu-14'
                  onClick={handleOpen}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left py-2 text-sm rounded flex flex-row justify-start items-center cursor-pointer'
                  )}
                >
                  
                    <svg 
                      id="menu-15" 
                      className='ml-5'
                      width="13.3" 
                      height="14.6" 
                      viewBox="0 0 21 22" 
                      fill="none" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="menu-16"
                        d="M14.5 8C20.5 8 20.5 12 20.5 12V14H14.5V12C14.5 12 14.5 10.31 13.35 8.8C13.18 8.57 12.97 8.35 12.75 8.14C13.27 8.06 13.84 8 14.5 8ZM0.5 12C0.5 12 0.5 8 6.5 8C12.5 8 12.5 12 12.5 12V14H0.5V12ZM7.5 16V18H13.5V16L16.5 19L13.5 22V20H7.5V22L4.5 19L7.5 16ZM6.5 0C4.84 0 3.5 1.34 3.5 3C3.5 4.66 4.84 6 6.5 6C8.16 6 9.5 4.66 9.5 3C9.5 1.34 8.16 0 6.5 0ZM14.5 0C12.84 0 11.5 1.34 11.5 3C11.5 4.66 12.84 6 14.5 6C16.16 6 17.5 4.66 17.5 3C17.5 1.34 16.16 0 14.5 0Z" 
                        fill="black"
                      />
                    </svg>
                    <span className="ml-3">Alternar perfil</span> 
                </div>
              )}
            </Menu.Item>
          </div>
          <div id='menu-18'>
            <Menu.Item>
              {({ active }) => (
                <div
                  id='menu-19'
                  onClick={handleSignOut}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left py-2 text-sm rounded flex flex-row justify-start items-center cursor-pointer'
                  )}
                >
                  <svg
                    id='menu-20'
                    className='ml-5'
                    width='12'
                    height='12'
                    viewBox='0 0 12 12'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      id='menu-21'
                      d='M10.6667 0H1.33333C0.593333 0 0 0.593333 0 1.33333V4H1.33333V1.33333H10.6667V10.6667H1.33333V8H0V10.6667C0 11.0203 0.140476 11.3594 0.390524 11.6095C0.640573 11.8595 0.979711 12 1.33333 12H10.6667C11.0203 12 11.3594 11.8595 11.6095 11.6095C11.8595 11.3594 12 11.0203 12 10.6667V1.33333C12 0.593333 11.4 0 10.6667 0ZM4.72 8.38667L5.66667 9.33333L9 6L5.66667 2.66667L4.72 3.60667L6.44667 5.33333H0V6.66667H6.44667L4.72 8.38667Z'
                      fill='black'
                    />
                  </svg>

                  <button id='menu-22' type='button' className='ml-3'>
                    Sair
                  </button>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
