import { useState, useEffect, useLayoutEffect } from 'react';

import { Box, ToggleButtonGroup, Typography } from "@mui/material";

import { getCustom, putGuest } from '../../../../../api/user';

import { knowledgeArea } from '../../../../../utils/knowledgeArea';
import { getClassesData, getFilteredSchoolsData } from '../../../../../api/school';
import { brazilStates } from '../../../../../utils/states';

import { allProfileProps } from './AcceptProfiles';
import { SaveButton, BackButton, ToggleButton } from './styles'
import { Alert } from '../../../../../components/Alert';
import { getLocalUserData } from '../../../../../services/localStorage';
 
interface EditInfoProps {
  open: boolean;
  handleClose: () => void;
  setProfilesData: (data: allProfileProps) => void;
  data: allProfileProps | undefined;
  loggedUserId: string;
}

export function EditInfo({ open, handleClose, setProfilesData, data, loggedUserId } : EditInfoProps) {
  const userData = getLocalUserData()

  const [schools, setSchools] = useState('')
  const [schoolsData, setSchoolsData] = useState<any[]>([])
  const [schoolsDisabled, setSchoolsDisabled] = useState(false)

  const [profiles, setProfiles] = useState('')

  const [classes, setClasses] = useState('')
  const [classesData, setClassesData] = useState([])

  const [profilesAndSchoolsData, setProfilesAndSchoolsData] = useState<any>([])
  const [filteredProfiles, setFilteredProfiles] = useState<any>(profilesAndSchoolsData)

  const [currentState, setCurrentState] = useState('')
  const [stateDisabled, setStateDisabled] = useState(false)

  const [currentCity, setCurrentCity] = useState('')
  const [cityDisabled, setCityDisabled] = useState(false)

  const [statesAndCities, setStatesAndCities] = useState<any>([])

  const currentDependency: string[] = ["Federal", "Estadual", "Municipal"]
  const currentFunction: string[] = ["Escolar", "Gestão"]
  const currentProfile: any = profilesAndSchoolsData.find((profile: any) => profile.id === Number(profiles))

  const [depedencyAlignment, setDepedencyAlignment] = useState<string>('left')
  const [functionAlignment, setFunctionAlignment] = useState<string>('left')

  const [blockChange, setBlockChange] = useState(false)

  const [field, setField] = useState('')

  const handleDepedencyChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setDepedencyAlignment(newAlignment);
  }

  const handleFunctionChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setFunctionAlignment(newAlignment);
  }

  const depedencyControl = {
    value: depedencyAlignment,
    onChange:  handleDepedencyChange,
    exclusive: true,
    className: "justify-between gap-2",
  }

  const depedencyChildren = [
    <ToggleButton value="1" key="1" className="rounded border border-neutral-light-1 py-3 px-9 w-full" disableRipple>
      <span className="font-bold text-sm normal-case">Federal</span>
    </ToggleButton>,
    <ToggleButton value="2" key="2" className="rounded border border-neutral-light-1 py-3 px-9 w-full" disableRipple>
      <span className="font-bold text-sm normal-case">Estadual</span>
    </ToggleButton>,
    <ToggleButton value="3" key="3" className="rounded border border-neutral-light-1 py-3 px-9 w-full" disableRipple>
      <span className="font-bold text-sm normal-case">Municipal</span>
    </ToggleButton>,
  ]

  const functionControl = {
    value: functionAlignment,
    onChange:  handleFunctionChange,
    exclusive: true,
    className: "justify-between gap-2",
  }

  const functionChildren = [
    <ToggleButton value="1" key="1" className="rounded border border-neutral-light-1 py-3 px-9 w-full" disableRipple>
      <span className="font-bold text-sm normal-case">Escolar</span>
    </ToggleButton>,
    <ToggleButton value="2" key="2" className="rounded border border-neutral-light-1 py-3 px-9 w-full" disableRipple>
      <span className="font-bold text-sm normal-case">Gestão</span>
    </ToggleButton>,
  ]

  const handleSetFixedAlignment = () => {
    let finalAlignment: string = depedencyAlignment
    
    if(currentProfile?.hasFederalAccess || currentProfile?.hasNationalAccess) {
      finalAlignment = '1'
      setBlockChange(true)
    } else if(currentProfile?.hasStateAccess) {
      finalAlignment = '2'
      setBlockChange(true)
    } else if(currentProfile?.hasCityAccess) {
      finalAlignment = '3'
      setBlockChange(true)
    } else {
      setBlockChange(false)
    }

    setDepedencyAlignment(finalAlignment)
  }

  const handleSetSchools = () => {
    const selectElement: any = document.getElementById('schools')
    const selectedOption = selectElement.options[selectElement.selectedIndex]

    setSchools(selectedOption.value)
  }

  const handleSetClasses = () => {
    const selectElement: any = document.getElementById('classes')
    const selectedOption = selectElement.options[selectElement.selectedIndex]

    setClasses(selectedOption.value)
  }

  const handleSetProfileData = () => {
    let schoolId = ''
    if(currentProfile?.hasNationalAccess || currentProfile?.hasFederalAccess || currentProfile?.hasStateAccess || currentProfile?.hasCityAccess) {
      schoolId = '1'
    } else {
      schoolId = !currentProfile?.hasSchoolAccess ? '2' : schools
    }
    
    let newData: allProfileProps = {} as allProfileProps
    newData = {
      dependencia_administrativa: currentProfile?.hasNationalAccess ? "Nacional" : currentDependency[+depedencyAlignment - 1],
      area_conhecimento: data?.area_conhecimento || '',
      cpf_visitante: data?.cpf_visitante || '',
      data_visita: data?.data_visita || '',
      estado: currentState,
      municipio: currentCity,
      nome: data?.nome || 'Batata',
      id_escola: {
        id_escola: +schoolId,
        nome_escola: schoolsData.find((school: any) => school.id === +schools)?.name || '',
      },
      id_perfil: {
        id_perfil: +profiles,
        descricao_perfil: profilesAndSchoolsData.find((profile: any) => profile.id === +profiles)?.profile_name || '',
      },
      id_visitante: data?.id_visitante || 0,
    }
    
    putGuest({
      school: +schoolId,
      idProfile: newData.id_perfil.id_perfil,
      state: newData.estado,
      city: newData.municipio,
      guestId: newData.id_visitante,
      id_usuario_aprovou: loggedUserId,
    }).then((res) => {
      setProfilesData(newData)
      Alert({
        type: 'success',
        message: 'Perfil atualizado com sucesso!',
        autoClose: true,
      })
    }).catch((err) => {
      Alert({
        type: 'error',
        message: 'Não foi possível atualizar o perfil!',
        autoClose: true,
      })
    })

    handleClose();
  }
  
  const handleSetStatesAndCities = (schools: any) => {
    let newStatesAndCities: any = [...statesAndCities]

    schools.forEach((school: any) => {
      newStatesAndCities.push({
        "initiais": school.uf,
        "cities": []
      })
    })

    newStatesAndCities = [...new Map(newStatesAndCities.map((item: any, index: any) => [item.initiais, item])).values()] //Remove duplicates

    schools.forEach((school: any) => {
      newStatesAndCities.forEach((state: any) => {
        if(state.initiais === school.uf) {
          state.cities.push(school.municipio)
        }
      })
    })
    
    setStatesAndCities(newStatesAndCities)
  }

  const filterByProfileType = () => {
    const currentFunctionType = currentFunction.at(+functionAlignment - 1)

    const filteredProfiles = profilesAndSchoolsData.filter((profile: any) => {
      return profile.gestao ? currentFunctionType === 'Gestão' : currentFunctionType === 'Escolar';
    })

    return filteredProfiles
  }

  const resetSelectValues = () => {
    setSchools('')
    setCurrentState('')
    setCurrentCity('')
    setClasses('')
  }

  const resetAllSelectValues = () => {
    setSchools('')
    setCurrentState('')
    setCurrentCity('')
    setClasses('')
    setProfiles('')
  }

  useEffect(() => {
    getCustom(data?.cpf_visitante || '').then((data: any) => {
      const newProfiles = data.map((item: any) => {
        const schools = item.escolas
        handleSetStatesAndCities(schools)

        return {
          id: item.perfil.id_perfil,
          profile_name: item.perfil.descricao_perfil,
          hasNationalAccess: item.perfil.acesso_nacional,
          hasFederalAccess: item.perfil.acesso_federal,
          hasStateAccess: item.perfil.acesso_estadual,
          hasCityAccess: item.perfil.acesso_municipal,
          hasSchoolAccess: item.perfil.acesso_escolas,
          gestao: item.perfil.gestao,
          schools,
        }
      })

      const newFilteredProfiles = newProfiles.filter(function (el: any) {
        return el !== null;
      });

      setProfilesAndSchoolsData(newFilteredProfiles)
      setFilteredProfiles(newFilteredProfiles)
    })
  }, [])

  useEffect(() => {
    resetSelectValues()
  }, [profiles])

  useEffect(() => {
    resetAllSelectValues()
  }, [functionAlignment])

  useEffect(() => {
    getClassesData().then((data) => {
      const newClasses = data.results.map((classes: any) => {
        return {
          id: classes.id_turma,
          name: classes.nome_turma,
          schoolId: classes.id_escola,
        }
      })

      setClassesData(newClasses)
    })
  }, [])

  // Refactor this by getting from an upper component ✅
  useEffect(() => {
    if( (userData.dados[0].acesso_escolas) && (!userData.dados[0].acesso_federal && !userData.dados[0].acesso_nacional) ) {

      if(userData.dados[0].acesso_estadual) {
        setCurrentState(userData.dados[0].uf)
        setStateDisabled(true)
      } else if(userData.dados[0].acesso_municipal) {
        setCurrentState(userData.dados[0].uf)
        setStateDisabled(true)

        setCurrentCity(userData.dados[0].municipio)
        setCityDisabled(true)
      } else if(userData.dados[0].escolas != null) {
        setCurrentState(userData.dados[0].uf)
        setStateDisabled(true)

        setCurrentCity(userData.dados[0].municipio)
        setCityDisabled(true)

        setSchools(userData.dados[0].escolas)
        setSchoolsDisabled(true)
      }
    }
  }, [profiles, currentCity, currentState])

  useEffect(() => {
    setSchools('')
  }, [schoolsData, currentState, currentCity, depedencyAlignment])

  useEffect(() => {
    resetAllSelectValues()
    const newFilteredProfiles = filterByProfileType()

    setFilteredProfiles(newFilteredProfiles)
  }, [functionAlignment])

  useLayoutEffect(() => {
    handleSetFixedAlignment();
    resetSelectValues()
  }, [profiles])

  useEffect(() => {
    setSchoolsData([])
    
    if ((currentCity !== '' && currentState !== '') && depedencyAlignment !== 'left') {
      const fields = "id_escola, nome_escola, uf, municipio, dependencia_administrativa";
      getFilteredSchoolsData({
        uf: currentState, 
        municipio: currentCity, 
        dependency :currentDependency[+depedencyAlignment - 1],
        fields: fields
      }).then((data) => {
        const schools = data.results.map((item: any) => {
          return {
            id: item.id_escola,
            name: item.nome_escola,
            state: item.uf,
            city: item.municipio,
            dependency: item.dependencia_administrativa
          }
        })

        schools.sort((a: any, b: any) => {
          return a.name > b.name ? 1 : -1
        })

        setSchoolsData(schools)
      })
    }
  }, [currentCity, currentState, depedencyAlignment])

  return (
    <div className='flex flex-col gap-4 mt-6'>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography 
          component="h3" 
          sx={{ 
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '150%', 
          }}>
          Informações adicionais
        </Typography>
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <BackButton onClick={() => handleClose()}>
            Cancelar
          </BackButton>
          <SaveButton onClick={() => handleSetProfileData()}>
            Salvar
          </SaveButton>
        </Box>
      </Box>
      <div className='flex flex-col'>
        <label className='text-primitive-500 text-sm font-normal'>
          Função
        </label>
        <ToggleButtonGroup sx={{ marginTop: '8px' }} {...functionControl}>
          {functionChildren}
        </ToggleButtonGroup>
      </div>
      <div className='flex flex-col'>
        <label className='text-primitive-500 text-sm font-normal'>
          Perfil
        </label>
        <select
          id="select-profile"
          className={`mt-2 px-4 py-2 text-sm bg-primitive-50 first-of-type:text-neutral-base appearance-none bg-arrowDown bg-[right_22px_center] bg-no-repeat focus:outline-none`}
          onChange={(e) => setProfiles(e.target.value)}
          value={profiles}
        >
          <option value="default" className="text-neutral-base">Escolha o seu perfil</option>
          {filteredProfiles.map((profile: any, index: number) => {
            return (
              <option key={index} value={profile.id+""}>{profile.profile_name}</option>
            )
          })}
        </select>
      </div>
      <div className='flex flex-col'>
        <label className='text-primitive-500 text-sm font-normal'>
          Aréa do conhecimento
        </label>
        <select 
          id="select-field"
          className={`mt-2 px-4 py-2 text-sm bg-primitive-50 rounded first-of-type:text-neutral-base appearance-none bg-arrowDown bg-[right_22px_center] bg-no-repeat focus:outline-none`}
          onChange={(e) => setField(e.target.value)}
          value={field}
        >
          <option value="default" className="text-neutral-base">Escolha a sua área de conhecimento</option>
          {knowledgeArea.map((area: string, index: number) => {
            return (
              <option key={index} value={index}>{area}</option>
            )
          })}
        </select>
      </div>

      <div className='flex flex-col'>
        <label className='text-primitive-500 text-sm font-normal'>
          Dependência administrativa
        </label>
        <ToggleButtonGroup sx={{ marginTop: '8px' }} disabled={blockChange} {...depedencyControl}>
          {depedencyChildren}
        </ToggleButtonGroup>
      </div>
      <form className='flex flex-col justify-start bg-white rounded-md'>
        <div className='flex-1'>
          {profiles ? (
            <>
              {(currentProfile?.hasStateAccess || currentProfile?.hasCityAccess || currentProfile?.hasSchoolAccess) && !currentProfile?.hasNationalAccess && !currentProfile?.hasFederalAccess ? (
                <>
                  <div className=' mt-2 flex flex-row justify-between relative'>
                    <div className='flex flex-col flex-1'>
                      <span className='text-sm text-[#5E6475]'>
                        Estado
                      </span>
                      <select
                        id='states'
                        className='mt-2 px-4 py-2 text-user w-full bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0] appearance-none bg-arrowDown bg-[right_22px_center] bg-no-repeat focus:outline-none'
                        onChange={(e) =>
                          setCurrentState(e.target.value)
                        }
                        value={currentState}
                        disabled={stateDisabled}
                      >
                        <option value='' className='text-sm'>
                          Selecione o estado
                        </option>
                        {brazilStates.states.map((state, index) => {
                          return (
                            <option
                              key={index}
                              value={state.initials}
                            >
                              {state.initials}
                            </option>
                          )}
                        )}
                      </select>
                    </div>
                  
                    {(currentProfile?.hasCityAccess || currentProfile?.hasSchoolAccess) && !currentProfile?.hasStateAccess ? (<div className='flex flex-col ml-6 flex-1'>
                      <span className='text-sm text-[#5E6475]'>
                        Município
                      </span>
                      <select
                        id='profile'
                        className='mt-2 px-4 py-2 text-user w-full bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0] appearance-none bg-arrowDown bg-[right_22px_center] bg-no-repeat focus:outline-none'
                        onChange={(e) => {
                          setCurrentCity(e.target.value)
                        }}
                        value={currentCity}
                        disabled={cityDisabled}
                      >
                        <option value='' className='text-sm'>
                          Selecione o município
                        </option>
                        {brazilStates.states.map((state: any, index: any) => {
                          return state.initials === currentState
                            ? (state.cities.map((city: any, idx: any) => {
                                return (
                                  <option
                                    key={idx}
                                    value={city}
                                  >
                                    {city}
                                  </option>
                                )
                              }))
                            : null
                          }
                        )}
                      </select>
                    </div>) : null}
                  </div>
                  {schoolsData.length > 0 && currentCity && currentState && currentProfile?.hasSchoolAccess && !currentProfile?.hasCityAccess && !currentProfile?.hasStateAccess && !currentProfile?.hasFederalAccess && (
                    <div className='flex flex-col mt-2'>
                      <label className='text-[#5E6475] text-sm'>
                        Escolas
                      </label>
                      <select
                        id='schools'
                        className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0] appearance-none bg-arrowDown bg-[right_22px_center] bg-no-repeat focus:outline-none'
                        onChange={(e) => handleSetSchools()}
                        value={schools}
                        disabled={schoolsDisabled}
                      >
                        <option value='' className=''>
                          Selecione a escola
                        </option>
                        {profilesAndSchoolsData.map(
                          (profile: any, index: React.Key) => {
                            return profile.id.toString() ===
                              profiles && profile.hasAccess ? (
                              <option
                                key={index}
                                className='text-[#9EA1B0]'
                                value={'1'}
                              >
                                TODAS
                              </option>
                            ) : null
                          }
                        )}

                        {profilesAndSchoolsData.map(
                          (profile: any, index: React.Key) => {
                            return profile.id.toString() ===
                              profiles && !profile.hasNationalAccess
                              ? schoolsData.map(
                                  (
                                    school: any,
                                    idx: React.Key
                                  ) => {
                                    return (
                                      <option
                                        key={idx}
                                        className='text-[#9EA1B0]'
                                        value={school.id}
                                      >
                                        {school.name}
                                      </option>
                                    )
                                  }
                                )
                              : null
                          }
                        )}
                      </select>
                    </div>
                  )}

                  {profiles === '23' ? (
                    <div className='flex flex-col mt-2'>
                      <label className='text-[#5E6475] text-sm'>
                        Turmas
                      </label>
                      <select
                        id='classes'
                        className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0] appearance-none bg-arrowDown bg-[right_22px_center] bg-no-repeat focus:outline-none'
                        onChange={(e) => handleSetClasses()}
                        value={classes}
                      >
                        <option value='' className=''>
                          Selecione a turma
                        </option>
                        {classesData.map(
                          (clas: any, index: React.Key) => {
                            return clas.schoolId.toString() ===
                              schools.toString() ? (
                              <option
                                key={index}
                                className='text-[#9EA1B0]'
                                value={clas.id}
                              >
                                {clas.name}
                              </option>
                            ) : null
                          }
                        )}
                      </select>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          ): null}
        </div>
      </form>
    </div>
  )
}