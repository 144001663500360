import { ISchemaFormData, IStudents } from "../types";

export const serializeStudent = {
  toFormDate: (dataStudent: IStudents) => {
    if (!Object.keys(dataStudent).length) return {} as ISchemaFormData;
    const { nome_turma } = dataStudent;
    const nameClass = nome_turma?.replace(/[\W\d]/g, "");
    const year = nome_turma?.replace(/[A-Za-z\s]/g, "");
    return {
      ...dataStudent,
      ano: year,
      nome_turma: nameClass,
    } as ISchemaFormData;
  },
  toDataStudent: (formData: ISchemaFormData) => {
    if (!Object.keys(formData).length) return {} as IStudents;

    const nameClass = `${formData.ano} ${formData.nome_turma}`;
    const data = { ...formData } as Omit<ISchemaFormData, "ano">;
    return {
      ...data,
      nome_turma: nameClass,
    } as IStudents;
  },
};
