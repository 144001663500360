import { Fragment, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'

import { Dialog, Transition } from '@headlessui/react'

import { Alert } from '../../../../components/Alert'

import closeIcon from '../../../../assets/icons/blackCloseIcon.svg'

import { getSystemsData } from '../../../../api/system'
import { linkUserToProfile } from '../../../../api/profile'
import { getClassesData, getFilteredSchoolsData } from '../../../../api/school'
import { getCustom, getUserData } from '../../../../api/user'

import { brazilStates } from "../../../../utils/states"
import { getLocalUserData } from '../../../../services/localStorage'

interface systemProps {
  id: number
  name: string
}

export function LinkUser(name: string, userId: string) {
  return new Promise((resolve, reject) => {
    addDialog(resolve, name, userId)
  })
}

function removeDialog(root: any) {
  root.unmount()

  const div = document.getElementById('modal-link-user')
  if (div) {
    div.remove()
  }
}

function addDialog(resolve: any, name: string, userId: string) {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')

  div.setAttribute('id', 'modal-link-user')
  body.appendChild(div)

  const root = createRoot(div)

  root.render(
    <CreateDialog root={root} resolve={resolve} name={name} userId={userId} />
  )
}

function CreateDialog(dialogObj: {
  root: any
  resolve: any
  name: string
  userId: string
}) {
  const userData = getLocalUserData()
  
  const [newUser, setNewUser] = useState(false)
  const [isLinkUserInfoOpen] = useState(true)

  const [system, setSystem] = useState('')
  const [systemsData, setSystemsData] = useState<systemProps[]>([
    { id: 0, name: 'Selecione o sistema' },
  ])

  const [isDisabled, setIsDisabled] = useState(true)

  const [schools, setSchools] = useState('')
  const [schoolsData, setSchoolsData] = useState([])
  const [schoolsDisabled, setSchoolsDisabled] = useState(false)

  const [profiles, setProfiles] = useState('')

  const [classes, setClasses] = useState('')
  const [classesData, setClassesData] = useState([])

  const [profilesAndSchoolsData, setProfilesAndSchoolsData] = useState<any>([])

  const [currentState, setCurrentState] = useState('')
  const [stateDisabled, setStateDisabled] = useState(false)

  const [currentCity, setCurrentCity] = useState('')
  const [cityDisabled, setCityDisabled] = useState(false)

  const [statesAndCities, setStatesAndCities] = useState<any>([])

  const currentProfile: any = profilesAndSchoolsData.find((profile: any) => profile.id === Number(profiles))
  //profilesData.find((profile: any) => profile.id === Number(profiles))

  const handleSetSchools = () => {
    const selectElement: any = document.getElementById('schools')
    const selectedOption = selectElement.options[selectElement.selectedIndex]

    setSchools(selectedOption.value)
  }

  const handleSetClasses = () => {
    const selectElement: any = document.getElementById('classes')
    const selectedOption = selectElement.options[selectElement.selectedIndex]

    setClasses(selectedOption.value)
  }

  const handleClose = () => {
    removeDialog(dialogObj.root)

    dialogObj.resolve()
  }

  const handleLink = () => {
    removeDialog(dialogObj.root)

    let classname: string = ''
    let school = ''

    if(currentProfile?.hasNationalAccess || currentProfile?.hasFederalAccess || currentProfile?.hasStateAccess || currentProfile?.hasCityAccess) {
      school = '1'
    } else {
      school = !currentProfile?.hasSchoolAccess ? '2' : schools
    }

    classesData.forEach((item: any) => {
      if (item.id.toString() === classes) {
        classname = item.name
      }
    })

    const studentData = {
      name: dialogObj.name,
      classId: classes,
      className: classname,
      id: dialogObj.userId,
    }
    
    linkUserToProfile({
      profileId: profiles,
      id: userData.cpf,
      userId: dialogObj.userId,
      schoolId: school,
      city: currentCity,
      state: currentState,
    })
      .then((res: any) => {
        Alert({
          type: 'success',
          message: 'Usuário vinculado com sucesso!',
          autoClose: true,
        })

        dialogObj.resolve({
          changed: profiles === '23' ? true : false,
          data: studentData,
        })
      })
      .catch((err: any) => {
        const errorMessage = err.response.data.message

        Alert({
          type: 'error',
          message: errorMessage || 'Ops! Ocorreu um erro ao vincular o usuário. Tente novamente mais tarde.',
          autoClose: true,
        })

        dialogObj.resolve([{ changed: false }])
      })
  }

  const handleSetStatesAndCities = (schools: any) => {
    let newStatesAndCities: any = [...statesAndCities]

    schools.forEach((school: any) => {
      newStatesAndCities.push({
        "initiais": school.uf,
        "cities": []
      })
    })

    newStatesAndCities = [...new Map(newStatesAndCities.map((item: any, index: any) => [item.initiais, item])).values()] //Remove duplicates

    schools.forEach((school: any) => {
      newStatesAndCities.forEach((state: any) => {
        if(state.initiais === school.uf) {
          state.cities.push(school.municipio)
        }
      })
    })
    
    setStatesAndCities(newStatesAndCities)
  }

  const getAllModules = (item: any) => {
    let modules: string[] = []

    item.modulos.forEach((module: any) => {
      modules.push(module)
    })
    
    return modules
  }

  const resetSelectValues = () => {
    setSchools('')
    setCurrentState('')
    setCurrentCity('')
    setClasses('')
  }

  const resetAllSelectValues = () => {
    setSchools('')
    setCurrentState('')
    setCurrentCity('')
    setClasses('')
    setProfiles('')
  }

  useEffect(() => {
    if(system) {
      getCustom(dialogObj.userId).then((data: any) => {
        const newProfiles = data.map((item: any) => {
          
          const schools = item.escolas
          const currentSystem = systemsData.filter((item: any) => item.id === Number(system))[0].name

          let allModules: string[] = getAllModules(item)
          handleSetStatesAndCities(item.escolas)

          return allModules.includes(currentSystem) ? {
            id: item.perfil.id_perfil,
            profile_name: item.perfil.descricao_perfil,
            hasNationalAccess: item.perfil.acesso_nacional,
            hasFederalAccess: item.perfil.acesso_federal,
            hasStateAccess: item.perfil.acesso_estadual,
            hasCityAccess: item.perfil.acesso_municipal,
            hasSchoolAccess: item.perfil.acesso_escolas,
            schools,
          } : null
        })

        var filteredProfiles = newProfiles.filter(function (el: any) {
          return el !== null;
        });

        setProfilesAndSchoolsData(filteredProfiles)
      })
    }
  }, [dialogObj.userId, system])

  useEffect(() => {
    resetSelectValues()
  }, [profiles])

  useEffect(() => {
    resetAllSelectValues()
  }, [system])

  useEffect(() => {
    if (currentCity !== '' && currentState !== '') {
      getFilteredSchoolsData({
        municipio: currentCity,
        uf: currentState,
        modulo: systemsData[+system - 1]?.name
      }).then((data) => {
        let schools = data.results.map((item: any) => {
          return {
            id: item.id_escola,
            name: item.nome_escola,
            state: item.uf,
            city: item.municipio,
          }
        })

        schools.sort((a: any, b: any) => {
          return a.name > b.name ? 1 : -1
        })

        setSchoolsData(schools)
      })
    }
  }, [currentCity, currentState])

  useEffect(() => {
    getClassesData().then((data) => {
      const newClasses = data.results.map((classes: any) => {
        return {
          id: classes.id_turma,
          name: classes.nome_turma,
          schoolId: classes.id_escola,
        }
      })

      setClassesData(newClasses)
    })
  }, [])

  useEffect(() => {
    getSystemsData(true).then((data) => {
      const newSystems = data.results.map((system: any) => {
        return {
          id: system.id_modulo,
          name: system.nome_modulo,
        }
      })

      setSystemsData(newSystems)
    })

    getUserData(dialogObj.userId).then((data: any) => {
      data.perfil.length > 0 ? setNewUser(false) : setNewUser(true)
    })
  }, [])

  useEffect(() => {
    if( (userData.dados[0].acesso_escolas) && (!userData.dados[0].acesso_federal && !userData.dados[0].acesso_nacional) ) {
      if(userData.dados[0].acesso_estadual) {
        setCurrentState(userData.dados[0].uf)
        setStateDisabled(true)
      } else if(userData.dados[0].acesso_municipal) {
        setCurrentState(userData.dados[0].uf)
        setStateDisabled(true)

        setCurrentCity(userData.dados[0].municipio)
        setCityDisabled(true)
      } else if(userData.dados[0].escolas != null) {
        setCurrentState(userData.dados[0].uf)
        setStateDisabled(true)

        setCurrentCity(userData.dados[0].municipio)
        setCityDisabled(true)

        setSchools(userData.dados[0].escolas)
        setSchoolsDisabled(true)
      }
    }
  }, [profiles, currentCity, currentState])

  useEffect(() => {
    if((currentProfile?.hasFederalAccess || currentProfile?.hasNationalAccess) || (!currentProfile?.hasFederalAccess && !currentProfile?.hasNationalAccess && !currentProfile?.hasStateAccess && !currentProfile?.hasCityAccess && !currentProfile?.hasSchoolAccess)) {
      if(system !== '' && profiles !== '') {
        setIsDisabled(false)
      }
    } else {
      setIsDisabled(true)

      if(currentProfile?.hasStateAccess) {
        if(system !== '' && profiles !== '' && currentState !== '') {
          setIsDisabled(false)
        }
      } else {
        setIsDisabled(true)

        if(currentProfile?.hasCityAccess) {
          if(system !== '' && profiles !== '' && currentState !== '' && currentCity !== '') {
            setIsDisabled(false)
          }
        } else {
          setIsDisabled(true)

          if(currentProfile?.hasSchoolAccess) {
            if(system !== '' && profiles !== '' && currentState !== '' && currentCity !== '' && schools !== '') {
              setIsDisabled(false)
            }
          } else {
            setIsDisabled(true)
          }
        }       
      }
    }

  },[system, profiles, schools, currentState, currentCity, classes, currentProfile])

  useEffect(() => {
    setSchools('')
  }, [schoolsData])

  return (
    <section>
      {isLinkUserInfoOpen ? (
        <Transition.Root show as='div'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-[#14161F]  bg-opacity-40 transition-opacity' />
          </Transition.Child>

          <Dialog
            as='div'
            className='w-full z-10 relative'
            onClose={() => {return false}}
            id='basic'
          >
            <div className='fixed z-10 inset-0 overflow-y-auto'>
              <div className='flex items-end sm:items-center justify-center min-h-full text-center sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  leave='ease-in duration-200'
                >
                  <Dialog.Panel className='relative w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-full'>
                    <div className='bg-white'>
                      <div className='sm:flex sm:items-start'>
                        <div className='text-center sm:text-left w-full'>
                          <div className='flex flex-row justify-between mx-8 mt-8'>
                            <Dialog.Title
                              as='h3'
                              className='text-lg font-medium'
                            >
                              Vincular usuário
                            </Dialog.Title>
                            {!newUser ? (
                              <img
                              id='close_user'
                              className='cursor-pointer'
                              src={closeIcon}
                              alt='Imagem de um x'
                              onClick={handleClose}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <form className='flex flex-col justify-start bg-white rounded-md mt-12 ml-8'>
                      <p className='text-profile mr-8'>
                        Defina a quais sistemas o usuário terá acesso, assim
                        como qual a sua função em cada um deles:
                      </p>
                      <div className='border #EDEEF2 box-border flex-1 mt-4 mr-10 p-4'>
                        <div className='flex flex-col'>
                          <label className='text-[#5E6475] text-sm'>
                            Sistema
                          </label>
                          <select
                            name='system'
                            className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                            onChange={(e) => setSystem(e.target.value)}
                            value={system}
                          >
                            <option value='' className=''>
                              Selecione o sistema
                            </option>
                            {systemsData.map(
                              (system: systemProps, index: React.Key) => {
                                return index !== 4 &&  (
                                  <option
                                    key={index}
                                    className='text-[#9EA1B0]'
                                    value={system.id}
                                  >
                                    {system.name.toUpperCase()}
                                  </option>
                                )
                              }
                            )}
                          </select>
                        </div>

                        {system && (
                          <>
                            <div className='flex flex-col mt-2'>
                              <label className='text-[#5E6475] text-sm'>
                                Função do usuário
                              </label>
                              <select
                                name='user-function'
                                className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                                onChange={(e) => setProfiles(e.target.value)}
                                value={profiles}
                              >
                                <option value='' className=''>
                                  Selecione a função do usuário
                                </option>
                                {profilesAndSchoolsData.map(
                                  (profile: any, index: React.Key) => {
                                    return (
                                      <option
                                        key={index}
                                        className='text-[#9EA1B0]'
                                        value={profile.id}
                                      >
                                        {profile.profile_name}
                                      </option>
                                    )
                                  }
                                )}
                              </select>
                            </div>

                            {(currentProfile?.hasStateAccess || currentProfile?.hasCityAccess || currentProfile?.hasSchoolAccess) && !currentProfile?.hasNationalAccess && !currentProfile?.hasFederalAccess ? (
                              <>
                                <div className=' mt-2 flex flex-row justify-between relative'>
                                  <div className='flex flex-col flex-1'>
                                    <span className='text-sm text-[#5E6475]'>
                                      Estado
                                    </span>
                                    <select
                                      id='states'
                                      className='mt-2 px-4 py-2 text-user w-full bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                                      onChange={(e) =>
                                        setCurrentState(e.target.value)
                                      }
                                      value={currentState}
                                      disabled={stateDisabled}
                                    >
                                      <option value='' className='text-sm'>
                                        Selecione o estado
                                      </option>
                                      {brazilStates.states.map((state, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={state.initials}
                                          >
                                            {state.initials}
                                          </option>
                                        )}
                                      )}
                                    </select>
                                  </div>
                                
                                  {(currentProfile?.hasCityAccess || currentProfile?.hasSchoolAccess) && !currentProfile?.hasStateAccess ? (<div className='flex flex-col ml-6 flex-1'>
                                    <span className='text-sm text-[#5E6475]'>
                                      Município
                                    </span>
                                    <select
                                      id='profile'
                                      className='mt-2 px-4 py-2 text-user w-full bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                                      onChange={(e) => {
                                        setCurrentCity(e.target.value)
                                      }}
                                      value={currentCity}
                                      disabled={cityDisabled}
                                    >
                                      <option value='' className='text-sm'>
                                        Selecione o município
                                      </option>
                                      {brazilStates.states.map((state: any, index: any) => {
                                        return state.initials === currentState
                                          ? (state.cities.map((city: any, idx: any) => {
                                              return (
                                                <option
                                                  key={idx}
                                                  value={city}
                                                >
                                                  {city}
                                                </option>
                                              )
                                            }))
                                          : null
                                        }
                                      )}
                                    </select>
                                  </div>) : null}
                                </div>
                                {schoolsData.length > 0 && currentCity && currentState && currentProfile?.hasSchoolAccess && !currentProfile?.hasCityAccess && !currentProfile?.hasStateAccess && !currentProfile?.hasFederalAccess && (
                                  <div className='flex flex-col mt-2'>
                                    <label className='text-[#5E6475] text-sm'>
                                      Escolas
                                    </label>
                                    <select
                                      id='schools'
                                      className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                                      onChange={(e) => handleSetSchools()}
                                      value={schools}
                                      disabled={schoolsDisabled}
                                    >
                                      <option value='' className=''>
                                        Selecione a escola
                                      </option>
                                      {profilesAndSchoolsData.map(
                                        (profile: any, index: React.Key) => {
                                          return profile.id.toString() ===
                                            profiles && profile.hasAccess ? (
                                            <option
                                              key={index}
                                              className='text-[#9EA1B0]'
                                              value={'1'}
                                            >
                                              TODAS
                                            </option>
                                          ) : null
                                        }
                                      )}

                                      {profilesAndSchoolsData.map(
                                        (profile: any, index: React.Key) => {
                                          return profile.id.toString() ===
                                            profiles && !profile.hasNationalAccess
                                            ? schoolsData.map(
                                                (
                                                  school: any,
                                                  idx: React.Key
                                                ) => {
                                                  return (
                                                    <option
                                                      key={idx}
                                                      className='text-[#9EA1B0]'
                                                      value={school.id}
                                                    >
                                                      {school.name}
                                                    </option>
                                                  )
                                                }
                                              )
                                            : null
                                        }
                                      )}
                                    </select>
                                  </div>
                                )}

                                {profiles === '23' ? (
                                  <div className='flex flex-col mt-2'>
                                    <label className='text-[#5E6475] text-sm'>
                                      Turmas
                                    </label>
                                    <select
                                      id='classes'
                                      className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                                      onChange={(e) => handleSetClasses()}
                                      value={classes}
                                    >
                                      <option value='' className=''>
                                        Selecione a turma
                                      </option>
                                      {classesData.map(
                                        (clas: any, index: React.Key) => {
                                          return clas.schoolId.toString() ===
                                            schools.toString() ? (
                                            <option
                                              key={index}
                                              className='text-[#9EA1B0]'
                                              value={clas.id}
                                            >
                                              {clas.name}
                                            </option>
                                          ) : null
                                        }
                                      )}
                                    </select>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        )}
                      </div>
                    </form>

                    <div className={!newUser ? 'bg-white mt-11 flex justify-between gap-4' : 'bg-white mt-11 flex justify-end gap-4'}>
                      {!newUser ? (
                        <button
                          id='cancel_user'
                          type='button'
                          className='w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-bold mb-7 ml-8'
                          onClick={handleClose}
                        >
                          Cancelar
                        </button>
                      ) : null}
                      <button
                        id='link_user'
                        type='button'
                        className='w-auto inline-flex justify-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white mb-7 mr-8'
                        onClick={handleLink}
                        disabled={isDisabled}
                      >
                        Salvar permissões
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </section>
  )
}
