import { getLoggedUserData, getUserData } from "../api/user"
import { setLocalUserData } from "../services/localStorage"

export async function setUserData() {
  await getLoggedUserData().then(async (res) => {
    await getUserData(res.cpf).then((response) => {
      let userData = response
      userData.dados = res.dados
      setLocalUserData(JSON.stringify(userData))
    })
  })
}