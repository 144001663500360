import Cookies from 'universal-cookie'
import CryptoJS from 'crypto-js'

const cookies = new Cookies()
const refreshToken = 'refreshToken'
const accessToken = 'accessToken'
const userProfile = 'userProfile'
const cpfKey = 'CPF_KEY'
const systemUrl = 'systemUrl'
const hash = 'hash'
const userData = 'userData'

export const encrypt = (value: string) => {
  return CryptoJS.SHA1(value)
}

export const getLocalToken = () => {
  return cookies.get(accessToken) || ''
}

export const getLocalRefreshToken = () => {
  return cookies.get(refreshToken) || ''
}

export const setLocalToken = (token: string) => {
  cookies.set(accessToken, token, { path: '/' })
}

export const setLocalRefreshToken = (token: string) => {
  cookies.set(refreshToken, token, { path: '/' })
}

export const removeLocalToken = () => {
  cookies.remove(accessToken, { path: '/' })
  cookies.remove(refreshToken, { path: '/' })
  cookies.remove(userProfile, { path: '/' })
  cookies.remove(hash, { path: '/' })
  localStorage.clear()
}

export const getLocalCPF = () => {

  return localStorage.getItem(cpfKey) || ''
}

export const setLocalCPF = (cpf: string) => {
  localStorage.setItem(cpfKey, cpf)
}

export const setSystemUrl = (url: string) => {
  cookies.set(systemUrl, url, { path: '/' })
}

export const getSystemUrl = () => {
  return cookies.get(systemUrl) || ''
}

export const removeSystemUrl = () => {
  cookies.remove(systemUrl, { path: '/' })
}

export const setUserProfile = (profile: number) => {
  cookies.set(userProfile, profile, { path: '/' })
}

export const getUserProfile = () => {
  return cookies.get(userProfile) || ''
}

export const setHash = (code: string) => {
  cookies.set(hash, code, { path: '/' })
}

export const getHash = () => {
  return cookies.get(hash) || ''
}

export const removeHash = () => {
  cookies.remove(hash, { path: '/' })
}
export const getLocalUserData = () => {
  const encryptedData = localStorage.getItem(userData) || ''
  const data = CryptoJS.AES.decrypt(encryptedData, ""+process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)

  return JSON.parse(data)
}

export const setLocalUserData = (data: any) => {
  const encryptedData = CryptoJS.AES.encrypt(data, ""+process.env.REACT_APP_SECRET_KEY).toString()

  localStorage.setItem(userData, encryptedData)
}
