/**
 *  This  util solve the request time. To prevent multiple request when
 *  input is changing value.
 *
 *  - The 'func' param recive a function that will
 *  be executed after change stop.
 *
 *  - 'params' is parameters that function will receive. Default is {}
 *
 *  - 'time' is milisseconds to wait change stop to exec 'func'
 *
 *  - 'timer' and 'setTimer' control the state to finish wainting timer
 *
 *
 * @param func
 * @param params
 * @param time
 * @param timer
 * @param setTimer
 */

export default function controlRequest(
  func: (value: any) => void,
  params: any = {},
  time: number,
  timer: any,
  setTimer: any
) {
  if (timer > 0) {
    clearTimeout(timer)
    setTimer(0)
  }
  setTimer(setTimeout(() => func(params), time))
}
