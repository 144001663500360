import styled from 'styled-components'

interface BreadProps {
  texto: string
}
export function Bread({ texto }: BreadProps) {
  return (
    <div
      id='bread-component-0'
      className='flex py-2 bg-[#083CA6] text-white'
    >
      <Margins id='bread-component-1'>
        <div
          id='bread-component-2'
          className='flex items-center gap-3'
        >
          <a id='bread-component-3' href='/'>
            <i id='bread-component-4' className='fas fa-home'></i>
          </a>
          {texto.length !== 0 ? <i className='fas fa-chevron-right'></i> : null}
          <ul id='bread-component-5' className=''>
            <li id='bread-component-6' className=''>
              {texto}
            </li>
          </ul>
        </div>
      </Margins>
    </div>
  )
}

const Margins = styled.div`
  @media (max-width: 768px) {
    margin-left: 20px;
  }
  @media (min-width: 768px) {
    margin-left: 30px;
  }
  @media (min-width: 1024px) {
    padding-left: 20px;
  }
  @media (min-width: 1366px) {
    margin-left: 55px;
  }
  @media (min-width: 1500px) {
    margin-left: 90px;
  }
`
