import React, { useEffect, useState } from "react";
import SchollsService from "../../../../../services/scholls";
import { useNavigate } from "react-router-dom";
import ClassesService from "../../../../../services/classes";
import { IDataClass } from "../../../../../interfaces/classes";
import EditSchool from "../../../ManageSchools/components/EditSchoolForm";
import { getHasResource } from "../../../../../api/user";
import { IScholl } from "../../../ManageClasses/components/TopCardScholls/types";

import { FrameRoot, FlexRow, ButtonBase, Arrowleft, FlexColumn, 
  FlexColumn5, FlexRow1, FlexColumn1, Text1, Text2, FlexRow2, 
  ButtonBase1, Text4, ButtonLabel, Button1, Paragraph } from '../../../styles'

interface ITopCardSchollsAndClass {
  idTurma?: string;
}

const TopCardSchollsAndClass: React.FC<ITopCardSchollsAndClass> = ({ idTurma }) => {
  const navigate = useNavigate();
  const { getClassById } = ClassesService();
  const [scholl, setScholl] = useState({} as IScholl);
  const [classData,setClassData] = useState({} as IDataClass)
  
  const [openEdit, setOpenEdit] = useState(false)
  
  const [hasEditSchoolPermission, setHasEditSchoolPermission] = useState(false)
  
  const { getSchollById } = SchollsService();
  
  const updateSchoolData = (idEscola: any) => {
    getSchollById(idEscola).then((res) => {
      setScholl(res.data)
    })
  }

  useEffect(() => {
    if (!idTurma) return
    getClassById(idTurma).then(res=>{
      setClassData(res.data)  
      getSchollById(res.data.id_escola).then((res) => {
        setScholl(res.data);
      });
    })

    getHasResource("62").then((res) => {
      setHasEditSchoolPermission(res)
    })
  }, [])

  return (
    <>
      <FrameRoot>
        <FlexRow>
          <Button1 onClick={() => navigate(-1)}>
            <ButtonBase>
              <Arrowleft
                src={"https://file.rendit.io/n/ypvrNQ9X9TjTML4xtgS6.svg"}
              />
            </ButtonBase>
          </Button1>
          <FlexColumn className="space-y-[30px]">
            <FlexRow1 alignItems={"center"}>
              <FlexColumn1 width={"550px"}>
                <Text1>Escola</Text1>
                <Text2>{scholl.nome_escola || '--'}</Text2>
              </FlexColumn1>
              <FlexRow2 alignItems={"center"}>
                <FlexColumn1 width={"350px"}>
                  <Text1>Cidade</Text1>
                  <Text4>{scholl.municipio || '--'}</Text4>
                </FlexColumn1>
                {hasEditSchoolPermission && (
                  <Button1 onClick={() => setOpenEdit(true)}>
                    <ButtonBase1>
                      <Arrowleft
                        src={"https://file.rendit.io/n/NsBl866ZZ626jS4u3pvm.svg"}
                      />
                      <ButtonLabel>Editar escola</ButtonLabel>
                    </ButtonBase1>
                  </Button1>
                )}
              </FlexRow2>
            </FlexRow1>

            <FlexRow1 alignItems={"center"}>
              <FlexColumn1 width={"550px"}>
                <Text1>Endereço</Text1>
                <Paragraph alignSelf={"stretch"}>{scholl.endereco|| '--'}</Paragraph>
              </FlexColumn1>
              <FlexRow2 alignItems={"flex-start"}>
                <FlexColumn1 width={"350px"}>
                  <Text1>Gestor escolar</Text1>
                  <Text4>{scholl.gestor_escolar || '--'}</Text4>
                </FlexColumn1>
                <FlexColumn5>
                  <Text1>Estado</Text1>
                  <Paragraph alignSelf={"inherit"}>{scholl.uf || '--'}</Paragraph>
                </FlexColumn5>
              </FlexRow2>
            </FlexRow1>
            <FlexRow1 alignItems={"center"}>
              <div>
                <Text1>Ano</Text1>
                <Paragraph alignSelf={"stretch"}>{classData.ano_turma || '--'}</Paragraph>
              </div>
              <div>
                <Text1>Turma</Text1>
                <Paragraph alignSelf={"stretch"}>{classData.nome_turma || '--'}</Paragraph>
              </div>
            </FlexRow1>
          </FlexColumn>
        </FlexRow>
      </FrameRoot>
      <EditSchool
        open={openEdit}
        close={setOpenEdit}
        data={scholl}
        update={() => updateSchoolData(classData.id_escola)}
      />
    </>
  );
};
export default TopCardSchollsAndClass;
