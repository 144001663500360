import { useState, useEffect } from 'react'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import GroupIcon from '@mui/icons-material/Group'
import AddIcon from '@mui/icons-material/Add'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
  TableContainer,
  Button,
  IconButton,
  FormControl,
  FormControlLabel,
} from '@mui/material'

import { CustomSwitch } from '../../../../../components/CustomSwitch'
import { CustomPagination } from '../../../../../components/CustomPagination'
import { Alert } from '../../../../../components/Alert'
import { InputSelect, InputText } from '../../../../../components/FigmaBasicComponents'
import { AcceptProfiles } from '../../Users/AcceptRequests/AcceptProfiles'

import { addStudent, deactivateAccount, filterUsersDataTabbed, getHasResource } from '../../../../../api/user'

import { LinkUser } from '../../Users/LinkUser'
import { getSystemsData } from '../../../../../api/system'
import { deleteProfileAction, deleteUserAction, editProfileAction, editUserAction, statusUserAction } from '../..'
import { getProfile } from '../../../../../api/profile'
import { maskCPF, removeMaskCPF } from '../../../../../utils/string'
import { getLocalUserData } from '../../../../../services/localStorage'

import { CardManagementProps, CardRowData, CardRowDataProfile } from './types'

export function CardManagement(props: CardManagementProps) {
  const userData = getLocalUserData()
  const hasNationalAccess = userData.dados[0].acesso_nacional

  const rowsPerPage = props.rowsPerPage

  const [cadastrarUsuario, setCadastrarUsuario] = useState(false)

  const [data, setData] = useState(props.data)

  const [page, setPage] = useState(1)
  const [pageFilter, setPageFilter] = useState<any>('')
  const [pageCount, setPageCount] = useState(
    Math.ceil(props.dataCount / rowsPerPage)
  )

  const [filterList, setFilterList] = useState<string[]>([])
  const isAdmin = props.isAdmin
  
  /* Handle Functions */
  const handleButtonAction = async () => {
    const newData: any = await props.buttonAction()

    let newRow = newData

    if (newRow) {
      if (newRow?.changed) {
        if (props?.profile) {
          getProfile({id: newRow.data.id_perfil}).then((res) => {
            const newProfile = {
              id: res.id_perfil.toString(),
              name: res.descricao_perfil,
              accessSystems: res.modulos,
              profiles: [],
              inclusionDate: '01/01/2020',
              externo: false,
              editAction: editProfileAction,
              deleteAction: deleteProfileAction,
            }
            
            setData([...data, newProfile])

            Alert({
              type: 'success',
              message: "Perfil criado com sucesso",
              autoClose: true,
            })
            
            setPageFilter('')
          })
        } else {
          const rawUserData = newRow.data

          const studentData: any = await LinkUser(
            rawUserData.username,
            rawUserData.cpf
          ).finally(() => {
            filterUsersDataTabbed(rawUserData.cpf, 1, 0).then((res) => {              
              const newUser = {
                id: res.results[0].cpf,
                name: res.results[0].nome_usuario,
                email: res.results[0].email,
                accessSystems: res.results[0].modulos,
                profiles: res.results[0].perfil,
                inclusionDate: res.results[0].data_criacao,
                status: res.results[0].active,
                externo: res.results[0].externo,
                statusAction: statusUserAction,
                editAction: editUserAction,
                deleteAction: deleteUserAction,
              }
              
              setData([newUser, ...data])
            })
          })

          if (studentData.changed) {
            addStudent(studentData.data)
          }

          Alert({
            type: 'success',
            message: "Usuário criado com sucesso",
            autoClose: true,
          }) 
          
          setPageFilter('')
        }
      } else {
        Alert({
          type: 'error',
          message: newRow.message,
          autoClose: true,
        })
      }
    }
  }

  const handleSwitch = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: CardRowData | CardRowDataProfile
  ) => {
    if (row.status !== undefined && row.statusAction !== undefined) {
      const newData = [...data]
      const newStatus = event.target.checked

      const result = await row.statusAction(row)

      const index = newData.findIndex((el) => el.id === row.id)
      newData[index].status = result ? newStatus : !newStatus

      await setStatus(row.id, newStatus)

      setData(newData)
    }
  }

  const handleEdit = async (row: CardRowData | CardRowDataProfile) => {
    const newData: any = [...data]
    const newRow = await row.editAction(row)

    if (newRow?.changed) {
      const index = newData.findIndex((el: any) => el.id === row.id)
      if (props.profile) {
        newData[index].name = newRow.data.descricao_perfil
      } else {
        newData[index].profiles = newRow.data.perfil
        newData[index].accessSystems = newRow.data.modulos
        newData[index].email = newRow.data.email
        if(newRow.data.perfil.length > 0) {
          newData[index].externo = true
        } else {
          newData[index].externo = false
        }
      }
    }

    setData(newData)
  }

  const handleDelete = async (row: CardRowData | CardRowDataProfile, deleteSolicitationStatus?: boolean, deleteSolicitation?: boolean) => {
    const result = !deleteSolicitation ? await row.deleteAction(row) : null

    if (result?.changed || deleteSolicitationStatus) {
      const newData = [...data]

      const index = newData.findIndex((el) => el.id === row.id)
      newData.splice(index, 1)
      
      setData(newData)
      setPageFilter('')
    }
  }

  const setStatus = async (cpf: string, active: boolean) => {
    const response = await deactivateAccount(cpf, active)
    return response
  }

  const handlePageFilter = async (filter: any) => {
    setPageFilter(filter === 0 ? "" : filter)

    setPage(1)
  }
  /* Props Use Effect */
  useEffect(() => {
    setData(props.data)
  }, [props.data])

  useEffect(() => {
    setPageCount(Math.ceil(props.dataCount / rowsPerPage))
  }, [props.dataCount])

  useEffect(() => {
    getHasResource("72").then((response) => {
      setCadastrarUsuario(response)
    })
  }, [])

  /* Pagination Use Effects */
  useEffect(() => {
    const pageFilterWithoutSpaces = pageFilter.trim()
    const cpf = removeMaskCPF(pageFilterWithoutSpaces)
    maskCPF(cpf) ? props.handleFilter(cpf) :props.handleFilter(pageFilterWithoutSpaces)
  }, [pageFilter])

  useEffect(() => {
    props.handleCurrentPage(page)
  }, [page])

  useEffect(() => {
    const newFilterList = new Set<string>()
    newFilterList.add('Todos os sistemas')

    async function setNewFilterList(){
      if(!props.profile) {
        data.forEach((row) => {
          row?.accessSystems.forEach((system) => {
            newFilterList.add(system)
          })
        })
      } else {
        await getSystemsData().then(data => {
          data.results.forEach((system: any) => {
            newFilterList.add(system.nome_modulo)
          })
        })
      }
      
      setFilterList(Array.from(newFilterList))
    }
    setNewFilterList()
  }, [data, props.profile])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '644px',
        padding: '24px',
        borderRadius: '4px',
        background: '#fff',
        paddingX: '16px',
        width: `calc(100vw - 4vw)`,
        '@media (min-width: 768px)': {
          width: 'auto',
          marginX: '5%',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '32px',
            width: '100%',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              gap: '16px',
              marginBottom: '24px',
            },
          }}
        >
          <Box sx={{ display: 'flex', position: 'relative' }}>
            <GroupIcon
              style={{
                color: '#083CA6',
                background: '#F2F5FD',
                width: '48px',
                height: '48px',
                padding: '12px',
                borderRadius: '50%',
                marginRight: '16px',
              }}
            />
            <Typography
              style={{
                fontSize: '2rem',
                color: '#083CA6',
              }}
            >
              {props.profile ? 'Perfis de usuário' : 'Usuários'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              justifyContent: 'space-between',
              '@media (max-width: 768px)': {
                width: '100%',
                flexDirection: 'column',
              },
            }}
          >
            {props.profile && (
              <FormControl
                sx={{
                  width: '200px',
                  marginRight: '16px',
                  marginLeft: '16px',
                  '@media (max-width: 768px)': {
                    marginLeft: '0px',
                    width: '100%',
                  },
                }}
                size='small'
              >
                <InputSelect
                  id='input-select-system'
                  value={filterList.at(pageFilter)}
                  onChange={(event) => handlePageFilter(event.target.selectedIndex)}
                  className=''
                >                  
                  {filterList.map((filter, index) => (
                    <option key={index} value={filter}>
                      {filter}
                    </option>
                  ))}
                </InputSelect>
              </FormControl>
            )}
            {!props.profile && (
              <FormControl
                sx={{
                  '@media (min-width: 768px)': { width: '350px' },
                  width: '100%',
                }}
                size='small'
              >
                <InputText
                  id='input-filter'
                  name='input-filter'
                  type='text'
                  value={pageFilter}
                  placeholder='Pesquise usuários por nome ou CPF'
                  onChange={(event) => handlePageFilter(event.target.value)}
                />
              </FormControl>
            )}

            <Button
              id='button-add'
              variant='contained'
              {...(cadastrarUsuario ? { disabled: false } : { disabled: true })}
              disableElevation
              startIcon={<AddIcon />}
              style={{
                backgroundColor: '#083CA6',
                textTransform: 'none',
                fontWeight: '700',
                color: '#fff',
              }}
              className=''
              onClick={() => handleButtonAction()}
            >
              {props.profile
                ? 'Adicionar perfil de usuário'
                : 'Adicionar usuário'}
            </Button>
          </Box>
        </Box>
        <TableContainer className='mb-[24px]'>
          <Table
            sx={{
              tableLayout: 'fixed',
              border: '2px solid #F7F8FA',
              width: '1200px',
              '@media (min-width: 768px)': {
                width: '100%',
              },
            }}
            padding='none'
          >
            <TableHead>
              <TableRow style={{ height: '48px', background: '#F7F8FA' }}>
                <TableCell
                  sx={{
                    padding: '0 8px',
                    fontWeight: 600,
                    '@media (min-width: 768px)': { padding: '0 16px' },
                  }}
                  width={props.profile ? '20%' : '20%'}
                  align='left'
                  className=''
                >
                  Nome
                </TableCell>
                <TableCell
                  sx={{
                    padding: '0 8px',
                    fontWeight: 600,
                    '@media (min-width: 768px)': { padding: '0 16px' },
                  }}
                  width={props.profile ? '20%' : '15%'}
                  align='left'
                >
                  Sistemas de acesso
                </TableCell>
                {!props.profile && (
                  <TableCell
                    sx={{
                      padding: '0 8px',
                      fontWeight: 600,
                      '@media (min-width: 768px)': { padding: '0 16px' },
                    }}
                    width='16%'
                    align='left'
                  >
                    Perfil
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    padding: '0 8px',
                    fontWeight: 600,
                    '@media (min-width: 768px)': { padding: '0 16px' },
                  }}
                  width={props.profile ? '20%' : '10%'}
                  align='left'
                >
                  Data de inclusão
                </TableCell>
                {!props.profile && isAdmin && (
                  <TableCell
                    sx={{
                      padding: '0 8px',
                      fontWeight: 600,
                      '@media (min-width: 768px)': { padding: '0 16px' },
                    }}
                    width='16%'
                    align='left'
                  >
                    Status do usuário
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    padding: '0 8px',
                    fontWeight: 600,
                    '@media (min-width: 768px)': { padding: '0 16px' },
                  }}
                  width={props.profile ? '25%' : '20%'}
                  align='center'
                >
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={`row-${index}`}>
                  <TableCell
                    sx={{
                      padding: '0 8px',
                      '@media (min-width: 768px)': { padding: '0 16px' },
                    }}
                    width={props.profile ? '20%' : '10%'}
                    className='truncate ...'
                  >
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography sx={{width: '75%', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '14px', lineHeigth: '21px', fontFamily: 'Inter, sans-serif'}}>{row.name}</Typography>
                    { !hasNationalAccess && !props.profile && row.externo ? (
                        <span 
                        style={{
                          width: 'fit-content', 
                          height: 'fit-content',
                          color: '#196416', 
                          fontSize: '12px', 
                          padding: '4px 8px',
                          background: '#F4FDF4',
                          marginLeft: '8px',                          
                        }}
                        >
                          Externo
                        </span>
                    ) : null }
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: '0 8px',
                      '@media (min-width: 768px)': { padding: '0 16px' },
                    }}
                    width={props.profile ? '20%' : '10%'}
                    className='truncate ...'
                  >
                    {row.accessSystems?.join(', ')}
                  </TableCell>
                  {!props.profile && (
                    <TableCell
                      sx={{
                        padding: '0 8px',
                        '@media (min-width: 768px)': { padding: '0 16px' },
                      }}
                      width='16%'
                      className='truncate ...'
                    >
                      {row.profiles.map((profile: any) => {
                        return profile.descricao_perfil + ' '
                      })}
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      padding: '0 8px',
                      '@media (min-width: 768px)': { padding: '0 16px' },
                    }}
                    width={props.profile ? '20%' : '10%'}
                    className='truncate ...'
                  >
                    {row.inclusionDate}
                  </TableCell>
                  {!props.profile && isAdmin && (
                    <TableCell
                      sx={{
                        padding: '0 8px',
                        '@media (min-width: 768px)': { padding: '0 16px' },
                      }}
                      width='16%'
                    >
                      {!props.pendency ? (
                        <FormControlLabel
                          control={
                            <CustomSwitch
                              sx={{ m: 1 }}
                              checked={row.status}
                              onChange={(e) => handleSwitch(e, row)}
                            />
                          }
                          label={
                            <Typography>
                              {row.status ? 'Ativo' : 'Inativo'}
                            </Typography>
                          }
                        />
                      ) : (
                        <AcceptProfiles deleteRow={handleDelete} userData={row as CardRowData} loggedUserId={userData.cpf}  />
                      )}
                      
                    </TableCell>
                  )}
                  <TableCell
                    sx={{
                      padding: '0 8px',
                      '@media (min-width: 768px)': { padding: '0 16px' },
                    }}
                    width={props.profile ? '25%' : '20%'}
                    align='right'
                  >
                    { !props.pendency ? ( 
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Editar'
                            onClick={() => handleEdit(row)}
                            disableRipple
                          >
                            <EditIcon sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={
                          <Typography sx={{ fontWeight: 600 }} color='disabled'>
                            Editar
                          </Typography>
                        }
                      />
                    ): null }

                    {isAdmin && (
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Excluir'
                            onClick={() => handleDelete(row)}
                            disableRipple
                          >
                            <DeleteIcon color='error' sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={
                          <Typography sx={{ fontWeight: 600 }} color='error'>
                            Excluir
                          </Typography>
                        }
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display='flex' justifyContent='right'>
        <CustomPagination
          id='pagination'
          variant='outlined'
          shape='rounded'
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </Box>
    </Box>
  )
}
