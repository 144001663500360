import tw from 'tailwind-styled-components'
import InputMask from 'react-input-mask'
import { FormControlStyle } from '../types'
import styled from '@emotion/styled'

//@ts-ignore
export const InputMaskTw = styled(InputMask)`
  appearance-none 
  rounded 
  w-full 
  h-10
  py-2 
  px-3
  bg-primitive-50
  placeholder-primitive-500
  text-primitive-700
  leading-tight 
  focus:outline-none 
  focus:shadow-outline
  placeholder:not-italic
  mt-2
`;

export const InputTw = tw.input<FormControlStyle>`
  appearance-none 
  rounded 
  w-full 
  h-10
  py-2 
  px-3
 
  ${(props: FormControlStyle) => (props.isInvalid ? " bg-negative-50": " bg-primitive-50")} 
  placeholder-primitive-500
  text-primitive-700
  leading-tight 
  focus:outline-none 
  focus:shadow-outline
  placeholder:not-italic
  mt-2
`