
import React, { useState } from 'react';

import { Box, 
  Typography, 
  TextField,
  Button, 
  ToggleButton, 
  ToggleButtonGroup, 
  Select,
  FormControl, 
  MenuItem,
  Divider,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
/* import UploadIcon from '@mui/icons-material/Upload'; */
import { styled } from '@mui/material/styles'
import { postContact } from '../../api/contact';
import { getLocalUserData } from '../../services/localStorage';
import { Alert } from '../Alert';

interface ContatoProps {
  title: string
  subtitle?: string
  hasButton?: boolean
}
function ContatoComponent({ title, subtitle, hasButton } : ContatoProps) {
  const userData = getLocalUserData()

  const [ category, setCategory ] = useState<string | null>('')
  const [ modulo, setModulo ] = useState('')

  const Input = styled(TextField) ({
    width: '100%',
    backgroundColor: '#F7F8FA',
  })
  const BackButton = styled(Button) ({   
    width: 'auto',
    border: '1px solid #DBDDE5',
    textTransform: 'none',
    justifyContent: 'flex-start',
    fontWeight: '700',
    color: '#083CA6',
  })
  const ToggleSelect = styled(ToggleButton) ({
    fontWeight: '700',
    textTransform: 'none',
    padding: '0.5rem 2rem',
    color: '#002577',
    backgroundColor: '#F2F5FD',
    '&.Mui-selected': {
      backgroundColor: '#013090',
      color: '#F2F5FD',
    },
    '&.Mui-selected:hover': {
      color: "#002577",
    }
  })
  const StyledToggleButtonGroup = styled(ToggleButtonGroup) ({
    '& .MuiToggleButtonGroup-grouped': {
      marginRight: '0.5rem',
      border: 0,
      '&:not(:first-of-type)': {
        borderRadius: '0.25rem',
      },
      '&:first-of-type': {
        borderRadius: '0.25rem',
      },
    },
  })
  const EnviarMensagemButton = styled(Button)({
    fontWeight: '700',
    fontSize: '0.9rem',
    width: '12rem',
    padding:'0.5rem',
    marginTop: '2rem',
    textTransform: 'none',
    backgroundColor: '#083CA6',
    color: 'white',
    '&:hover': {
      backgroundColor: '#073289',
    }
  })
  const StyledSelect = styled(Select)({
    backgroundColor: '#F7F8FA',
    height: '2.5rem',

  })

  const handleSelectCategory = (e: React.MouseEvent<HTMLElement>, newCategory: string | null) => {
    setCategory(newCategory)
  }

  const goHome = () => {
    window.location.href = '/'
  }

  const clearForm = () => {
    const message = document.getElementById('mensagem') as HTMLInputElement
    const subject = document.getElementById('assunto') as HTMLInputElement

    message.value = ''
    subject.value = ''
    setCategory('')
    setModulo('')
  }

  const submitForm = () => {
    const message = document.getElementById('mensagem') as HTMLInputElement
    const subject = document.getElementById('assunto') as HTMLInputElement

    const data = {
      name: userData.nome_usuario,
      cpf: userData.cpf,
      email: userData.email,
      category,
      modulo,
      subject: subject.value,
      message: message.value,
    }

    clearForm()

    postContact(data).then(() => {
      Alert({
        type: 'success',
        message: "Mensagem enviada com sucesso!",
        autoClose: true,
      })

      setTimeout(() => {
        goHome()
      }, 2000)
    }).catch(() => {
      Alert({
        type: 'error',
        message: "Erro ao enviar mensagem!",
        autoClose: true,
      })
    })
  }

  return (
    <Box sx={{
      display: 'flex',
      backgroundColor: 'white',
      width: '70%',
      margin: '2.2rem',
      padding: '2.2rem',
      flexDirection: 'column',
      borderRadius: '0.5rem',
    }}>
      {hasButton && (
        <Box pb="2rem">
          <BackButton startIcon={<ArrowBackIcon/>} onClick={goHome}>Voltar</BackButton>
        </Box>
      )}
      { subtitle ? (
        <Box>
        <Typography fontSize="1.5rem" fontWeight="600" pb="2rem">{title}</Typography>
        <Divider />
        <Typography fontSize="1.2rem" fontWeight="600" py="2rem">{subtitle}</Typography>
      </Box>
      ) : (
        <Box>
          <Typography fontSize="1.5rem" fontWeight="600" pb="2rem">{title}</Typography>
        </Box>
      )}


      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',

      }}>
        <Box sx={{
          marginRight: '2rem',
          width: '50%',
        }}>
          <Typography fontSize="14px" fontWeight="500" pb="0.5rem" color='#5E6475'>Nome</Typography>
          <Input id="nome" value={userData.nome_usuario} hiddenLabel variant="outlined" size="small" placeholder="Helena Silveira" disabled/>
        </Box>
        <Box sx={{
          marginRight: '2rem',
          width: '50%',
        }}>
          <Typography fontSize="14px" fontWeight="500" pb="0.5rem" color='#5E6475'>CPF</Typography>
          <Input id="cpf" value={userData.cpf} hiddenLabel variant="outlined" size="small" placeholder="999.999.999-99" disabled/>
        </Box>
        <Box sx={{
          width: '50%',
        }}>
          <Typography fontSize="14px" fontWeight="500" pb="0.5rem" color='#5E6475'>Email</Typography>
          <Input id="email" value={userData.email} hiddenLabel variant="outlined" size="small" placeholder="helena@email.com" disabled/>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '2rem',
      }}>
        <Box sx={{
          marginRight: '2rem',
        }}>
          <Typography fontSize="14px" fontWeight="500" pb="0.5rem" color='#5E6475'>Categoria</Typography>
          <Box sx={{
            display:'flex',
            flexDirection:'row',
            width: '25%',
          }}
          >
            <StyledToggleButtonGroup
              color="primary"
              value={category}
              exclusive
              onChange={handleSelectCategory}
              aria-label="Platform"
            >
              <ToggleSelect value="duvida">Dúvida</ToggleSelect>
              
              <ToggleSelect value="sugestao">Sugestão</ToggleSelect>
            </StyledToggleButtonGroup>
          </Box>
        </Box>
        
        <Box sx={{
          width: '100%',
        }}>
          <Typography fontSize="14px" fontWeight="500" pb="0.5rem" color='#5E6475'>Módulo</Typography>
          <FormControl fullWidth>
            <StyledSelect value={modulo} onChange={e => setModulo(e.target.value as string)} labelId="modulo" id="moduloId" displayEmpty>
              <MenuItem disabled value="">
                Selecione o módulo
              </MenuItem>
              {userData.modulos?.map((module: string, index: number) => {
                return <MenuItem key={index} value={module}>{module}</MenuItem>
              })}
            </StyledSelect>
          </FormControl>
        </Box> 
      </Box>
      <Box>
        <Typography fontSize="14px" fontWeight="500" pb="0.5rem" pt="1.5rem" color='#5E6475'>Assunto</Typography>
        <Input id="assunto" hiddenLabel variant="outlined" size="small" placeholder="Digite aqui o assunto"/>

        <Typography fontSize="14px" fontWeight="500" pb="0.5rem" pt="1.5rem" color='#5E6475'>Mensagem</Typography>
        <Input id="mensagem" hiddenLabel variant="outlined" size="small" placeholder="Digite aqui a mensagem" multiline/>

        {/* <Typography fontSize="14px" fontWeight="500" pb="0.5rem" pt="1.5rem" color='#5E6475'>Anexo(s)</Typography>
        <Button variant="outlined" startIcon={<UploadIcon/>} component="label" sx={{ width: '100%', border: '2px dashed #B6C4EE', textTransform: 'none', justifyContent: 'flex-start' }}>
          <Typography fontSize="14px" fontWeight="600" color='#013090' py="0.45rem">Clique aqui para selecionar o(s) arquivo(s)</Typography>
          <input type="file" multiple hidden />
        </Button>
        <Typography fontSize="12px" fontWeight="400" pb="0.5rem" pt="0.5rem" color='#5E6475'>Tamano máximo: XMB</Typography> */}
        
        <EnviarMensagemButton startIcon={<SendIcon/>} onClick={submitForm}>Enviar Mensagem</EnviarMensagemButton>  
      </Box>
    </Box>

  )
}


export default ContatoComponent