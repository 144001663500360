import httpScanner from "../http/scanner-prova";
import { IDataStudent } from "../interfaces/students";

export default function StudentsService() {
  const getStudent = async (limit: number = 10) => {
    const api = await httpScanner.get(`/govbr/aluno/?limit=${limit}/`);
    return api;
  };
  const getFiltredStudent = async (idTurma: string, limit: number, search:string, offset:number) => {
    const api = await httpScanner.get(`/govbr/aluno/filter/?id_turma=${idTurma}&search=${search}&limit=${limit}&offset=${offset}`);
    return api;
  };
  const getStudentByClass = async (idClass: string) => {
    const api = await httpScanner.get(`/govbr/aluno/turma/${idClass}/`);
    return api;
  };
  const getStudentById = async (idStudent: string) => {
    const api = await httpScanner.get(`/govbr/aluno/${idStudent}/`);
    return api;
  };
  const postStudent = async (dataStudent: IDataStudent) => {
    const api = await httpScanner.post(`/govbr/aluno/`, dataStudent);
    return api;
  };
  const putStudent = async (dataStudent: IDataStudent) => {
    const api = await httpScanner.put(
      `/govbr/aluno/${dataStudent.id_aluno}`,
      dataStudent
    );
    return api;
  };
  const deleteStudent = async (idStudent: number) => {
    const api = await httpScanner.delete(`/govbr/aluno/${idStudent}`);
    return api;
  };

  return {
    getStudent,
    getFiltredStudent,
    getStudentByClass,
    getStudentById,
    postStudent,
    putStudent,
    deleteStudent,
  };
}
