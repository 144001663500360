import { Box, Typography } from "@mui/material";
import { allProfileProps } from "./AcceptProfiles";

import { EditButton } from './styles'

import pen from '../../../../../assets/icons/pen.svg'

interface MoreInfoProps {
  TextLabel: any;
  open: boolean;
  handleOpen: () => void;
  data: allProfileProps | undefined;
}

export function MoreInfo({ TextLabel, open, handleOpen, data } : MoreInfoProps) {
  return (
    <Box sx={{ mt: '16px', py: '8px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography 
          component="h3" 
          sx={{ 
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '150%', 
          }}>
          Informações adicionais
        </Typography>
        <EditButton onClick={handleOpen}>
          <img src={pen} style={{ width: '18px', height: '18px' }} alt="Imagem de uma caneta" />
          Editar
        </EditButton>
      </Box>
      <Box sx={{ display: 'grid', rowGap: '16px', gridTemplateColumns: '1fr 1fr' }}>
        <Box>
          <TextLabel>
            Função
          </TextLabel>
          <Typography variant="body2" sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
            Escolar
          </Typography>
        </Box>
        <Box>
          <TextLabel>
            Perfil
          </TextLabel>
          <Typography variant="body2" sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
            {data?.id_perfil.descricao_perfil}
          </Typography>
        </Box>
        {data?.dependencia_administrativa ? (
          <Box>
            <TextLabel>
              Dependência administrativa
            </TextLabel>
            <Typography variant="body2" sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
              {data?.dependencia_administrativa}
            </Typography>
          </Box>
        ) : <Box></Box>}
        <Box>
          <TextLabel>
            Área do conhecimento
          </TextLabel>
          <Typography variant="body2" sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
            {data?.area_conhecimento}
          </Typography>
        </Box>

        {data?.estado ? (
          <Box>
            <TextLabel>
              Estado
            </TextLabel>
            <Typography variant="body2" sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
              {data?.estado}
            </Typography>
          </Box>
        ): null}

        {data?.municipio ? (
          <Box>
            <TextLabel>
              Cidade
            </TextLabel>
            <Typography variant="body2" sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
              {data?.municipio}
            </Typography>
          </Box>
        ): null}
        
        {data?.id_escola.nome_escola ? (
          <Box>
            <TextLabel>
              Escola
            </TextLabel>
            <Typography variant="body2" sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
              {data?.id_escola.nome_escola}
            </Typography>
          </Box>
        ): null}
      </Box>
    </Box>
  )
}
