/* eslint-disable @typescript-eslint/no-unused-vars */
import isAuth from '../services/auth'
import { useLocation, Navigate } from 'react-router-dom'

export function ProtectedRoute({ children }: { children: JSX.Element }) {
  let location = useLocation()

  if (!isAuth()) {
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  return children
}
