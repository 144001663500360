import httpScanner from "../http/scanner-prova";

export default function SchollsService() {
  const getSchollById = async (idScholl: string) => {
    const api = await httpScanner.get(`/govbr/escola/${idScholl}`);
    return api;
  };

  return {
    getSchollById,
  };
}
