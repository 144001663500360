import { useEffect, useState } from "react";

import { styled, ToggleButton as MuiToggleButton, ToggleButtonGroup } from "@mui/material";

import { getProfilesData } from "../api/profile";
import { getFilteredSchoolsData } from "../api/school";

import { brazilStates } from "../utils/states";
import { knowledgeArea } from "../utils/knowledgeArea";
import { getIsGuest, postGuest } from "../api/user";
import { useNavigate } from "react-router-dom";
import { Alert } from "../components/Alert";
import { Loader } from "../components/Loader";
import { getLocalUserData } from "../services/localStorage";

const ToggleButton = styled(MuiToggleButton)((props) => ({
  color: '#083CA6',
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#083CA6"
  }
}))

export function PreRegister() {
  const userData = getLocalUserData()
  
  const [alignment, setAlignment] = useState<string>('left')
  
  const [profiles, setProfiles] = useState('')
  const [profilesData, setProfilesData] = useState([])

  const [currentState, setCurrentState] = useState('')
  const [currentCity, setCurrentCity] = useState('')

  const [schools, setSchools] = useState('')
  const [schoolsData, setSchoolsData] = useState([])

  const [blockChange, setBlockChange] = useState(false)

  const [field, setField] = useState('')
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const currentDependency: string[] = ["Federal", "Estadual", "Municipal"]
  const currentProfile: any = profilesData.find((profile: any) => profile.id === Number(profiles))

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  }

  const control = {
    value: alignment,
    onChange:  handleChange,
    exclusive: true,
    className: "justify-between gap-2",
  }

   const children = [
    <ToggleButton value="1" key="1" className="rounded border border-neutral-light-1 py-3 px-9 w-full">
      <span className="font-bold text-sm normal-case">Federal</span>
    </ToggleButton>,
    <ToggleButton value="2" key="2" className="rounded border border-neutral-light-1 py-3 px-9 w-full">
      <span className="font-bold text-sm normal-case">Estadual</span>
    </ToggleButton>,
    <ToggleButton value="3" key="3" className="rounded border border-neutral-light-1 py-3 px-9 w-full">
      <span className="font-bold text-sm normal-case">Municipal</span>
    </ToggleButton>,
  ]

  const handleSetFixedAlignment = () => {
    let finalAlignment: string = alignment
    
    if(currentProfile?.hasFederalAccess || currentProfile?.hasNationalAccess) {
      finalAlignment = '1'
      setBlockChange(true)
    } else if(currentProfile?.hasStateAccess) {
      finalAlignment = '2'
      setBlockChange(true)
    } else if(currentProfile?.hasCityAccess) {
      finalAlignment = '3'
      setBlockChange(true)
    } else {
      setBlockChange(false)
    }

    setAlignment(finalAlignment)
  }

  const resetSelectValues = () => {
    setSchools('')
    setCurrentState('')
    setCurrentCity('')
  }

  const handleSubmit = () => {
    let school = ''
    
    if(currentProfile.hasNacionalAccess || currentProfile.hasFederalAccess || currentProfile.hasStateAccess || currentProfile.hasCityAccess) {
      school = '1'
    } else {
      school = !currentProfile.hasSchoolAccess ? '2' : schools
    }
    
    postGuest({
      cpf: userData.cpf,
      name: userData.nome_usuario,
      area: knowledgeArea[+field],
      idProfile: +profiles,
      dependency: currentDependency[+alignment - 1],
      city: currentCity,
      state: currentState,
      school,
    }).then(() => {
      Alert({
        type: 'success',
        message: 'Pré-cadastro realizado com sucesso!',
        autoClose: true,
      })
      
      setTimeout(() => {
        navigate('/')
      }, 2000)
    }).catch((err) => {
      Alert({
        type: 'error',
        message: err.response.data.detail || "Ocorreu um erro ao realizar o pré-cadastro!",
        autoClose: true,
      })
    })
  }

  useEffect(() => {
    document.title = "Pré-cadastro";

    getIsGuest(userData.cpf).then(() => {
      navigate('/')
    })

    getProfilesData().then((data) => {
      const newProfiles = data.results.map((item: any) => {
        const schools = item.escolas        

        return {
          id: item.id_perfil,
          profile_name: item.descricao_perfil,
          hasNationalAccess: item.acesso_nacional,
          hasFederalAccess: item.acesso_federal,
          hasStateAccess: item.acesso_estadual,
          hasCityAccess: item.acesso_municipal,
          hasSchoolAccess: item.acesso_escolas,
          schools,
        }
      })

      setProfilesData(newProfiles);
    })

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    handleSetFixedAlignment();
    resetSelectValues()
  }, [profiles])

  useEffect(() => {
    if ((currentCity !== '' && currentState !== '') && alignment !== 'left') {
      getFilteredSchoolsData({
        uf: currentState,
        municipio: currentCity,
        dependency: currentDependency[+alignment - 1],
      }).then((data) => {
        const schools = data.results.map((item: any) => {
          return {
            id: item.id_escola,
            name: item.nome_escola,
            state: item.uf,
            city: item.municipio,
            dependency: item.dependencia_administrativa
          }
        })

        setSchoolsData(schools)
      })
    }
  }, [currentCity, currentState, alignment])

  return (
    <div className="w-full h-full flex justify-center items-center bg-brand-primary-600">
      <div className='relative w-full h-auto bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-2/5 my-10 flex flex-col gap-6'>
        <div className='text-center sm:text-left flex flex-col justify-between mx-8 mt-8 sm:flex sm:items-start gap-6 text-profile'>
          <h1 className='text-xl font-bold text-brand-primary-600'>
            Informações necessárias
          </h1>
          <p className=''>Estamos quase lá! Precisamos de mais algumas informações para completar seu cadastro e preparar a plataforma para que você possa acessá-la.</p>
        </div>
        <div className='flex flex-col justify-start bg-white rounded-md gap-6 ml-8 mr-10'>         
          <div className='flex flex-col'>
            <label className='text-primitive-500 text-sm font-normal'>
              Perfil
            </label>
            <select
              id="select-profile"
              className={`mt-2 px-4 py-2 text-sm bg-primitive-50 rounded first-of-type:text-neutral-base appearance-none bg-arrowDown bg-[right_22px_center] bg-no-repeat`}
              onChange={(e) => setProfiles(e.target.value)}
              value={profiles}
            >
              <option value="default" className="text-neutral-base">Escolha o seu perfil</option>              
              {profilesData.map((profile: any, index: number) => {
                return (
                  <option key={index} value={profile.id+""}>{profile.profile_name}</option>
                )
              })}
            </select>
          </div>
          <div className='flex flex-col'>
            <label className='text-primitive-500 text-sm font-normal'>
              Aréa do conhecimento
            </label>
            <select 
              id="select-field"
              className={`mt-2 px-4 py-2 text-sm bg-primitive-50 rounded first-of-type:text-neutral-base appearance-none bg-arrowDown bg-[right_22px_center] bg-no-repeat`}
              onChange={(e) => setField(e.target.value)}
              value={field}
            >
              <option value="default" className="text-neutral-base">Escolha a sua área de conhecimento</option>
              {knowledgeArea.map((area: string, index: number) => {
                return (
                  <option key={index} value={index}>{area}</option>
                )
              })}
            </select>
          </div>

          <div className='flex flex-col'>
            <label className='text-primitive-500 text-sm font-normal'>
              Dependência administrativa
            </label>
            <ToggleButtonGroup disabled={blockChange} {...control}>
              {children}
            </ToggleButtonGroup>
          </div>
        </div>
        {(currentProfile?.hasStateAccess || currentProfile?.hasCityAccess || currentProfile?.hasSchoolAccess) && !currentProfile?.hasNationalAccess && !currentProfile?.hasFederalAccess ? (
          <div className='flex flex-row justify-between relative ml-8 mr-10 gap-6'>
              <div className='flex flex-col flex-1 rounded'>
                <span className='text-sm text-[#5E6475]'>
                  Estado
                </span>
                <select
                  id='states'
                  className='mt-2 px-4 py-2 text-user w-full bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                  onChange={(e) =>
                    setCurrentState(e.target.value)
                  }
                  value={currentState}
                >
                  <option value='' className='text-sm'>
                    Estado
                  </option>
                  {brazilStates.states.map((state, index) => {
                    return (
                      <option
                        key={index}
                        value={state.initials}
                      >
                        {state.initials}
                      </option>
                    )}
                  )}

                </select>
              </div>
            

            {(currentProfile?.hasCityAccess || currentProfile?.hasSchoolAccess) && !currentProfile?.hasStateAccess ? (
              <div className='flex flex-col flex-1 rounded'>
                <span className='text-sm text-[#5E6475]'>
                  Cidade
                </span>
                <select
                  id='cities'
                  className='mt-2 px-4 py-2 text-user w-full bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
                  onChange={(e) => {
                    setCurrentCity(e.target.value)
                  }}
                  value={currentCity}
                >
                  <option value='' className='text-sm'>
                    Cidade
                  </option>
                  {brazilStates.states.map((state: any, index: any) => {
                    return state.initials === currentState
                      ? (state.cities.map((city: any, idx: any) => {
                          return (
                            <option
                              key={idx}
                              value={city}
                            >
                              {city}
                            </option>
                          )
                        }))
                      : null
                    }
                  )}
                </select>
              </div>
            ): null}
          </div>
        ) : null}
        {currentCity && currentState && currentProfile?.hasSchoolAccess && !currentProfile?.hasCityAccess && !currentProfile?.hasStateAccess && !currentProfile?.hasFederalAccess ? (<div className='flex flex-col flex-1 rounded ml-8 mr-10'>
          <span className='text-sm text-[#5E6475]'>
            Escola
          </span>
          <select
            id='shools'
            className='mt-2 px-4 py-2 text-user w-full bg-[#F7F8FA] rounded first-of-type:text-[#9EA1B0]'
            onChange={(e) => setSchools(e.target.value)}
            value={schools}
          >
            <option value='' className='text-sm'>
              Escola
            </option>
            {schoolsData.map((school: any, idx: React.Key) => {
              return currentDependency[+alignment - 1] === school.dependency && (
                <option
                  key={idx}
                  className='text-[#9EA1B0]'
                  value={school.id}
                >
                  {school.name}
                </option>
              )
              })
            }
          </select>
        </div>) : null}
        
        <div className='bg-gray-50 sm:flex justify-between mb-8 mt-6 mx-8'>
          <button
            id='next-create-profile'
            type='button'
            className='flex flex-1 justify-center items-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white'
            onClick={() => {handleSubmit()}}
          >
            Finalizar cadastro
          </button>
        </div>
      </div>
      
      <Loader loading={loading} />
    </div>
  );
}