import { useEffect } from "react";

import ContatoComponent from "../components/Contato";

import { Box } from "@mui/material";

export function LoginContato() {
  useEffect(() => {
    document.title = "Contato";
  }, [])
  
  return(
    <>
      <Box sx={{
        backgroundColor: '#013090',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <ContatoComponent 
          title='Plataforma de Recuperação da Aprendizagem e Redução do Abandono'
          subtitle="Contato"
          hasButton={true}
        />
      </Box>
    </>
  )
}