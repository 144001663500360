import { InputTw, InputMaskTw } from "./styles";
import { InputProps } from "./types";
import { Field, isString } from 'formik'
import HelperText from "../HelperText";
import Label from "../Label";

const Input: React.FC<InputProps> = ({
  label, 
  helperText, 
  mask,
  isInvalid = false, 
  ...rest
}) => {
  const inputProps = {
    id: rest.name,
    ...rest,
    mask,
    isInvalid
  }
  if (!mask) delete inputProps.mask
  return (
    <div>
      <Label {...{isInvalid}} text={label} />
      <Field as={mask ? InputMaskTw : InputTw} {...inputProps} />
      {!!helperText && (
          <HelperText 
            {...{isInvalid}} 
            text={
              isString(helperText) ? helperText : helperText[0]
            } 
          />
        )}
    </div>
  )
}
export default Input
