import { useEffect } from "react";
import { removeLocalToken } from "../services/localStorage";

export function Logout() {

  useEffect(() => {
		removeLocalToken();
		var form = document.createElement("form");
    form.setAttribute("method", "post");
		form.setAttribute("action", "https://sso.acesso.gov.br/logout?post_logout_redirect_uri=https://plataforma-integrada.nees.ufal.br/login");
		document.body.appendChild(form);
		form.submit();
	}, [])

	return (
		<></>
	)
}
