import { useEffect } from "react";
import ContatoComponent from "../components/Contato";
import { MainHeader } from "../components/Header";
import { Box } from "@mui/material";
import { Bread } from "../components/Bread/bread";
export function Contato() {

  useEffect(() => {
    document.title = "Contato";
  }, [])

  return (
    <>
      <MainHeader subtitles={['']}/>
      <Bread texto={'Contato'}/>
      <Box sx={{
        backgroundColor: '#F2F5FD',
        display: 'flex',
        justifyContent: 'center',
      }}>
        <ContatoComponent 
          title='Contato'
        />

      </Box>
    
    </>
  )
}