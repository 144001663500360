import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Grid, MenuItem, TextField, Button, Box } from '@mui/material'
import * as yup from 'yup'
import { IForm, ISchemaFormData } from '../../types'
import { Formik, Field } from 'formik'

import { serializeStudent } from '../../utils/serializeStudent'
import ClassesService from '../../../../../services/classes'
import SchollsService from '../../../../../services/scholls'

const MaintenanceStudentForm: React.FC<IForm> = ({
  data,
  onConfirm,
  onCancel,
  confirmButtonLabel,
}) => {
  const optionsYearsSelect = [...Array(9).keys()].map((y) => `${y + 1}º`)
  const optionsGenreSelect = ['Feminino', 'Masculino']
  const [submit, setSubmit] = useState(false)
  const { idTurma = '' } = useParams()

  const { getClassById } = ClassesService()
  const { getSchollById } = SchollsService()

  const [anoTurma, setAnoTurma] = useState('')
  const [nomeTurma, setNomeTurma] = useState('')

  const validationSchema = yup.object({
    nome_mae: yup.string().nullable(),
    tp_sexo: yup.string().required('É necessário informar o sexo do aluno.'),
    rg: yup.string().nullable(),
    dt_nascimento: yup
      .string()
      .required('É necessário informar a data de nascimento do aluno.'),
    etapa_ensino: yup.string().nullable(),
    nome_aluno: yup
      .string()
      .required('É necessário preencher o nome do(a) aluno(a).'),
    cod_matricula: yup
      .string()
      .required('É necessário informar o código da matrícula do aluno.'),
  })
  const formikParms = {
    initialValues: serializeStudent.toFormDate(data),
    validationSchema,
    onSubmit: (values: ISchemaFormData) => {
      onConfirm(serializeStudent.toDataStudent(values))
    },
  }

  useEffect(() => {
    if (idTurma) {
      getClassById(idTurma).then((res) => {
        setNomeTurma(res.data.nome_turma)
        setAnoTurma(res.data.ano_turma)

        getSchollById(res.data.id_escola).then((response) => {
          const learningMode: any = document.getElementById('etapa_ensino')

          learningMode.value = response.data.modalidade_ensino
          data = response.data.etapa_ensino
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Formik {...formikParms}>
      {({ handleSubmit, errors, values }) => (
        <form
          style={{ width: '100%' }}
          onSubmit={(event) => {
            handleSubmit(event)
            setSubmit(true)
          }}
        >
          <Grid container columnSpacing={3} rowSpacing={4}>
            <Grid item xs={12}>
              <Field
                as={TextField}
                fullWidth
                id='nome_aluno'
                name='nome_aluno'
                label='Nome'
                variant='outlined'
                placeholder='Digite aqui o nome'
                helperText={submit && errors.nome_aluno}
                error={submit && errors.nome_aluno}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                id='cod_matricula'
                name='cod_matricula'
                label='Matrícula'
                helperText={submit && errors.cod_matricula}
                error={submit && !!errors.cod_matricula}
                variant='outlined'
                placeholder='Digite aqui a matrícula'
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                InputLabelProps={{ shrink: true, required: true }}
                type='date'
                as={TextField}
                fullWidth
                id='dt_nascimento'
                name='dt_nascimento'
                label='Data de nascimento'
                variant='outlined'
                placeholder='Selecione a data de nascimento'
                helperText={submit && errors.dt_nascimento}
                error={submit && errors.dt_nascimento}
              />
            </Grid>

            <Grid item xs={4}>
              <Field
                as={TextField}
                select
                id='tp_sexo'
                name='tp_sexo'
                label='Sexo'
                helperText={submit && errors.tp_sexo}
                error={submit && !!errors.tp_sexo}
                variant='outlined'
                placeholder='Selecione o sexo'
                fullWidth
              >
                {optionsGenreSelect.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={4}>
              <Field
                as={TextField}
                fullWidth
                id='rg'
                name='rg'
                label='RG'
                type="number"
                variant='outlined'
                placeholder='Digite aqui o RG'
                helperText={submit && errors.rg}
                error={submit && errors.rg}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                as={TextField}
                fullWidth
                id='etapa_ensino'
                name='etapa_ensino'
                variant='outlined'
                helperText={submit && errors.etapa_ensino}
                error={submit && errors.etapa_ensino}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                as={TextField}
                fullWidth
                id='nome_mae'
                name='nome_mae'
                label='Nome da mãe'
                variant='outlined'
                placeholder='Digite aqui o nome da mãe'
                helperText={submit && errors.nome_mae}
                error={submit && errors.nome_mae}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                select
                id='ano'
                name='ano'
                label='Ano'
                helperText={submit && errors.ano}
                error={submit && !!errors.ano}
                variant='outlined'
                placeholder='Selecione o ano'
                fullWidth
                value={anoTurma}
                onChange={(e: any) => setAnoTurma(e.target.value)}
                disabled
              >
                {optionsYearsSelect.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                as={TextField}
                fullWidth
                id='nome_turma'
                name='nome_turma'
                label='Turma'
                variant='outlined'
                placeholder='Digite aqui o nome da turma'
                helperText={submit && errors.nome_turma}
                error={submit && errors.nome_turma}
                value={nomeTurma}
                onChange={(e: any) => setNomeTurma(e.target.value)}
                disabled
              />
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '40px',
            }}
          >
            <Button
              variant='text'
              style={{
                color: 'black',
                textTransform: 'none',
                fontWeight: '700',
              }}
              disableElevation
              type='reset'
              onClick={onCancel}
            >
              Cancelar
            </Button>

            <Button
              disableElevation
              style={{
                backgroundColor: '#083CA6',
                textTransform: 'none',
                fontWeight: '700',
                color: 'white',
              }}
              type='submit'
            >
              {confirmButtonLabel}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default MaintenanceStudentForm
