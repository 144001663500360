import { useState, useEffect } from 'react'

import BasicModal from '../../../../../components/ModalManagement/BasicModal'
import { Box, Button, styled, Typography } from '@mui/material'
import {
  Label,
  Group,
  InputText,
  ErrorMessage,
} from '../../../../../components/FigmaBasicComponents'

import { Alert } from '../../../../../components/Alert'

import { useFormik } from 'formik'
import { validationSchema } from './validations'
import { appendGestorToSchool, editSchoolsData } from '../../../../../api/school'
import { cpfMask, removeSpecialCharacters } from '../../../../../utils/string'
import { filterUsersDataTabbed } from '../../../../../api/user'
import { deleteUserProfile } from '../../../../../api/profile'
import { getLocalUserData } from '../../../../../services/localStorage'

type Props = {
  open: boolean
  close: (value: boolean) => void
  update: () => void
  data: any
}

const TypographyStyled = styled(Typography)({
  backgroundColor: '#f7f8fa',
  width: '100%',
  padding: '8px 3px',
  borderRadius: '5px',
})

export default function EditSchool(props: Props) {
  const userData = getLocalUserData()

  const [openEdit, setOpenEdit] = useState(false)
  const [uf, setUf] = useState('')
  const [city, setCity] = useState('')
  const [idEscola, setIdEscola] = useState('')
  const [nomeEscola, setNomeEscola] = useState('')
  const [endereco, setEndereco] = useState('')
  const [cpf, setCpf] = useState('')
  const [gestor, setGestor] = useState('')
  const [hasValidCpf, setHasValidCpf] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const initialValues = {
    id_escola: '',
    nome: '',
    estado: '',
    municipio: '',
    endereco: '',
    cpf: '',
  }

  const [originalValues, setOriginalValues] = useState({
    id_escola: '',
    nome: '',
    estado: '',
    municipio: '',
    endereco: '',
    cpf: '',
  })

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if ((gestor && cpf) || (!gestor && !cpf) || (gestor && !cpf)) {
        handleSubmit(
          {
            id_escola: parseInt(values.id_escola),
            nome_escola: values.nome,
            endereco: values.endereco,
            uf: values.estado,
            municipio: values.municipio,
          },
          parseInt(values.id_escola)
        )
      }
    },
  })

  useEffect(() => {
    setOpenEdit(props.open)
  }, [props.open])

  useEffect(() => {
    if (props.data) {
      setIdEscola(props.data.id_escola)
      setNomeEscola(props.data.nome_escola)
      setUf(props.data.uf)
      setCity(props.data.municipio)
      setEndereco(props.data.endereco)
      setCpf('')
      setGestor(props.data.gestor_escolar)

      setOriginalValues({
        id_escola: props.data.id_escola,
        nome: props.data.nome_escola,
        estado: props.data.uf,
        municipio: props.data.municipio,
        endereco: props.data.endereco,
        cpf: '',
      })

      formik.setValues({
        id_escola: props.data.id_escola,
        nome: props.data.nome_escola,
        estado: props.data.uf,
        municipio: props.data.municipio,
        endereco: props.data.endereco,
        cpf: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  useEffect(() => {
    if ((gestor && cpf) || (!gestor && !cpf)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }

    if (cpf.length === 14) {
      filterUsersDataTabbed(removeSpecialCharacters(cpf), 1, 0)
        .then((data) => {          
          setHasValidCpf(true)
          setGestor(data.results[0].nome_usuario)
          setDisabled(false)

          formik.setValues({
            id_escola: idEscola,
            nome: nomeEscola,
            estado: uf,
            municipio: city,
            endereco: endereco,
            cpf: cpf,
          })
        })
        .catch((error) => {
          setHasValidCpf(true)
          setGestor('')
          formik.setValues({
            id_escola: idEscola,
            nome: nomeEscola,
            estado: uf,
            municipio: city,
            endereco: endereco,
            cpf: cpf,
          })
        })
    } else {
      setGestor(props.data?.gestor_escolar)
      setHasValidCpf(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf])

  useEffect(() => {
    props.close(openEdit)
    formik.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!openEdit])

  useEffect(() => {
    formik.setValues({
      id_escola: idEscola,
      nome: nomeEscola,
      estado: uf,
      municipio: city,
      endereco: endereco,
      cpf: cpf,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nomeEscola, uf, city, endereco, cpf])

  const isObjectEqual = (obj1: any, obj2: any) => {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) {
      return false
    }

    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false
      }
    }
    return true
  }

  const handleSubmit = async (payload: any, id: number) => {
    editSchoolsData(payload, id)
      .then((res) => {
        if (cpf) {
          if(props.data.id_gestor !== '') {
            deleteUserProfile(props.data.id_gestor).then(() => {
              appendGestorToSchool({
                id_perfil: 50,
                id_usuario: removeSpecialCharacters(cpf),
                id_escola: parseInt(idEscola),
                id_concessor: userData.cpf,
                municipio: props.data.municipio,
                uf: props.data.uf,
              }).then(() => {
                Alert({
                  type: 'success',
                  message: 'Escola atualizada com sucesso!',
                  autoClose: true,
                })
              }).catch((err) => {
                Alert({
                  type: 'error',
                  message: err.response.data.message || "Ocorreu um erro ao realizar a troca de gestor",
                  autoClose: true,
                })
              })
            }).catch((err) => {
              Alert({
                type: 'error',
                message: err.response.data.message || "Ocorreu um erro ao realizar a troca de gestor",
                autoClose: true,
              })
            })
          } else { 
            appendGestorToSchool({
              id_perfil: 50,
              id_usuario: removeSpecialCharacters(cpf),
              id_escola: parseInt(idEscola),
              id_concessor: userData.cpf,
              municipio: props.data.municipio,
              uf: props.data.uf,
            }).then((data) => {
              Alert({
                type: 'success',
                message: 'Escola atualizada com sucesso!',
                autoClose: true,
              })
            }).catch((err) => {
              Alert({
                type: 'error',
                message: err.response.data.message || "Ocorreu um erro ao realizar a atribuição de gestor",
                autoClose: true,
              })
            })
          }
        } else {
          Alert({
            type: 'success',
            message: 'Escola atualizada com sucesso!',
            autoClose: true,
          })
        }
        props.close(false)
        props.update()
      })
      .catch((error) => {
        Alert({
          type: 'error',
          message: 'Ops! Ocorreu um erro ao tentar editar a escola.',
          autoClose: true,
        })
        props.close(false)
      })
  }

  return (
    <BasicModal title='Editar escola' open={openEdit} close={setOpenEdit}>
      <form
        style={{
          width: '100%',
        }}
        onSubmit={formik.handleSubmit}
      >
        <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
                gap: '16px',
                width: '100%',

                height: '100%',
              }}
              className='max-h-[400px] overflow-y-scroll'
        >
          <Group mdFlexDirection='col'>
            <Typography color='gray' fontSize='14px'>ID Escola</Typography>
            <TypographyStyled color='gray'>{idEscola}</TypographyStyled>
          </Group>

          <Group mdFlexDirection='col'>
            <Typography color='black' fontWeight={700}>
              Dados da escola
            </Typography>
            <Typography color='gray' fontSize='14px' mt={1.5}>Nome</Typography>
            <TypographyStyled color='gray'>{nomeEscola}</TypographyStyled>
            
            <Typography color='gray' fontSize='14px' mt={1}>Estado</Typography>
            <TypographyStyled color='gray'>{uf}</TypographyStyled>
            <Typography color='gray' fontSize='14px' mt={1}>Município</Typography>
            <TypographyStyled color='gray'>{city}</TypographyStyled>
            <Typography color='gray' fontSize='14px' mt={1}>Endereço</Typography>
            <InputText
              id='address'
              type='text'
              name='address'
              placeholder='Digite aqui o endereço'
              value={endereco}
              disabled={props.data?.id_gestor === '' && props.data?.gestor_escolar !== ''}
              style={{opacity: props.data?.id_gestor === '' && props.data?.gestor_escolar !== '' ? 0.5 : 1}}
              onChange={(e: any) => {
                setEndereco(e.target.value)
                formik.handleChange(e)
              }}
            />
            <ErrorMessage
              error={formik.touched.endereco && formik.errors.endereco}
            >
              {formik.touched.endereco && formik.errors.endereco}
            </ErrorMessage>
          </Group>
         
          <Group mdFlexDirection='col'>
            <Label color='black' fontWeight={700}>
              Gestor vinculado
            </Label>
          </Group>

          {props.data?.id_gestor !== '' || props.data?.gestor_escolar === '' ? (
            <Group mdFlexDirection='col'>
              <Label>CPF</Label>
              <InputText
                id='cpf'
                type='text'
                name='cpf'
                maxLength={15}
                placeholder='Digite aqui o CPF do gestor'
                onChange={(e: any) => {
                  setCpf(cpfMask(e.target.value))
                  formik.handleChange(e)
                }}
                value={cpf}
              />
              <ErrorMessage error={formik.touched.cpf && formik.errors.cpf}>
                {formik.touched.cpf && formik.errors.cpf}
              </ErrorMessage>
              <ErrorMessage error={hasValidCpf && !gestor}>
                {hasValidCpf &&
                  !gestor &&
                  'Nenhum gestor encontrado para este CPF. Verifique-o novamente e tente outra vez.'}
              </ErrorMessage>
            </Group>
          ) : null}

          {gestor && (
            <Group
              mdFlexDirection='col'
              style={{
                display: gestor ? 'flex' : 'none',
              }}
            >
              <Label>Nome do gestor</Label>
              <InputText
                id='gestor'
                type='text'
                name='gestor'
                value={gestor}
                disabled
              />
            </Group>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            pt: 2,
            alignContent: 'space-between',
          }}
        >
          <Button
            id='cancel-button-edit'
            variant='text'
            style={{
              color: 'black',
              textTransform: 'none',
              fontWeight: '700',
            }}
            disableElevation
            type='reset'
            onClick={() => props.close(false)}
          >
            Cancelar
          </Button>

          <Box sx={{ flex: '1 1 auto' }} />

          <Button
            id='submit-button-edit'
            disableElevation
            variant='contained'
            style={{
              backgroundColor: '#154da6',
              textTransform: 'none',
              fontWeight: '700',
              color: 'white',
              opacity: isObjectEqual(originalValues, formik.values) || disabled ? 0.5 : 1,
            }}
            type='submit'
            disabled={
              isObjectEqual(originalValues, formik.values) || disabled
                ? true
                : false
            }
          >
            Salvar alterações
          </Button>
        </Box>
      </form>
    </BasicModal>
  )
}
