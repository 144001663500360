import styled from "styled-components";


export const FrameRoot = styled.div`
background-color: #ffffff;
display: flex;
flex-direction: column;
gap: 24px;
justify-content: flex-start;
align-items: flex-start;
margin: auto;
width: calc(100vw - 4vw);
padding: 24px;
border-radius: 4px;
margin-bottom: 30px;
@media (min-width: 768px) {
  width: auto;
  margin: 0 5% 32px 5%;
}
`;
export const FlexRow = styled.div`
display: flex;
flex-direction: row;
gap: 16px;
justify-content: flex-start;
align-items: center;
align-self: stretch;
width: 100%;
`;
export const ButtonBase = styled.div`
width: 40px;
height: 40px;
display: flex;
overflow: hidden;
flex-direction: row;
justify-content: center;
align-items: center;
border-radius: 4px;
padding: 8px;
`;
export const Text2 = styled.div`
font-size: 20px;
font-family: Inter;
font-weight: 500;
line-height: 30px;
`;
export const Text4 = styled.div`
font-size: 14px;
font-family: Inter;
font-weight: 400;
line-height: 23.8px;
`;
export const ButtonBase1 = styled.div`
border-width: 1px;
border-color: #dbdce5;
border-style: solid;
background-color: #ffffff;
display: flex;
overflow: hidden;
flex-direction: row;
justify-content: flex-start;
align-items: center;
align-self: stretch;
flex-grow: 1;
border-radius: 4px;
padding: 8px 16px;
gap: 8px;
`;
export const ButtonLabel = styled.div`
font-size: 14px;
font-family: Inter;
font-weight: 700;
line-height: 14px;
color: #073ca5;
display: inline-block;
`;
export const FlexColumn5 = styled.div`
width: 143px;
display: flex;
flex-direction: column;
gap: 4px;
justify-content: flex-start;
align-items: flex-start;
`;
export const Button1 = styled.button`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
padding: 0px;
border-width: 0px;
background: none;
box-sizing: content-box;
cursor: pointer;
&: hover {
  opacity: 70%;
}
`;
export const Arrowleft = styled.img`
width: 24px;
height: 24px;
`;
export const FlexColumn = styled.div`
display: "flex";
flex-direction: "column";
justify-content: flex-start;
align-items: flex-start;
flex-grow: 1;
`;
export type FlexR = {
alignItems: string;
};
export const FlexRow1 = styled.div<FlexR>`
display: flex;
flex-direction: row;
gap: 24px;
justify-content: flex-start;
align-self: stretch;
width: 100%;
align-items: ${(props) => props.alignItems};
`;
export type FlexCol1 = {
width: string;
};
export const FlexColumn1 = styled.div<FlexCol1>`
display: flex;
flex-direction: column;
gap: 4px;
justify-content: flex-start;
align-items: flex-start;
flex-grow: 1;
width: ${(props) => props.width};
`;
export const Text1 = styled.div`
font-size: 12px;
font-family: Inter;
font-weight: 400;
line-height: 15.96px;
`;

export const FlexRow2 = styled.div<FlexR>`
display: flex;
flex-direction: row;
gap: 24px;
justify-content: flex-start;
flex-grow: 1;
align-items: ${(props) => props.alignItems};
`;
export type ParagraphProp = {
alignSelf: string;
};
export const Paragraph = styled.div<ParagraphProp>`
font-size: 16px;
font-family: Inter;
font-weight: 400;
line-height: 24px;
align-self: ${(props) => props.alignSelf};
`;
