import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getHasResource } from "../../../api/user";

import { MainHeader } from "../../../components/Header";
import { Bread } from "../../../components/Bread/bread";
import TableManageStudents from "./components/TableManageStudents";

import Box from "@mui/material/Box";
import TopCardScholls from "./components/TopCardScholls";
import { Footer } from "../../../components/Footer";

export default function ManageSchool() {  

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Gerenciar Alunos";
    getHasResource("79").then((res) => {
      return res ? navigate('/') : null
    })
  }, [])
  
  const { idTurma = "" } = useParams();
  
  return (
    <>
      <MainHeader
        username={"Redux?"}
        subtitles={["Gerenciamento de escolas"]}
      />
      <Bread texto={"Gerenciamento de escolas"}/>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          padding: "24px 0 32px 0",
          background: "#f2f5fd",
          "@media (min-width: 768px)": {
            width: "100%",
          },
        }}
      >
        <Box sx={{ marginTop: "21px" }}>
          <TopCardScholls {...{ idTurma }}/>
          <TableManageStudents  {...{ idTurma }}/>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
