import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ApartmentIcon from '@mui/icons-material/Apartment'
import IosShareIcon from '@mui/icons-material/IosShare'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
  TableContainer,
  ButtonBase,
  IconButton,
  FormControl,
  FormControlLabel,
} from '@mui/material'

import EditSchool from '../EditSchoolForm'
import DeleteSchool from '../DeleteSchoolForm'
import { CustomPagination } from '../../../../../components/CustomPagination'
import { Alert } from '../../../../../components/Alert'
import { Overload } from '../../../../../components/Overload'
import controlRequest from '../../../../../utils/controlRequest'
import { InputText } from '../../../../../components/FigmaBasicComponents'

import { filterSchools, getSchoolsData } from '../../../../../api/school'

export default function TableManageSchools(props: any) {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)

  const rowsPerPage = props.rowsPerPage || 10

  const [data, setData] = useState(props.data)
  const [rowData, setRowData] = useState()

  const [page, setPage] = useState(1)
  const [pageFilterSchool, setPageFilterSchool] = useState('')

  const [pageCount, setPageCount] = useState(
    Math.ceil(props.dataCount / rowsPerPage)
  )

  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)

  const [timer, setTimer]: any = useState(0)

  const filterSchool = async (value: string) => {
    setLoading(true)
    if (value) {
      return filterSchools(
        { limit: rowsPerPage, offset: (page - 1) * 10 },
        value
      )
        .then((res) => {
          setData(res.results)
          setPageCount(Math.ceil(res.count / rowsPerPage))
          if (res.count > 0) return setPage(1)
          setPage(0)
        })
        .catch((error) => {
          Alert({
            type: 'error',
            message:
              'Ops! Ocorreu um erro ao filtrar os dados. Tente novamente.',
            autoClose: true,
          })
        })
        .finally(() => setLoading(false))
    } else {
      setData(props.data)
      setPageCount(Math.ceil(props.dataCount / rowsPerPage))
      setPage(1)
      setLoading(false)
    }
  }

  /* Props Use Effect */
  useEffect(() => {
    setData(props.data)
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data])

  useEffect(() => {
    setPageCount(Math.ceil(props.dataCount / rowsPerPage))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataCount])

  const goTo = (idEscola: number) => {
    navigate(`/turmas/${idEscola}`)
  }

  const changePage = (page: number) => {
    getSchoolsData(10, (page - 1) * 10).then((schoolsData) => {
      setData(schoolsData.results)
      setPage(page)
    })
  }

  const changeFilteredPage = (page: number) => {
    filterSchools(
      { limit: rowsPerPage, offset: (page - 1) * 10 },
      pageFilterSchool
    ).then((res) => {
      setData(res.results)
      setPage(page)
    })
  }

  const handleListSchools = () => {
    getSchoolsData()
      .then((res) => {
        setData(res.results)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        Alert({
          type: 'error',
          message:
            'Ops! Ocorreu um erro ao tentar atualizar alista de escolas.',
          autoClose: true,
        })
        props.close(false)
      })
    setRowData(undefined)
  }

  const handleDelete = (row: any) => {
    setOpenDelete(true)
    setRowData(row)
    props.setReload(!props.reload)
  }

  const handleEdit = (row: any) => {
    setOpenEdit(true)
    setRowData(row)
    props.setReload(!props.reload)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '644px',
        padding: '24px',
        borderRadius: '4px',
        background: '#fff',
        paddingX: '16px',
        width: `calc(100vw - 4vw)`,
        '@media (min-width: 900px)': {
          width: 'auto',
          marginX: '5%',
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '32px',
            width: '100%',
            '@media (max-width: 900px)': {
              flexDirection: 'column',
              gap: '16px',
              marginBottom: '24px',
            },
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <ApartmentIcon
              style={{
                color: '#083CA6',
                background: '#F2F5FD',
                width: '48px',
                height: '48px',
                padding: '12px',
                borderRadius: '50%',
                marginRight: '16px',
              }}
            />
            <Typography
              style={{
                fontSize: '2rem',
                color: '#083CA6',
              }}
            >
              Escolas
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
              justifyContent: 'space-between',
              '@media (max-width: 900px)': {
                width: '100%',
                flexDirection: 'column',
              },
            }}
          >
            <FormControl
              sx={{
                '@media (min-width: 900px)': { width: '350px' },
                width: '100%',
              }}
              size='small'
            >
              <InputText
                id='input-filter'
                name='input-filter'
                type='text'
                placeholder='Pesquise a escola por nome ou inep'
                onChange={(e) => {
                  controlRequest(
                    filterSchool,
                    e.target.value,
                    1000,
                    timer,
                    setTimer
                  )
                  setPageFilterSchool(e.target.value)
                }}
              />
            </FormControl>
          </Box>
        </Box>

        <TableContainer className='mb-[24px]'>
          <Overload loading={loading}>
            <Table
              sx={{
                tableLayout: 'fixed',
                border: '2px solid #F7F8FA',
                width: '1200px',
                '@media (min-width: 900px)': {
                  width: '100%',
                },
              }}
              padding='none'
            >
              <TableHead>
                <TableRow style={{ height: '48px', background: '#F7F8FA' }}>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='35%'
                    align='left'
                  >
                    Nome e Endereço
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='10%'
                    align='left'
                  >
                    Cidade
                  </TableCell>

                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='5%'
                    align='left'
                  >
                    Estado
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='16%'
                    align='left'
                  >
                    Gestor escolar
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='10%'
                    align='left'
                  >
                    Data de inclusão
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='10%'
                    align='center'
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((result: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell sx={{ padding: '0 12px' }} width='16%'>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          padding: '0px',
                          gap: '4px',
                          width: '100%',
                          height: '44px',
                          flex: 'none',
                          order: 0,
                          flexGrow: 0,
                          cursor: 'pointer',
                        }}
                        className='truncate ...'
                        onClick={() => goTo(result.id_escola)}
                      >
                        <div
                          style={{
                            textTransform: 'uppercase',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '170%',
                            color: '#000000',
                          }}
                        >
                          {result.nome_escola}
                        </div>
                        <div
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '12px',
                            lineHeight: '133%',

                            color: '#404554',
                          }}
                        >
                          {result.endereco}
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      sx={{ padding: '0 12px' }}
                      className='truncate ...'
                    >
                      {result.municipio}
                    </TableCell>

                    <TableCell
                      sx={{ padding: '0 12px' }}
                      width='16%'
                      className='truncate ...'
                    >
                      {result.uf}
                    </TableCell>
                    <TableCell
                      sx={{ padding: '0 12px' }}
                      className='truncate ...'
                    >
                      {result.gestor_escolar}
                    </TableCell>

                    <TableCell sx={{ padding: '0 12px' }} width='16%'>
                      {result.data_criacao}
                    </TableCell>
                    <TableCell sx={{ padding: '0 12px' }} align='right'>
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Editar'
                            onClick={() => handleEdit(result)}
                            disableRipple
                          >
                            <EditIcon sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={''}
                      />
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Excluir'
                            onClick={() => handleDelete(result)}
                            disableRipple
                          >
                            <DeleteIcon color='error' sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={''}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Overload>
        </TableContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px',
            gap: '24px',

            width: '100%',
          }}
          className='flex-col sm:flex-row justify-between'
        >
          <ButtonBase
            sx={{
              boxSizing: 'border-box',

              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '8px 16px',
              gap: '8px',

              width: '156px',
              height: '40px',

              background: '#FFFFFF',

              border: '1px solid #DBDDE5',
              borderRadius: '4px',
            }}
            className='self-center sm:self-stretch'
          >
            <IosShareIcon style={{ color: '#083CA6' }} />
            <div
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '100%',
                color: '#083CA6',
                flex: 'none',
                order: 1,
                flexGrow: 0,
              }}
            >
              Exportar lista
            </div>
          </ButtonBase>

          <CustomPagination
            variant='outlined'
            shape='rounded'
            count={pageCount}
            page={page}
            onChange={(e, value) =>
              pageFilterSchool ? changeFilteredPage(value) : changePage(value)
            }
            size='small'
            className=''
          />
        </Box>
      </Box>

      {/* EDIT MODAL */}
      <EditSchool
        open={openEdit}
        close={setOpenEdit}
        data={rowData}
        update={handleListSchools}
      />

      {/* DELETE MODAL */}
      <DeleteSchool
        open={openDelete}
        close={setOpenDelete}
        data={rowData}
        update={handleListSchools}
      />
    </Box>
  )
}
