import axios from 'axios'

import {
  getLocalToken,
  setLocalToken,
  setLocalCPF,
  setLocalRefreshToken,
  getUserProfile,
  setUserProfile,
} from '../services/localStorage'
import { getAPI } from '../utils/currentApi'
import { listProfiles } from './profile'

const API_URL: string = getAPI()

const api = axios.create({
  baseURL: API_URL,
})

export const userLogin = async (userCPF: string, password: string) => {
  const cleanCPF = userCPF.replace(/[^\d]/g, '')

  return await api
    .post('govbr/user/token/', {
      cpf: cleanCPF,
      password,
    })
    .then((res) => {
      setLocalToken(res.data.access)
      setLocalRefreshToken(res.data.refresh)

      if(getUserProfile() === '' || getUserProfile() === "undefined") {
        listProfiles().then((res)=> {
          setUserProfile(res.results[0].ids[0])
          
          res.results.forEach((profile: any) => {
            if(profile.perfil === "Pre-cadastro") {
              setUserProfile(profile.ids[0])
            }
          })
        })
      }

      const success = res.status === 200
      if (success) {
        setLocalCPF(cleanCPF)
      }

      return success
    })
    .catch(() => {
      return false
    })
}

export const userRegister = async (info: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const cleanCPF = info.cpf.replace(/[^\d]/g, '')

  const res = await api.post(
    'govbr/user/',
    {
      cpf: cleanCPF,
      email: info.email,
      nome_usuario: info.username,
      password: info.password,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res
}

export const getUsersData = async () => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get('govbr/user/', {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getUsersDataTabbed = async (limit: number, offset: number) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`govbr/user/?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getProfilesDataTabbed = async (limit: number, offset: number) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`govbr/perfil/?limit=${limit}&offset=${offset}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getUserData = async (userCPF: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`govbr/user/${userCPF}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
      
    },
  })

  return res.data
}

export const editUser = async (info: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.patch(
    `govbr/user/${info.cpf}/`,
    {
      cpf: info.cpf,
      email: info.email,
      celular: info.phoneNumber,
      password: info.password,
      nome_usuario: info.username,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res.data
}

export const deleteUser = async (userCPF: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  return await api
    .delete(`govbr/user/${userCPF}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    })
    .then((res) => {
      return res.status === 204
    })
    .catch(() => {
      return false
    })
}

export const editPassword = async (userCPF: string, password: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  return await api
    .put(
      `govbr/users/${userCPF}/`,
      {
        password: password.old,
        newPassword: password.new,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'id-profile': userProfile
        },
      }
    )
    .then((res) => {
      return res.status === 200
    })
    .catch(() => {
      return false
    })
}

export const getCustom = async (userCPF: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`govbr/custom/perfis/${userCPF}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getModule = async (system_id: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`govbr/perfil/modulo/${system_id}`, {
    params: {
      limit: 10000,
      offset: 0,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getPermitedProfiles = async (userCPF: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`govbr/custom/perfis/${userCPF}?remover=True`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const addStudent = async (info: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.post(
    'govbr/aluno/',
    {
      nome_turma: info.className,
      nome_aluno: info.name,
      id_turma: info.classId,
      cpf: info.id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res.status
}

export const deactivateAccount = (userCPF: string, active: boolean) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = api.patch(
    `/govbr/user/${userCPF}/`,
    {
      active,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res
}

export const getLoggedUserData = async () => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get('/govbr/user/v2/info/5/', {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const filterUsersDataTabbed = async (
  search: string,
  limit: number,
  offset: number,
  isGuest?: boolean
) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  const url = search !== '' ? `govbr/user/filter/${search}` : `govbr/user`
  const params = isGuest ?{
    limit,
    offset,
    solicitacao_perfil: isGuest,
  } : {
    limit,
    offset,
  }

  const res = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
    params,
  })

  return res.data
}

export const getProfiles = async (cpf: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`/govbr/user/${cpf}/`, {
    params: {},
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getIsAdmin = async () => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`/govbr/custom/credenciais/`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.status === 200
}

export const postGuest = async (data: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.post(`/govbr/visitante/`, {
    cpf_visitante: data.cpf,
    nome: data.name,
    area_conhecimento: data.area,
    id_perfil: data.idProfile,
    dependencia_administrativa: data.depedency,
    municipio: data.city,
    estado: data.state,
    escola: data.school,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const deleteGuest = async (guestId: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  
  const res = await api.delete(`/govbr/visitante/${guestId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res
}

export const putGuest = async (data: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  
  const res = await api.patch(`/govbr/visitante/${data.guestId}`, {
    municipio: data.city,
    estado: data.state,
    id_perfil: data.idProfile,
    id_escola: data.school,
    visitande_aprovado: data?.visitante_aprovado || false,
    id_usuario_aprovou: data?.id_usuario_aprovou,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getIsGuest = async (userId?: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`/govbr/visitante/precadastro`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.status === 200
}

export const getGuest = async (userId?: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`/govbr/visitante/`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
    params: {
      cpf_visitante: userId
    }
  })

  return res.data;
}

export const getHasResource = async (resource: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`/govbr/user/authorization/${resource}/`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.status === 200
}