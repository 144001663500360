import { useState, useEffect, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import FormControlLabel from '@mui/material/FormControlLabel'
import BasicModal from '../../../../../components/ModalManagement/BasicModal'
import {
  Button,
  TextField,
  IconButton,
  FormControl,
  Table,
  Box,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableContainer,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete'
import GroupIcon from '@mui/icons-material/Group'
import AddIcon from '@mui/icons-material/Add'

import { Alert } from '../../../../../components/Alert'
import { CustomPagination } from '../../../../../components/CustomPagination'

import { IClasses } from '../../types'

import MaintenanceClassForm from '../MaintenanceClassForm'
import DeleteClassForm from '../DeleteClassForm'
import ClassesService from '../../../../../services/classes'
import { AxiosResponse } from 'axios'
import { IDataClass } from '../../../../../interfaces/classes'
import { serializeClass } from '../../utils/serializeClass'
import { removeMaskCPF } from '../../../../../utils/string'
import { getTeacherClass } from '../../../../../api/school'
import { getHasResource } from '../../../../../api/user'

export interface ITableManageClasses {
  idEscola: string
}

const TableManageClasses: React.FC<ITableManageClasses> = ({ idEscola }) => {
  const rowsPerPage = 10
  const navigate = useNavigate()
  const [classes, setClasses] = useState<Array<IClasses>>([])
  const [openModalMaintenance, setOpenModalMaintenance] = useState(false)
  const [actionTitle, setActionTitle] = useState<string>('Adicionar turma')
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [classInAction, setClassInAction] = useState<IClasses>({} as IClasses)

  const [searchKey, setSearchKey] = useState('')
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const { getFiltredClassByScholl, postClass, postTeacher, putTeacher, deleteClass, putClass } =
    ClassesService()

  const [hasCreateClassPermission, setHasCreateClassPermission] = useState(false)

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(1)
    setSearchKey(event.target.value)
  }
  const handleClickAddClass = async () => {
    setOpenModalMaintenance(true)
    setActionTitle('Adicionar turma')
  }
  const handleClickEditClass = async (classData: IClasses) => {
    setClassInAction(classData)
    setActionTitle('Editar turma')
    setOpenModalMaintenance(true)
  }
  const handleSubmitMaintenanceForm = async (data: IClasses) => {
    const teacherCPF = removeMaskCPF(data.teacher)
    const foundIndex = classes.findIndex((c) => c.id === data.id)
    const sendData = serializeClass.toBackendFormat(data)
    
    setOpenModalMaintenance(false)

    if (foundIndex === -1) {
      await postClass({ ...sendData, id_escola: Number(idEscola) }).then((res) => {
        if(data.teacher.length > 0) {
          postTeacher({id_turma: res.data.id_turma, id_usuario: teacherCPF}).then((res) => {
            Alert({
              type: 'success',
              message: 'Turma criada com sucesso!',
              autoClose: true,
            })
          }).catch((err) => {
            Alert({
              type: 'error',
              message: (err.response.data.non_field_errors[0] + "\nPorém a turma foi editada com sucesso!"),
              autoClose: true,
            })
          })
        } else {
          Alert({
            type: 'success',
            message: 'Turma criada com sucesso!',
            autoClose: true,
          })
        }
      }).catch((err) => {
        Alert({
          type: 'error',
          message: err.response.data.detail,
          autoClose: true,
        })
      })   
    } else { 
      await putClass(sendData).then((res) => {
        if(data.teacher.length > 0) {
          getTeacherClass(res.data.id_turma).then((response) => {
            
            if(response.results.length > 0) {
              putTeacher({id: response.results[0].id, id_turma: Number(data.id), id_usuario: teacherCPF}).then((res) =>{
                Alert({
                  type: 'success',
                  message: 'Turma criada com sucesso!',
                  autoClose: true,
                })
              }).catch((err) => {
                Alert({
                  type: 'error',
                  message: (err.response.data.non_field_errors[0] + "\nPorém a turma foi editada com sucesso!"),
                  autoClose: true,
                })
              })
            } else {
              postTeacher({id_turma: Number(data.id), id_usuario: teacherCPF}).then((res) =>{
                Alert({
                  type: 'success',
                  message: 'Turma criada com sucesso!',
                  autoClose: true,
                })
              }).catch((err) => {
                Alert({
                  type: 'error',
                  message: (err.response.data.non_field_errors[0] + "\nPorém a turma foi editada com sucesso!"),
                  autoClose: true,
                })
              })
            }
          })
          
          
        } else {
          Alert({
            type: 'success',
            message: 'Turma editada com sucesso!',
            autoClose: true,
          })
        }
      }).catch((err) => {
        Alert({
          type: 'error',
          message: err.response.data.detail,
          autoClose: true,
        })
      })
    }
  }

  const handleClickDeleteClass = async (classData: IClasses) => {
    setClassInAction(classData)
    setOpenModalDelete(true)
    setActionTitle(
      `Tem certeza que deseja excluir a turma ${classData.year} ${classData.name}?`
    )
  }
  
  const handleDeleteClass = async (classData: IClasses) => {
    const foundIndex = classes.findIndex((c) => c.id === classData.id)
    if (foundIndex === -1) return
    await deleteClass(classData.id)
    setOpenModalDelete(false)
  }

  useEffect(() => {
    if (openModalMaintenance || openModalDelete) return
    setClassInAction({} as IClasses)
  }, [openModalMaintenance, openModalDelete])

  useEffect(() => {
    if (!idEscola) return
    const delayTime = !searchKey ? 0 : 200
    const requestDelay = setTimeout(async () => {
      await getFiltredClassByScholl(
        idEscola,
        rowsPerPage,
        searchKey,
        rowsPerPage * (page - 1)
      ).then((res: AxiosResponse<{ results: IDataClass[], count: number }>) => {
        setPageCount(Math.ceil(res.data.count / rowsPerPage))
        setClasses(
          res.data.results.map((data) => serializeClass.toFrontendFormat(data))
        )
      })
    }, delayTime)
    return () => clearTimeout(requestDelay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModalDelete, openModalMaintenance, searchKey, page])

  useEffect(() => {
    getHasResource("64").then((res) => {
      setHasCreateClassPermission(res)
    })
  })
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '644px',
          padding: '24px',
          borderRadius: '4px',
          background: '#fff',
          paddingX: '16px',
          width: `calc(100vw - 4vw)`,
          '@media (min-width: 768px)': {
            width: 'auto',
            marginX: '5%',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '32px',
              width: '100%',
              '@media (max-width: 768px)': {
                flexDirection: 'column',
                gap: '16px',
                marginBottom: '24px',
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <GroupIcon
                style={{
                  color: '#083CA6',
                  background: '#F2F5FD',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  borderRadius: '50%',
                  marginRight: '16px',
                }}
              />
              <Typography
                style={{
                  fontSize: '2rem',
                  color: '#083CA6',
                }}
              >
                Turmas
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                justifyContent: 'space-between',
                '@media (max-width: 768px)': {
                  width: '100%',
                  flexDirection: 'column',
                },
              }}
            >
              <FormControl
                sx={{
                  '@media (min-width: 768px)': { width: '350px' },
                  width: '100%',
                }}
                size='small'
              >
                <TextField
                  onChange={handleChangeFilter}
                  label='Pesquise por ano, turma ou professor'
                  inputProps={{ style: { height: '7px' } }}
                  sx={{ '& label[data-shrink="false"]': { top: '-7px' } }}
                />
              </FormControl>

              {
                hasCreateClassPermission && (
                  <Button
                    id='class-table-0'
                    variant='contained'
                    disableElevation
                    startIcon={<AddIcon />}
                    style={{
                      backgroundColor: '#083CA6',
                      textTransform: 'none',
                      fontWeight: '700',
                    }}
                    className=''
                    onClick={() => handleClickAddClass()}
                  >
                    Adicionar turma
                  </Button>
                )
              }
            </Box>
          </Box>
          <TableContainer className='mb-[24px]'>
            <Table
              sx={{
                tableLayout: 'fixed',
                border: '2px solid #F7F8FA',
                width: '1200px',
                '@media (min-width: 768px)': {
                  width: '100%',
                },
              }}
              padding='none'
            >
              <TableHead>
                <TableRow style={{ height: '48px', background: '#F7F8FA' }}>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='5%'
                    align='left'
                  >
                    Ano
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='30%'
                    align='left'
                  >
                    Turma
                  </TableCell>

                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='10%'
                    align='left'
                  >
                    Data de inclusão
                  </TableCell>
                  <TableCell
                    sx={{ padding: '0 12px', fontWeight: 600 }}
                    width='8%'
                    align='center'
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {classes.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell
                      id='class-card-1'
                      sx={{ padding: '0 12px' }}
                      width={'16%'}
                      className='truncate ...'
                    >
                      {item.year}
                    </TableCell>

                    <TableCell
                      id='class-card-2'
                      sx={{ padding: '0 12px', cursor: 'pointer', ":hover": { color: "#1351b4" }, textTransform: 'uppercase' }}
                      className='truncate ...'
                      onClick={() => navigate(`/alunos/${item.id}`)}
                    >
                      {item.name}
                    </TableCell>

                    <TableCell
                      id='class-card-4'
                      sx={{ padding: '0 12px' }}
                      className='truncate ...'
                    >
                      {item.date}
                    </TableCell>
                    <TableCell
                      id='class-card-5'
                      sx={{ padding: '0 12px' }}
                      align='right'
                    >
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Editar'
                            onClick={() => handleClickEditClass(item)}
                            disableRipple
                          >
                            <EditIcon sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={''}
                      />
                      <FormControlLabel
                        control={
                          <IconButton
                            size='small'
                            aria-label='Visualizar'
                            onClick={() => navigate(`/alunos/${item.id}`)}
                            disableRipple
                          >
                            <VisibilityIcon sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={''}
                      />
                      <FormControlLabel
                        control={
                          <IconButton
                            id='class-card-8'
                            size='small'
                            aria-label='Excluir'
                            onClick={() => handleClickDeleteClass(item)}
                            disableRipple
                          >
                            <DeleteIcon color='error' sx={{ m: 1 }} />
                          </IconButton>
                        }
                        label={''}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box display='flex' justifyContent='right'>
          <CustomPagination
            id='class-card-pagination'
            variant='outlined'
            shape='rounded'
            count={pageCount}
            page={page}
            onChange={(e, value) => setPage(value)}
          />
        </Box>
      </Box>
      <BasicModal
        title={actionTitle}
        open={openModalMaintenance}
        close={setOpenModalMaintenance}
      >
        <MaintenanceClassForm
          confirmButtonLabel={actionTitle}
          data={classInAction}
          onConfirm={handleSubmitMaintenanceForm}
          onCancel={() => setOpenModalMaintenance(false)}
        />
      </BasicModal>
      <BasicModal
        title={actionTitle}
        open={openModalDelete}
        close={setOpenModalDelete}
      >
        <DeleteClassForm
          data={classInAction}
          onConfirm={handleDeleteClass}
          onCancel={() => setOpenModalDelete(false)}
        />
      </BasicModal>
    </>
  )
}
export default TableManageClasses
