import httpScanner from "../http/scanner-prova";
import { IDataClass } from "../interfaces/classes";

export default function ClassesService() {
  const getClassByScholl = async (idCScholl: string) => {
    const api = await httpScanner.get(`/govbr/turma/escola/${idCScholl}/`);
    return api;
  };
  const getFiltredClassByScholl = async (id_escola: string ,limit: number, search:string, offset:number) => {
    const api = await httpScanner.get(`/govbr/turma/escola/${id_escola}?filtro=${search}&limit=${limit}&offset=${offset}`);
    return api;
  };

  const getClassById = async (idClass: string) => {
    const api = await httpScanner.get(`/govbr/turma/${idClass}`);
    return api;
  };
  const postClass = async (dataClass: IDataClass) => {
    const api = await httpScanner.post(`/govbr/turma/`, dataClass);
    return api;
  };
  const postTeacher = async (dataClass: any) => {
    const api = await httpScanner.post(`/govbr/turma/professor/`, dataClass);
    return api;
  };
  const putTeacher = async (dataClass: any) => {
    const api = await httpScanner.put(`/govbr/turma/professor/${dataClass.id}`, dataClass);
    return api;
  };
  const getTeacher = async (teacherId: any) => {
    const api = await httpScanner.get(`/govbr/turma/professor?id_turma=${teacherId}`);
    return api;
  };
  const putClass = async (dataClass: IDataClass) => {
    const api = await httpScanner.put(
      `/govbr/turma/${dataClass.id_turma}`,
      dataClass
    );
    return api;
  };
  const deleteClass = async (idClass: number) => {
    const api = await httpScanner.delete(`/govbr/turma/${idClass}`);
    return api;
  };

  return {
    getClassByScholl,
    getFiltredClassByScholl,
    getClassById,
    postClass,
    getTeacher,
    postTeacher,
    putTeacher,
    putClass,
    deleteClass,
  };
}
