import { useEffect, useState } from "react";
import { 
  Box, 
  Button, 
  Modal, 
  Typography, 
  Backdrop 
} from "@mui/material";

import { MoreInfo } from "./MoreInfo";
import { EditInfo } from "./EditInfo";
import { deleteGuest, getGuest, putGuest } from "../../../../../api/user";
import { 
  ApproveButton, 
  BackButton, 
  TextLabel, 
  ModalTitle, 
  ReproveButton, 
  boxStyle, 
  style 
} from './styles'
import { CardRowData } from "../../../../../views/AllManagement/Management/components/CardManagement/types";
import { linkUserToProfile } from "../../../../../api/profile";
import { Alert } from "../../../../../components/Alert";

export type allProfileProps = {
  dependencia_administrativa: string;
  area_conhecimento: string;
  cpf_visitante: string;
  data_visita: string;
  estado: string;
  municipio: string;
  nome: string;
  id_escola: {
    nome_escola: string;
    id_escola: number;
  };
  id_perfil: {
    descricao_perfil: string;
    id_perfil: number;
  };
  id_visitante: number;
}

interface AcceptProfileProps {
  userData: CardRowData;
  loggedUserId: string;
  deleteRow: (row: CardRowData, deleteSolicitationStatus?: boolean, deleteSolicitation?: boolean) => void;
}

export function AcceptProfiles({ userData, loggedUserId, deleteRow }: AcceptProfileProps) {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [profileData, setProfilesData] = useState<allProfileProps>();

  const formatedName = userData.name.toLocaleLowerCase()
    .split(' ')
    .map((s) => {
      return s.length > 2 ? s.charAt(0).toUpperCase() + s.substring(1) : s;
    })
    .join(' ');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setOpenEdit(false)
    
  };

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleSubmit = () => {
    linkUserToProfile({
      profileId: profileData?.id_perfil.id_perfil,
      id: loggedUserId,
      userId: profileData?.cpf_visitante,
      schoolId: profileData?.id_escola.id_escola,
      city: profileData?.municipio,
      state: profileData?.estado,
    }).then((res: any) => {
      Alert({
        type: 'success',
        message: 'Usuário vinculado com sucesso!',
        autoClose: true,
      })
      
      putGuest({
        visitante_aprovado: true,
        id_usuario_aprovou: loggedUserId,
      })
    }).catch((err: any) => {
      Alert({
        type: 'error',
        message: err.response.data.message+'!' || 'Erro ao vincular usuário!',
        autoClose: true,
      })
    })

    handleClose();
  }

  const handleDelete = () => {
    deleteGuest(profileData?.id_visitante).then((res: any) => {
      deleteRow(userData, true, true);
      Alert({
        type: 'success',
        message: 'Usuário reprovado com sucesso!',
        autoClose: true,
      })
    }).catch((err: any) => {
      deleteRow(userData, true, false);
      Alert({
        type: 'error',
        message: err.response.data.message+'!' || 'Erro ao reprovar usuário!',
        autoClose: true,
      })
    })

    handleClose();
  }

  useEffect(() => {
    getGuest(userData.id).then((response) => {
      setProfilesData({...response.results[0], nome: userData.name});
    });
  }, [userData.id, userData.name]);
  
  return (
    <>
      <Button 
        sx={{ 
          border: '1px solid #DBDDE5', 
          borderRadius: '4px', 
          padding: '8px 12px' 
        }} 
        onClick={handleOpen}
      >
        <Typography
          sx={{
            fontWeight: 700,
            color: '#083CA6',
            fontSize: '14px',
            lineHeight: '14px',
            fontFamily: 'Inter, sans-serif',
            textTransform: 'initial',
          }}
        >
          Aprovação pendente
        </Typography>
      </Button>
      <Box>
        <Backdrop
          open={open}
        >
          <Modal
            open={open}
            aria-labelledby="modal-title"
            sx={style}
            hideBackdrop
            disableEnforceFocus
          >
            <Box sx={boxStyle}>
              <ModalTitle as={"h2"} id="modal-title">
              Aprovação pendente
              </ModalTitle>

              <Box sx={{ mt: '32px', bgcolor: '#F7F8FA', p: '16px', borderRadius: '8px' }}>
                <Box>
                  <TextLabel as={'h3'}>
                    Nome
                  </TextLabel>
                  <Typography sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
                    {formatedName}
                  </Typography>
                </Box>
                <Box sx={{ mt: '16px', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                  <Box>
                    <TextLabel>
                      E-mail
                    </TextLabel>
                    <Typography variant="body2" sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
                      {userData.email}
                    </Typography>
                  </Box>

                  <Box>
                    <TextLabel>
                      CPF
                    </TextLabel>
                    <Typography variant="body2" sx={{ mt: '8px', fontSize: '16px', lineHeight: '150%' }}>
                      {userData.id}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              
              <ReproveButton onClick={handleDelete}> Reprovar Solicitação </ReproveButton>

              {!openEdit ? 
                <MoreInfo data={profileData} open={!openEdit} handleOpen={handleOpenEdit} TextLabel={TextLabel} /> :
                <EditInfo data={profileData} setProfilesData={setProfilesData} open={openEdit} handleClose={handleCloseEdit} loggedUserId={loggedUserId} />
              }
              
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '32px' }}>
                <BackButton onClick={handleClose} >
                  Voltar
                </BackButton>
                <ApproveButton onClick={handleSubmit} >
                  Liberar acesso
                </ApproveButton>
              </Box>
            </Box>
          </Modal>
        </Backdrop>
      </Box>
    </>
  )
}