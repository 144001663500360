import React, { Fragment, useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'

import { Dialog, Transition } from '@headlessui/react'

import { addProfileResource, deleteResourceByProfileAndModuleId, editProfile, getProfile } from '../../../../api/profile'
import { getRecursos, getSystemsData } from '../../../../api/system'

import closeIcon from '../../../../assets/icons/blackCloseIcon.svg'
import { Select, Box, Button, MenuItem, Checkbox, ListItemText, FormControl, SelectChangeEvent} from '@mui/material'

export function EditProfile(profileId: string, profile: string) {
  return new Promise((resolve, reject) => {
    addDialog(profileId, profile, resolve)
  })
}

function removeDialog(root: any) {
  root.unmount()

  const div = document.getElementById('modal-edit-profile')
  if (div) {
    div.remove()
  }
}

function addDialog(profileId: string, profile: string, resolve: any) {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')

  div.setAttribute('id', 'modal-edit-profile')
  body.appendChild(div)

  const root = createRoot(div)

  root.render(
    <CreateDialog
      root={root}
      profileId={profileId}
      profile={profile}
      resolve={resolve}
    />
  )
}

function CreateDialog(dialogObj: {
  root: any
  profileId: string
  profile: string
  resolve: any
}) {
  const [systemsResources, setSystemsResources] = useState<any>([])
  const [profileResources, setProfileResources] = useState<any>([])
  const [newName, setNewName] = useState(dialogObj.profile)
  const [newSystems, setNewSystems] : any = useState([])
  const [newAcessoEscolas, setNewAcessoEscolas] = useState()
  const [newAcessoNacional, setNewAcessoNacional] = useState()
  const [level, setLevel] = useState(0)
  const [allSystems, setAllSystems] : any = useState([])

  const [selectedResources, setSelectedResources] = useState<any>([])
  const [open, setOpen] = useState(false)

  const [tab, setTab] = useState(0)
  const [tabs] = useState([
    {
      id: 0,
      label: 'Informações',
      tabMargin: 0,
      tabLength: 100,
    },
    {
      id: 1,
      label: 'Permissões',
      tabMargin: 32,
      tabLength: 100,
    },
  ])
  const handleCloseSelect = () => {
    setOpen(false)
  }
  const handleOpenSelect = () => {
    setOpen(true)
  }
  const handleSystemsChange = (event: SelectChangeEvent<typeof newSystems>) => {
    const {
      target: { value },
    } = event
    
    setNewSystems(
      typeof value === 'string' ? value.split(',') : value,
    )

  }
  const handleClose = () => {
    removeDialog(dialogObj.root)
    
    dialogObj.resolve(false)
  }
  const handleEdit = () => {
    removeDialog(dialogObj.root)

    editProfile({
      id: parseInt(dialogObj.profileId),
      descricao_perfil: newName,
      nivel: level,
      acessoEscola: newAcessoEscolas,
      acessoNacional: newAcessoNacional,
      modulos: newSystems,
      perfilRecurso: selectedResources,
    })
    .then((res: any) => {
      profileResources?.forEach((resource: any) => {
        if(!selectedResources?.includes(resource)){
          deleteResourceByProfileAndModuleId(
            dialogObj.profileId, 
            resource
          )
        }
      })
  
      selectedResources?.forEach((resource: any) => {
        if(profileResources.lenght === 0){
          addProfileResource({
            profileId: dialogObj.profileId,
            resourceId: resource,
          })
        } else if(!profileResources.includes(resource)){
          addProfileResource({
            profileId: dialogObj.profileId,
            resourceId: resource,
          })
        }
      })

      dialogObj.resolve({
        changed: true,
        data: res,
      })
    })
    .catch((err: any) => {
      dialogObj.resolve({ changed: false })
    })
  }
  
  const handleTabChange = (newValue: number) => {
    setTab(newValue)
  }
  const handleCheckboxChange = (e: any) => {
    const { checked, id } = e.target

    const newSelectedResources: string[] = checked
      ? [...selectedResources, parseInt(id)]
      : selectedResources.filter((item: any) => item !== parseInt(id))

    setSelectedResources(newSelectedResources)
  }
  const getWidth = (tab: number) => {
    return tabs.find((item: any) => item.id === tab)?.tabLength
  }
  
  const calculateMargin = (tab: number) => {
    if (tab === 0) {
      return 0
    } else {
      const index = tabs.findIndex((item: any) => item.id === tab)
      
      return tabs
      .slice(0, index)
      .reduce((accumulator: number, currentValue: any) => {
        return (
          accumulator + currentValue.tabLength + currentValue.tabMargin + 32
          )
        }, 0)
      }
  }

  useEffect(() => {
    getProfile({id: dialogObj.profileId})
    .then((profile) => {
      setProfileResources(profile.perfil_recurso)
      setSelectedResources(profile.perfil_recurso)
      setLevel(profile.nivel)
      setNewAcessoEscolas(profile.acesso_escolas)
      setNewAcessoNacional(profile.acesso_nacional)
      setNewName(profile.descricao_perfil)
      setNewSystems(profile.modulos)
    })

    getSystemsData().then((systems) => {
      const systemsData = systems.results.map((system: any) => {
        return {
          id: system.id_modulo,
          nome: system.nome_modulo,
        }
      })
      setAllSystems(systemsData)
    })
  }, [dialogObj.profileId])

  useEffect(() => {
    let selectedSystemsIds: Set<string> = new Set<string>()

    newSystems.forEach((system: string) => {
      allSystems.forEach((allSystem: any) => {
        if(system.localeCompare(""+allSystem.nome) === 0) {
          selectedSystemsIds.add(allSystem.id)
        }
      })
    })

    let allSystemsResources: Set<string> = new Set<string>();

    selectedSystemsIds.forEach((systemId: string) => {
      getRecursos(systemId).then((systemResources: any) => {
        systemResources.results.forEach((resource: any) => {
          allSystemsResources.add(resource)
        })
      }).finally(() => {
        setSystemsResources(Array.from(allSystemsResources.values()))
      })
    })

    
  }, [newSystems, allSystems])

  return (
    <>
      <Transition.Root show as='div'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-[#14161F]  bg-opacity-40 transition-opacity' />
        </Transition.Child>

        <Dialog
          as='div'
          className='w-full z-10 relative'
          onClose={() => {return false}}
          id='basic'
        >
          <div className='fixed z-10 inset-0 overflow-y-auto'>
            <div className='flex items-end sm:items-center justify-center min-h-full text-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                leave='ease-in duration-200'
              >
                <Dialog.Panel className='relative w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-full'>
                  <div className='bg-white'>
                    <div className='sm:flex sm:items-start'>
                      <div className='text-center sm:text-left w-full'>
                        <div className='flex flex-row justify-between mx-8 mt-8'>
                          <Dialog.Title as='h3' className='text-lg font-medium'>
                            Editar perfil de usuário
                          </Dialog.Title>
                          <img
                            id='close_profile'
                            className='cursor-pointer'
                            src={closeIcon}
                            alt='Imagem de um x'
                            onClick={handleClose}
                          />
                        </div>
                        <div className='flex ml-8 mt-12'>
                          {tabs.map((currentTab, index) => (
                            <Button
                              id={'button' + index}
                              variant='text'
                              style={{
                                color:
                                  tab === currentTab.id ? '#083CA6' : '#404554',
                                fontSize: '1rem',
                                fontWeight: 600,
                                textTransform: 'none',
                                padding: '0px',
                                marginLeft: `${currentTab.tabMargin}px`,
                                width: `${currentTab.tabLength}px`,
                              }}
                              onClick={() => handleTabChange(currentTab.id)}
                              key={index}
                            >
                              {currentTab.label}
                            </Button>
                          ))}
                        </div>
                        <Box
                          sx={{
                            height: '3px',
                            maxWidth: '90%',
                            borderRadius: '6px',
                            background: '#B6C4EE',
                            marginLeft: '32px',
                          }}
                        >
                          <Box
                            sx={{
                              height: '3px',
                              width: `${getWidth(tab)}px`,
                              borderRadius: '6px',
                              marginLeft: `${calculateMargin(tab)}px`,
                              transition: 'all 0.3s ease-in-out',
                              background: '#083CA6',
                            }}
                          ></Box>
                        </Box>
                      </div>
                    </div>
                  </div>
                  {tab === 0 && (
                    <>
                      <div className='flex flex-col justify-start bg-white rounded-md'>
                        <div className='flex flex-col mt-12 ml-8 mr-10'>
                          <label className='text-[#5E6475] text-sm'>Nome</label>
                          <input
                            className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded placeholder:text-[#9EA1B0]'
                            type='text'
                            name='name'
                            placeholder='Digite aqui o nome do perfil de usuário'
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                          />
                        </div>                       
                        <div className='flex flex-col mt-2 ml-8 mr-10 py-4'>
                          <label className='text-[#5E6475] text-sm'>
                            Sistema
                          </label>
                          <FormControl>
                            <button onClick={handleOpenSelect} className='absolute z-10 w-full h-full'></button>
                            <Select 
                              open={open}
                              onClose={handleCloseSelect}
                              onOpen={handleOpenSelect}
                              value={newSystems}
                              id='multiple-select-systems'
                              labelId='multiple-select-systems-label'
                              className='w-full text-sm font-[Inter] bg-[#F7F8FA] rounded'
                              multiple
                              onChange={handleSystemsChange}
                              renderValue={(selected) => selected.join(', ')}
                            >
                              {allSystems.map((system: any) => {
                                const checked = newSystems.indexOf(""+system.nome) > -1;

                                return (
                                  <MenuItem key={system.id} value={system.nome} >
                                    <Checkbox checked={checked} />
                                    <ListItemText primary={system.nome} />
                                  </MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </>
                  )}
                  {tab === 1 && (
                    <>
                      <div className='flex flex-col justify-start bg-white rounded-md'>
                        <div className='ml-8 mt-10'>
                          <p className='text-sm'>
                            Selecione as permissões que o perfil de usuário{' '}
                            <span className='font-bold'>{newName}</span> terá
                            no sistema <span className='font-bold'>{newSystems.join(', ')}</span>
                          </p>
                          {systemsResources.map((resource: any, index: React.Key) => {
                            const checkedInput = selectedResources.includes(resource.id_recurso)

                            return (
                            <div className='mt-5' key={index}>
                              <label
                                htmlFor={`${resource.nome_recurso}`}
                                className='text-profile'
                              >
                                <input
                                  className='mr-3'
                                  type='checkbox'
                                  name={`${resource.nome_recurso}`}
                                  id={`${resource.id_recurso}`}
                                  onChange={handleCheckboxChange}
                                  defaultChecked={checkedInput}
                                />
                                {resource.nome_recurso}
                              </label>
                            </div>
                          )})}
                        </div>
                      </div>
                    </>
                  )}
                  <div className='bg-white mt-11 flex justify-between gap-4'>
                    <button
                      id='cancel_profile'
                      type='button'
                      className='w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-bold mb-7 ml-8'
                      onClick={handleClose}
                    >
                      Descartar alterações
                    </button>
                    <button
                      id='edit_profile'
                      type='button'
                      className='w-auto inline-flex justify-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white mb-7 mr-8'
                      onClick={handleEdit}
                    >
                      Salvar alterações
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
