import {
  FormControl,
  Button,
  Box,
  Stack,
  Alert,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { IForm } from "../../types";
import * as yup from "yup";
import { useFormik } from "formik";

const DeleteStudentForm: React.FC<IForm> = ({ data, onConfirm, onCancel }) => {
  const formik = useFormik({
    initialValues: {
      accept: false,
    },
    validationSchema: yup.object({
      accept: yup
        .bool()
        .isTrue("É necessário confirmar para poder excluir o(a) aluno(a)."),
    }),
    onSubmit: () => {
      onConfirm(data);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl fullWidth>
        <Box>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="error">
              <strong>Atenção!</strong> Esta ação não pode ser desfeita.
            </Alert>
          </Stack>
        </Box>
        <FormControl
          sx={{ paddingX: "4px", marginTop: "24px" }}
          error={!!formik.errors.accept}
        >
          <FormControlLabel
            control={
              <Checkbox
                id="accept"
                name="accept"
                onChange={formik.handleChange}
                value="true"
                checked={formik.values.accept}
              />
            }
            label="Confirmo que desejo excluir este aluno e estou ciente que esta é uma ação que não pode ser desfeita."
          />
          <FormHelperText>
            {formik.touched.accept && formik.errors.accept}
          </FormHelperText>
        </FormControl>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "32px",
          }}
        >
          <Button
            variant="text"
            style={{
              color: "black",
              textTransform: "none",
              fontWeight: "700",
            }}
            disableElevation
            type="reset"
            onClick={onCancel}
          >
            Cancelar
          </Button>

          <Button
            disableElevation
            style={{
              backgroundColor: "#920000",
              textTransform: "none",
              fontWeight: "700",
              color: "white",
            }}
            type="submit"
          >
            Excluir aluno
          </Button>
        </Box>
      </FormControl>
    </form>
  );
};
export default DeleteStudentForm;
