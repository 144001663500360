import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Home } from "../views/Home";
import { Login } from "../views/Login";
import { Profile } from "../views/Profile";
import { Management } from "../views/AllManagement/Management";

import { ProtectedRoute } from "./ProtectedRoute";
import { Logout } from "../views/Logout";
import ManageSchool from "../views/AllManagement/ManageSchools";
import ManageClasses from "../views/AllManagement/ManageClasses";
import ManageStudents from "../views/AllManagement/ManageStudents";
import { LoginTest } from "../views/LoginTest";
import { PreRegister } from "../views/PreRegister";
import { SelectProfile } from "../views/SelectProfile/SelectProfile";
import { Contato } from "../views/Contato";
import { LoginContato } from "../views/LoginContato";

export default function MainRoutes() {
  const currentURL = window.location.href;
  const isSTG = currentURL.includes("jorge") || currentURL.includes("stg");

  return (
    <BrowserRouter>
      <Routes>
        {process.env.REACT_APP_MODE === "development" || isSTG ? (
          <Route path="/login" element={<LoginTest />} />
        ) : (
          <Route path="/login" element={<Login />} />
        )}
        <Route path="/logout" element={<Logout />} />

        <Route
          path="/pre-register"
          element={
            <ProtectedRoute>
              <PreRegister />
            </ProtectedRoute>
          }
        />

        <Route
          path="/pre-select"
          element={
            <ProtectedRoute>
              <SelectProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/management"
          element={
            <ProtectedRoute>
              <Management />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contato"
          element={
            <ProtectedRoute>
              <Contato />
            </ProtectedRoute>
          }
        />
        <Route
          path="/escolas"
          element={
            <ProtectedRoute>
              <ManageSchool />
            </ProtectedRoute>
          }
        />

        <Route
          path="/turmas/:idEscola"
          element={
            <ProtectedRoute>
              <ManageClasses />
            </ProtectedRoute>
          }
        />

        <Route
          path="/alunos/:idTurma"
          element={
            <ProtectedRoute>
              <ManageStudents />
            </ProtectedRoute>
          }
        />

        <Route path="/login-contato" element={<LoginContato />} />
        <Route path="*" element={<p>Página não encontrada: 404!</p>} />
      </Routes>
    </BrowserRouter>
  );
}
