import { Fragment, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { Dialog, Transition } from '@headlessui/react'

import { cpfMask, maskCPF, validEmail } from '../../../../utils/string'
import { Alert } from '../../../../components/Alert'
import { getUserData, userRegister } from '../../../../api/user'

import closeIcon from '../../../../assets/icons/blackCloseIcon.svg'

export function AddUser() {
  return new Promise((resolve, reject) => {
    addDialog(resolve)
  })
}

function removeDialog(root: any) {
  root.unmount()

  const div = document.getElementById('modal-add-user')
  if (div) {
    div.remove()
  }
}

function addDialog(resolve: any) {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')

  div.setAttribute('id', 'modal-add-user')
  body.appendChild(div)

  const root = createRoot(div)

  root.render(<CreateDialog root={root} resolve={resolve} />)
}

function CreateDialog(dialogObj: { root: any; resolve: any }) {
  const [isAddUserInfoOpen] = useState(true)

  const [name, setName] = useState('')
  const [cpfInput, setCpfInput] = useState('')
  const [emailInput, setEmailInput] = useState('')
  const [cpfAlerta, setCpfAlerta] = useState('')
  const [emailAlerta, setEmailAlerta] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)

  const handleCPF = (event: React.ChangeEvent<HTMLInputElement>) => {
    let CPF = event.target.value
    
    const cleanCPF = cpfMask(CPF).replace(/[^\d]/g, '')
    if(cleanCPF.length === 11 && maskCPF(cleanCPF)) {
      getUserData(cleanCPF).then((response) => {
        setCpfAlerta('O CPF já está cadastrado.')
      })
    }
    if (!maskCPF(cleanCPF)) {
      setCpfAlerta('CPF inválido')
    } else {
      setCpfAlerta('')
    }
    setCpfInput(cpfMask(CPF))
  }

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value)

    if (!validEmail(event.target.value) && event.target.value.length > 0) {
      setEmailAlerta('O email inserido é inválido.')
    }
    else {
      setEmailAlerta('')
    }
  }

  useEffect(() => {
    if (name.length > 0 && cpfInput.length === 14 && emailInput.length > 0 && emailAlerta.length === 0 && cpfAlerta.length === 0) {
      setIsDisabled(false)
    }
    else {
      setIsDisabled(true)
    }
  }, [name, cpfAlerta, cpfInput, emailAlerta, emailInput])

  const handleClose = () => {
    removeDialog(dialogObj.root)

    dialogObj.resolve()
  }

  const handleAdd = () => {
    const cleanCPF = cpfInput.replace(/[^\d]/g, '')

    const data = {
      username: name,
      cpf: cleanCPF,
      email: emailInput,
    }
    userRegister({
      username: name,
      cpf: cleanCPF,
      email: emailInput,
    })
    .then((res: any) => {
      dialogObj.resolve({
        changed: true,
        data: data,
      })
    })
    .catch((err: any) => {
      Alert({
        type: 'error',
        message: err.response.data.email[0] || 'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        autoClose: true,
      })

      dialogObj.resolve()
    })
    removeDialog(dialogObj.root)
  }

  return (
    <section>
      {isAddUserInfoOpen ? (
        <Transition.Root show as='div'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-[#14161F]  bg-opacity-40 transition-opacity' />
          </Transition.Child>

          <Dialog
            as='div'
            className='w-full z-10 relative'
            onClose={() => {return false}}
            id='basic'
          >
            <div className='fixed z-10 inset-0 overflow-y-auto'>
              <div className='flex items-end sm:items-center justify-center min-h-full text-center sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  leave='ease-in duration-200'
                >
                  <Dialog.Panel className='relative w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-full'>
                    <div className='bg-white'>
                      <div className='sm:flex sm:items-start'>
                        <div className='text-center sm:text-left w-full'>
                          <div className='flex flex-row justify-between mx-8 mt-8'>
                            <Dialog.Title
                              as='h3'
                              className='text-lg font-medium'
                            >
                              Adicionar usuário
                            </Dialog.Title>
                            <img
                              id='close_user'
                              className='cursor-pointer'
                              src={closeIcon}
                              alt='Imagem de um x'
                              onClick={handleClose}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <form className='flex flex-col justify-start bg-white rounded-md mt-7 ml-8'>
                      <p className='text-profile pb-5'>
                        Para criar um novo usuário, é necessário fornecer as
                        seguintes informações:
                      </p>
                      <div className='flex flex-col mt-2 mr-10'>
                        <label className='text-[#5E6475] text-user'>Nome</label>
                        <input
                          className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded placeholder:text-[#9EA1B0]'
                          type='text'
                          placeholder='Digite aqui o nome'
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          name='name'
                        />
                      </div>
                      <div className='flex flex-col mt-2 mr-10'>
                        <label className='text-[#5E6475] text-sm'>CPF</label>
                        <input
                          type='text'
                          onChange={handleCPF}
                          value={cpfInput}
                          name='cpf'
                          placeholder='000.000.00-00'
                          className='placeholder:italic placeholder:text-inputw-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded placeholder:text-[#9EA1B0]'
                          autoComplete='on'
                        />
                        <p className='text-xs p-2 text-[#FF0000]'>{cpfAlerta}</p>
                      </div>
                      <div className='flex flex-col mt-2 mr-10'>
                        <label className='text-[#5E6475] text-user'>
                          E-mail
                        </label>
                        <input
                          name='email'
                          className='w-full mt-2 px-4 py-2 text-sm bg-[#F7F8FA] rounded placeholder:text-[#9EA1B0]'
                          type='text'
                          placeholder='Digite aqui o e-mail'
                          value={emailInput}
                          onChange={handleEmail}
                        />
                        <p className='text-xs p-2 text-[#FF0000]'>{emailAlerta}</p>

                      </div>
                    </form>

                    <div className='bg-white mt-11 flex justify-between gap-4'>
                      <button
                        id='cancel_user'
                        type='button'
                        className='w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-bold mb-7 ml-8'
                        onClick={handleClose}
                      >
                        Cancelar
                      </button>
                      <button
                        id='add_user'
                        type='button'
                        className='w-auto inline-flex justify-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white mb-7 mr-8'
                        onClick={handleAdd}
                        disabled={isDisabled}
                      >
                        Adicionar usuário
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </section>
  )
}
