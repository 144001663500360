import { SelectTw } from "./styles";
import { SelectProps } from "./types";
import { Field, isString } from 'formik'
import HelperText from "../HelperText";
import Label from "../Label";
const SelectArrow = () => {
  return ( 
    <span className="absolute w-1 h-1 top-12 right-5 z-10">
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 5.5L10 0.5L0 0.5L5 5.5Z" fill="#404554"/>
      </svg>
    </span>
  )
}
const Select: React.FC<SelectProps> = ({
  label, 
  helperText, 
  isInvalid = false, 
  options,
  ...rest
}) => {
  const selectProps = {
    id: rest.name,
    ...rest,
    isInvalid
  }
  return (
    <div className="relative">
      <SelectArrow />
      <Label {...{isInvalid}} text={label} />
      <Field as={SelectTw} {...selectProps}>
        <option 
          hidden
          label={rest.placeholder || "Selecione"}
        />
        {options.map((option,index) =>(
          <option 
           value={option.value} 
           key={index}
           label={option.label}
          />
        ))}
      </Field>
      {!!helperText && (
          <HelperText 
            {...{isInvalid}} 
            text={
              isString(helperText) ? helperText : helperText[0]
            } 
          />
        )}
    </div>
  )
}
export default Select
