import * as yup from 'yup'

export const validationSchema = yup.object({
  nome: yup.string().required('O campo nome é obrigatório.'),
  estado: yup
    .string()
    .typeError('É necessário infirmar corretamente o estado.')
    .required('O campo estado é obrigatório.'),
  municipio: yup
    .string()
    .typeError('É necessário infirmar corretamente a cidade.')
    .required('O campo municipio é obrigatório.'),
  endereco: yup.string().required('O campo endereço é obrigatório.'),
  cpf: yup.string().length(14, 'O campo cpf deve ter 11 dígitos.'),
  id_escola: yup
    .number()
    .typeError('O campo id escola deve ser numérico')
    .required('O campo id escola é obrigatório'),
})
