import axios from 'axios'

import { getLocalToken, getUserProfile } from '../services/localStorage'
import { getAPI } from '../utils/currentApi'

const API_URL: string = getAPI()

const api = axios.create({
  baseURL: API_URL,
})
export const listProfiles = async () => {
  const token = getLocalToken()
  const res = await api.get('govbr/custom/perfis-list/old/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res.data
}

export const getProfilesData = async () => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  const res = await api.get('govbr/perfil/?limit=10000&offset=0', {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile

    },
  })

  return res.data
}

export const editProfile = async (info: any) => {
  const token = getLocalToken()
  const res = await api.put(
    `govbr/perfil/${info.id}`,
    {
      acesso_escolas: info.acessoEscola,
      acesso_nacional: info.acessoNacional,
      descricao_perfil: info.descricao_perfil,
      nivel: info.nivel,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )

  return res.data
}

export const addProfile = async (info: any) => {
  const token = getLocalToken()
  const res = await api.post('govbr/perfil/', {
    nivel: +info.nivel,
    descricao_perfil: info.name,
    acesso_escolas: info.acesso_escolas,
    acesso_nacional: info.acesso_nacional,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res
}

export const deleteProfile = async (profileId: string) => {
  const token = getLocalToken()
  const res = await api.delete(`govbr/perfil/${profileId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res.status === 204
}

export const deleteResourceByProfileAndModuleId = async (profileId: string, resourceId: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.delete(`govbr/perfil/recurso-delete?id_perfil=${profileId}&id_recurso=${resourceId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.status === 204
}

export const linkUserToProfile = async (info: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  const cleanCPF = info.userId.replace(/[^\d]/g, '')

  const res = await api.post(
    `govbr/perfil/usuario/`,
    {
      id_perfil: +info.profileId,
      id_usuario: cleanCPF,
      id_escola: +info.schoolId,
      id_concessor: info.id,
      municipio: info.city,
      uf: info.state,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res.status === 200 || res.status === 201
}

export const deleteUserProfile = async (id: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.delete(`govbr/perfil/usuario/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.status === 204
}

export const getUsersProfiles = async () => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`govbr/perfil/usuario/`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const filterProfilesDataTabbed = async (
  search: string,
  limit: number,
  offset: number
) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const url = search !== '' ? `govbr/perfil/modulo/${search}` : `govbr/perfil`
  const res = await api.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
    params: {
      limit,
      offset,
    },
  })

  return res.data
}

export const addProfileResource = async (info: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.post(
    'govbr/perfil/recurso/', {
      id_perfil: info.profileId,
      id_recurso: info.resourceId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res.status === 200 || res.status === 201
}

export const getProfile = async (info : any) => {
  const token = getLocalToken()
  const res = await api.get(`govbr/perfil/${info.id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res.data
}
