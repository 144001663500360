import axios from 'axios'

import { getLocalToken, getUserProfile } from '../services/localStorage'
import { getAPI } from '../utils/currentApi'

const API_URL: string = getAPI()

const api = axios.create({
  baseURL: API_URL,
})

export const getSchoolsData = async (limit = 10, offset = 0, fields?: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  
  const res = await api.get('govbr/escola/', {
    params: {
      limit,
      offset,
      fields,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

interface getFilteredSchoolsDataProps{
  uf: string,
  municipio: string,
  modulo?: string,
  dependency?: string,
  fields?: string
}

export const getFilteredSchoolsData = async ({uf, municipio, modulo, dependency, fields} : getFilteredSchoolsDataProps) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get('govbr/escola/', {
    params: {
      uf,
      municipio,
      modulo,
      limit: 10000,
      dependencia_administrativa: dependency,
      fields,
    },
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getClassesData = async () => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  
  const res = await api.get('govbr/turma/', {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const deleteSchool = async (id: number) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  
  const res = await api.delete(`govbr/escola/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const editSchoolsData = async (queryParams: any, id: number) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  
  const res = await api.put(
    `govbr/escola/${id}`,
    { ...queryParams },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res.data
}

export const createSchool = async (queryParams: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  
  const res = await api.post(
    `govbr/escola/`,
    { ...queryParams },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res.data
}

export const filterSchools = async (queryParams: any, search: string) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  
  const res = await api.get(`govbr/escola/filter/${search}`, {
    params: { ...queryParams },
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const appendGestorToSchool = async (queryParams: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()
  
  const res = await api.post(
    'govbr/perfil/usuario/',
    { ...queryParams },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res.data
}

export const appendGestorToSystem = async (queryParams: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.post(
    'govbr/modulos-plataforma/escola/',
    { ...queryParams },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res.data
}

export const getTeacherClass = async (id: number) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`govbr/turma/professor?id_turma=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}