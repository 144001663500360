import { Grid, Button, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { IForm } from '../../types'
import * as yup from 'yup'
import { Formik } from 'formik'
import Input from '../../../../../components/FORMS/Input'
import Select from '../../../../../components/FORMS/Select'
import { filterUsersDataTabbed } from '../../../../../api/user'
import { removeSpecialCharacters } from '../../../../../utils/string'
import ClassesService from '../../../../../services/classes'

const MaintenanceClassForm: React.FC<IForm> = ({
  data,
  onConfirm,
  onCancel,
  confirmButtonLabel,
}) => {
  const optionsSelect = [...Array(9).keys()].map((y) => ({
    value: `${y + 1}º`,
    label: `${y + 1}º`,
  }))
  const [submit, setSubmit] = useState(false)
  const [teacherName, setTeacherName] = useState('Aguardando CPF...')
  const yupValidation = yup.object({
    year: yup.string().required('É necessário selecionar o ano da turma.'),
    name: yup.string().required('É necessário preencher o nome da turma.'),
    teacher: yup.string(),
  })

  const { getTeacher } = ClassesService()

  const teacherFilter = () => {
    setTimeout(() => {
      const teacherCPF : any = document.getElementById('class-form-2')
      const cleanTeacherCPF = removeSpecialCharacters(teacherCPF.value)

      if(cleanTeacherCPF.length === 11) {
        filterUsersDataTabbed(cleanTeacherCPF, 5, 0).then((res) => {
          if(res.results.length > 0) {
            setTeacherName(res.results[0].nome_usuario)
          } else {
            setTeacherName('CPF não encontrado ou inválido.')
          }
        })
      }
    }, 500)
  }

  useEffect(() => {
    const teacherCPF : any = document.getElementById('class-form-2')

    getTeacher(data.id).then((res) => {

      if(res.data.count > 0) {
        data.teacher = res.data.results[0].id_usuario
        teacherCPF.value = res.data.results[0].id_usuario
        teacherFilter()
      }
    })
  })

  return (
    <Formik
      initialValues={data}
      validationSchema={yupValidation}
      onSubmit={(values) => {
        onConfirm(values)
      }}
    >
      {({ handleSubmit, errors, values, touched }) => (
        <form
          style={{ width: '100%' }}
          onSubmit={(event) => {
            handleSubmit(event)
            setSubmit(true)
          }}
        >
          <Grid container columnSpacing={3} rowSpacing={4}>
            <Grid item xs={6}>
              <Select
                id='class-form-0'
                label='Ano'
                options={optionsSelect}
                name='year'
                helperText={!!touched.year || submit ? errors.year : undefined}
                isInvalid={submit && !!errors.year}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                id='class-form-1'
                name='name'
                label='Nome'
                helperText={!!touched.year || submit ? errors.name : undefined}
                isInvalid={submit && !!errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                id='class-form-2'
                name='teacher'
                label='CPF do professor responsável'
                placeholder='Digite aqui o CPF'
                mask='999.999.999-99'
                helperText={errors.teacher}
                isInvalid={submit && !!errors.teacher}
                onKeyDownCapture={() => teacherFilter()}
              />
            </Grid>
            {values.teacher && !errors.teacher && (
              <Grid item xs={12}>
                <Input
                  id='class-form-3'
                  name='teacherName'
                  label='Nome do professor'
                  value={teacherName}
                  disabled
                />
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '40px',
            }}
          >
            <Button
              id='class-form-4'
              variant='text'
              style={{
                color: 'black',
                textTransform: 'none',
                fontWeight: '700',
              }}
              disableElevation
              type='reset'
              onClick={onCancel}
            >
              Cancelar
            </Button>

            <Button
              id='class-form-5'
              disableElevation
              style={{
                backgroundColor: '#083CA6',
                textTransform: 'none',
                fontWeight: '700',
                color: 'white',
              }}
              type='submit'
            >
              {confirmButtonLabel}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default MaintenanceClassForm
