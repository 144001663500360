import axios from 'axios'

import { getLocalToken } from '../services/localStorage'
import { getAPI } from "../utils/currentApi"

const API_URL: string = getAPI()

const api = axios.create({
  baseURL: API_URL,
})

export const postContact = async (info: any) => {
  const token = getLocalToken()
  const res = await api.post('govbr/contato/', {
    nome: info.name,
    cpf: info.cpf,
    email: info.email,
    categoria: info.category,
    modulo: info.modulo,
    assunto: info.subject,
    mensagem: info.message,
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return res.data
}