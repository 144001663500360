import { Box } from '@mui/material'
import { Oval } from 'react-loader-spinner'

type Props = {
  loading: boolean
}

export const Loader = (props: Props) => {
  window.scrollTo(0, 0)
  document.body.style.overflow = props.loading ? 'hidden' : 'auto'

  return (
    <Box
      id='loader-component-0'
      sx={{
        zIndex: 9999,
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100vh',
        display: props.loading ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
      }}
    >
      <Box id='loader-component-1' sx={{}}>
        <Box id='loader-component-2'>
          <Oval
            ariaLabel='loading-indicator'
            height={50}
            width={50}
            strokeWidthSecondary={3}
            color='#154da6'
            secondaryColor='white'
          />
        </Box>
      </Box>
    </Box>
  )
}
