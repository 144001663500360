import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'

import { MainHeader } from '../../../components/Header'
import { Bread } from '../../../components/Bread/bread'
import TableManageSchools from './components/TableManageSchools'
import { Alert } from '../../../components/Alert'
import { Footer } from '../../../components/Footer'

import { getSchoolsData } from '../../../api/school'
import { getHasResource } from '../../../api/user'

export default function ManageSchool() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Gerenciar Turmas";
    getHasResource("79").then((res) => {
      return res ? navigate('/') : null
    })
  }, [navigate])

  const [data, setData] = useState([])
  const [dataCount, setDataCount] = useState(0)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    getSchoolsData()
      .then((data) => {
        setData(data.results)
        setDataCount(data.count)
      })
      .catch((error) => {
        Alert({
          type: 'error',
          message:
            'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
          autoClose: true,
        })
      })
  }, [reload])

  return (
    <>
      <MainHeader subtitles={['Gerenciamento de escolas']} />
      <Bread texto={'Gerenciamento de escolas'} />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '24px 0 32px 0',
          background: '#f2f5fd',
          '@media (min-width: 768px)': {
            width: '100%',
          },
        }}
      >
        <Box sx={{ marginTop: '21px' }}>
          <TableManageSchools
            profile={false}
            buttonAction={async () => null}
            data={data}
            dataCount={dataCount}
            setReload={setReload}
            reload={reload}
          />
        </Box>
      </Box>
      <Footer />
    </>
  )
}
