export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const handleFirstName = (value: string) => {
  const firstName: string[] = value ? value.split(' ') : []
  return value ? firstName[0] : ''
}

export const removeSpecialCharacters = (value: string) => {
  // eslint-disable-next-line no-useless-escape
  const string = value.replace(/[&\/\\#,+()$~%.'":*?<>{}_.-]/g, '')
  return string
}

export const maskCPF = (cpf: string) => {
  
  function testMask(value: string) {
    var Soma;
    var Resto;
    Soma = 0;
  
    if (value === "00000000000") return false;
  
    for (let i=1; i<=9; i++) {
      Soma = Soma + parseInt(value.substring(i-1, i)) * (11 - i)
   }
    
    Resto = (Soma * 10) % 11;
  
    if ((Resto === 10) || (Resto === 11)) { 
      Resto = 0
    };
    if (Resto !== parseInt(value.substring(9, 10))) return false;
  
    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(value.substring(i-1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
  
    if ((Resto === 10) || (Resto === 11))  Resto = 0;
    if (Resto !== parseInt(value.substring(10, 11) ) ) return false;
    
    return true;

  }
  if(cpf.length === 11 ) {
    return (testMask(cpf))
  }
}

export const removeMaskCPF = (cpf: string) => {
  return cpf.replace('.', '').replace('.', '').replace('-', '')
}

export const validEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}