import React from 'react'
import { Box } from '@mui/material'
import { Oval } from 'react-loader-spinner'

type Props = {
  loading: boolean
  children?: any
}

export const Overload = (props: Props) => {
  return (
    <Box
      id='overload-component-0'
      sx={{
        position: 'relative',
      }}
    >
      {props.children}

      <Box
        id='overload-component-1'
        sx={{
          display: props.loading ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255, 0.8)',
        }}
      >
        <Box id='overload-component-2'>
          <Oval
            ariaLabel='loading-indicator'
            height={50}
            width={50}
            strokeWidthSecondary={3}
            color='#154da6'
            secondaryColor='white'
          />
        </Box>
      </Box>
    </Box>
  )
}
