import axios from 'axios'

import { getLocalToken, getUserProfile } from '../services/localStorage'
import { getAPI } from '../utils/currentApi'

const API_URL: string = getAPI()

const api = axios.create({
  baseURL: API_URL,
})

export const getSystemsData = async (add?: boolean) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(`govbr/modulos-plataforma/?limit=10000&offset=0&add=${add ? add : false}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getSystemsResources = async () => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get('govbr/modulos-plataforma/recurso/', {
    headers: {
      Authorization: `Bearer ${token}`,
      'id-profile': userProfile
    },
  })

  return res.data
}

export const getRecursos = async (id_modulo: any) => {
  const token = getLocalToken()
  const userProfile = getUserProfile()

  const res = await api.get(
    `govbr/modulos-plataforma/recurso/filter/${id_modulo}`,
    {
      params: {
        limit: 100,
        offset: 0,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'id-profile': userProfile
      },
    }
  )

  return res.data
}
