import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Divider, Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles'

import { MainHeader } from '../components/Header'
import { Bread } from '../components/Bread/bread'
import { Alert } from '../components/Alert'
import BasicModal from '../components/ModalManagement/BasicModal'

import { deleteUser } from '../api/user'

import pen from '../assets/icons/pen.svg'
import { Footer } from '../components/Footer'
import { listProfiles } from '../api/profile'
import { getLocalUserData, getUserProfile } from '../services/localStorage'
import { EditContact } from '../components/Profile'

export function Profile() {
  const navigate = useNavigate()

  const [cpf, setCPF] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [organ, setOrgan] = useState([''])
  const [phone, setPhone] = useState('')
  const [userFunction, setUserFunction] = useState('')
  const [functionLevel, setFunctionLevel] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [confirmButton, setConfirmButton] = useState(true)
  const [isChecked, setIsChecked] = useState(false)

  const [isEditContactOn, setIsEditContactOn] = useState(false)
  const [user, setUser] = useState<any>({})


  useEffect(() => {
    document.title = 'Gerenciar perfil'
    async function fetchApi() {
      const userData = getLocalUserData()

      listProfiles().then((profiles) => {
        profiles.results.forEach((profile: any) => {
          profile.ids.forEach((id: number) => {
            if (id === +getUserProfile()) {
              setUserFunction(profile.perfil)
              setFunctionLevel(profile.nivel)
              return
            }
          })
        })
      })

      setUser(userData)
    }

    fetchApi().catch((err) => {
      Alert({
        type: 'error',
        message:
          'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
        autoClose: true,
      })
    })
  }, [])

  useEffect(() => {
    setName(user.nome_usuario)
    setEmail(user.email)
    setOrgan(user.modulos)
    setPhone(user.celular)
    setCPF(user.cpf)
    setIsActive(user.active)
  }, [user])

  const handleEditContact = () => {
    setIsEditContactOn(!isEditContactOn)

  }
  const handleModalDelete = () => {
    setOpenModalDelete(!openModalDelete)
  }
  const handleModalEdit = () => {
    setIsEditContactOn(!isEditContactOn)
  }
  const handleDisableButton = () => {
    setIsChecked(!isChecked)
    setConfirmButton(!confirmButton)
  }
  const handleSignOut = () => {
    navigate('/logout')
  }
  const handleDeleteUser = () => {
    deleteUser(cpf).then(() => {
      Alert({
        type: 'success',
        message: 'Dados pessoais excluídos com sucesso!',
        autoClose: true,
      })
    }).catch((err) => {
      Alert({
        type: 'error',
        message: 'Ops! Ocorreu um erro ao deletar o usuário. Tente novamente mais tarde.',
        autoClose: true,
      })
    })
    handleSignOut()
  }

  const DeleteButton = styled(Button)({
    fontWeight: 'bold',
    fontSize: '0.8rem',
    textTransform: 'none',
  })
  const ConfirmDeleteButton = styled(Button)({
    fontWeight: 'bold',
    fontSize: '0.8rem',
    textTransform: 'none',
    backgroundColor: '#920000',
  })
  return (
    <div id="profile-container" className='h-screen lg:h-fit w-screen bg-[#F7F8FA] flex mediumScreen:h-screen mediumScreen:block'>
      <EditContact hidden={isEditContactOn} close={handleModalEdit} userCPF={cpf} userEmail={email} userPhone={phone} setEmail={setEmail} setPhone={setPhone}/>
      <div id="profile-1" className='h-fit mediumScreen:h-auto w-screen mb-10'>
        <MainHeader subtitles={['Gerenciar perfil']} />
        <Bread texto={'Gerenciamento de perfil'}/>
        <div className="flex items-center justify-center">
          <div id="profile-2" className='flex flex-col bg-white mt-8 p-6 w-full md:w-10/12 2xl:w-9/12'>
            <div id="title" >
              <h1 id="profile-title" className='text-2xl font-semibold'>
                Gerenciar Perfil
              </h1>
              <p id="profile-description" className='text-md mt-2 '>
                Visualize as informações de cadastro e gerencie as informações de
                contato relacionadas à sua conta.
              </p>
            </div>
            
            <div id="profile-info" className='flex flex-row items-center justify-between'>
              <div id="profile-info-section" className='mt-8 order-2 md:-order-none flex flex-col'>
                <div className="title">
                  <h2 id="profile-info-title" className='text-xl font-bold pb-6'>
                    Informações de Cadastro
                  </h2>
                </div>
                <Divider />
                <p id="profile-info-name" className='flex flex-col'>
                  <label id="profile-info-name-label" className='mt-6 text-profile font-semibold'>Nome</label>
                  <span id="profile-info-name-span" className='mt-2 text-profile'>{name}</span>
                </p>
                <div className='flex flex-row mt-12'>
                  <p id="profile-info-cpf" className='flex flex-col'>
                    <label id="profile-info-cpf-label" className='mt-6 text-profile font-semibold'>CPF</label>
                    <span id="profile-info-cpf-span" className='mt-2 text-profile'>{cpf}</span>
                  </p>
                  <p id="profile-info-organization" className='flex flex-col ml-12'>
                    <label id="profile-info-organization-label" className='mt-6 text-profile font-semibold'>
                      Órgão
                    </label>
                    <span id="profile-info-organization-span " className='mt-2 text-profile '>
                      {
                        organ?.join(', ')
                      }
                    </span>
                  </p>
                </div>
              </div>
              <div id="profile-aside-container" className='bg-[#F7F8FA] flex flex-col p-8 ml-2 gap-8'>
                <div id="profile-aside-status" className='flex flex-col items-start'>
                  <label id="profile-aside-status-title" className='text-profile font-semibold'>
                    Status de usuário
                  </label>
                  <div className='flex flex-row bg-[#F4FDF4] mt-2 items-center justify-center py-1 px-3'>
                    <span id="profile-aside-status-image" className='w-2 h-2 text-profile bg-[#2B9127] rounded-full'></span>
                    <span id="profile-aside-status-text" className='text-profile text-green-600 ml-2'>
                      {isActive ? "Ativo" : "Inativo"}
                    </span>
                  </div>
                </div>
                <div id="profile-aside-function" className='flex flex-row gap-10'>
                  <p className='flex flex-col items-start'>
                    <label id="profile-aside-function-title" className='text-profile font-semibold'>
                      Função de usuário
                    </label>
                    <span id="profile-aside-function-text" className='mt-2 text-profile'>
                      {userFunction}
                    </span>
                  </p>
                  <p id="profile-aside-nivel" className='flex flex-col items-start'>
                    <label id="profile-aside-nivel-title" className='text-profile font-semibold'>
                      Nível de usuário
                    </label>
                    <span id="profile-aside-nivel-text" className='mt-2 text-profile'>
                      {functionLevel}
                    </span>
                  </p>
                </div>
                <div id="profile-aside-config" className='flex flex-row gap-10'>
                  <p className='w-full flex flex-col items-start'>
                    <label id="profile-aside-function-title" className='text-profile font-semibold mb-4'>
                      Configurações de acesso
                    </label>
                    <DeleteButton onClick={handleModalDelete} startIcon={<DeleteIcon />} className='w-full' variant="outlined" color="error">Excluir meus dados pessoais</DeleteButton>
                    {openModalDelete && 
                    <BasicModal title='Excluir dados pessoais' open={true} close={setOpenModalDelete}>
                        <Box
                          sx={{
                            width: '100%',
                            backgroundColor: '#FEF2F2',
                            padding: '1rem',
                            borderRadius: '0.5rem',
                          }}
                        >
                          <p className='text-[#A50000] text-semi-bold mb-2'>Atenção</p>
                          <p>Os dados serãos excluídos permanentemente e a sua conta não existirá mais. As ações não poderão ser desfeitas.</p>
                        </Box>
                        <FormGroup >
                          <FormControlLabel sx={{ fontSize: '0.5rem', padding: '1rem 0'}} control={<Checkbox checked={isChecked} onClick={handleDisableButton} size='small' />} label="Estou ciente e desejo excluir permanentemente meus dados associados a esta conta." />
                        </FormGroup>
                        <div id="bottom-buttons" className="flex justify-between">
                          <DeleteButton color="inherit" onClick={handleModalDelete}>Voltar</DeleteButton>
                          <ConfirmDeleteButton disabled={confirmButton} variant="contained" color="error" onClick={handleDeleteUser}>Excluir meus dados</ConfirmDeleteButton>
                        </div>
                    </BasicModal>
                    }
                  </p>
                </div>
              </div>
            </div>
            <div id="profile-contact-info-container" className='h-full mt-10'>
              <div className='flex flex-row items-center'>
                <h2 id="profile-contact-info-title" className='text-xl1 font-bold pb-3'>
                  Informações de contato
                </h2>

                <div
                  id="profile-edit-contact-info-container"
                  onClick={handleEditContact}
                  className='flex flex-row px-6 mt-4 mb-6 ml-8 py-3 bg-white border-x border-y border-solid border-[#DBDDE5] cursor-pointer'
                >
                  <img id="profile-edit-contact-info-image" src={pen} alt='Imagem de uma caneta' />
                  <button id="profile-edit-contact-info-button" className='ml-3 rounded text-[#083CA6] text-sm font-bold'>
                    Editar informações
                  </button>
                </div>
              </div>
              <Divider />
              
              <div id="profile-contact-info-text" className='flex flex-row mt-3 mb-3 lg:mt-12 lg:mb-12'>
                <p id="profile-edit-contact-info-phone" className='flex flex-col mr-10'>
                  <label id="profile-edit-contact-info-phone-title" className='text-profile font-semibold'>
                    Celular
                  </label>
                  <span id="profile-edit-contact-info-phone-text" className='mt-2 text-profile'>
                    { phone?.replace(/\D/g, '').replace(/(\d{1,2})(\d{4,5})(\d{4})/, '($1) $2-$3') }
                  </span>
                </p>
                <p id="profile-edit-contact-info-email" className='flex flex-col '>
                  <label id="profile-edit-contact-info-email-title" className='text-profile font-semibold'>
                    E-mail
                  </label>
                  <span id="profile-edit-contact-info-email-text" className='mt-2 text-profile'>{email}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
