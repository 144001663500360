import React, { Fragment, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'

import { Box, Button, Typography } from '@mui/material'
import closeIcon from '../../../../assets/icons/blackCloseIcon.svg'
import negativeIcon from '../../../../assets/icons/negative.svg'
import plusIcon from '../../../../assets/icons/plus.svg'
import { Alert } from '../../../../components/Alert'

import { Dialog, Transition } from '@headlessui/react'

import {
  addStudent,
  getPermitedProfiles,
} from '../../../../api/user'
import { deleteUserProfile } from '../../../../api/profile'

import { LinkUser } from './LinkUser'

export function EditUser(
  userId: string,
  user: string,
  email: string,
  profiles: any
) {
  return new Promise((resolve, reject) => {
    addDialog(userId, user, email, profiles, resolve)
  })
}

function removeDialog(root: any) {
  root.unmount()

  const div = document.getElementById('modal-edit-user')
  if (div) {
    div.remove()
  }
}

function addDialog(
  userId: string,
  user: string,
  email: string,
  profiles: any,
  resolve: any
) {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')

  div.setAttribute('id', 'modal-edit-user')
  body.appendChild(div)

  const root = createRoot(div)

  root.render(
    <CreateDialog
      root={root}
      userId={userId}
      user={user}
      email={email}
      resolve={resolve}
      profiles={profiles}
    />
  )
}

function CreateDialog(dialogObj: {
  root: any
  userId: string
  user: string
  email: string
  resolve: any
  profiles: any
}) {
  const [testData, setTestData] = useState<any>([])

  const email = dialogObj.email
  const [tab, setTab] = useState(0)
  const [tabs] = useState([
    {
      id: 0,
      label: 'Informações gerais',
      tabMargin: 0,
      tabLength: 170,
    },
    {
      id: 1,
      label: 'Perfis de usuário',
      tabMargin: 32,
      tabLength: 150,
    },
  ])

  const handleClose = () => {
    removeDialog(dialogObj.root)

    dialogObj.resolve()
  }

  const handleEdit = async () => {
    removeDialog(dialogObj.root)
  }

  const handleDelete = (
    profile: any,
    profileIndex: React.Key,
    schoolIndex: React.Key
  ) => {
    deleteUserProfile(profile).then((result) => {
      if (result) {
        let newData = [...testData]
        newData[0].remover[profileIndex].escolas.splice(schoolIndex, 1)
        newData[0].remover[profileIndex].id_perfil_usuario.splice(
          schoolIndex,
          1
        )
        setTestData(newData)
      }
    }).finally(() => {
      Alert({
        type: 'success',
        message: 'Perfil de usuario removido!',
        autoClose: true,
      })
    })
  }

  const handleLink = async () => {
    handleClose()
    const res: any = await LinkUser(dialogObj.user, dialogObj.userId)

    if (res?.changed) {
      addStudent(res.data)
    }
  }

  const handleTabChange = (newValue: number) => {
    setTab(newValue)
  }

  const getWidth = (tab: number) => {
    return tabs.find((item: any) => item.id === tab)?.tabLength
  }

  const calculateMargin = (tab: number) => {
    if (tab === 0) {
      return 0
    } else {
      const index = tabs.findIndex((item: any) => item.id === tab)

      return tabs
        .slice(0, index)
        .reduce((accumulator: number, currentValue: any) => {
          return (
            accumulator + currentValue.tabLength + currentValue.tabMargin + 32
          )
        }, 0)
    }
  }

  useEffect(() => {
    getPermitedProfiles(dialogObj.userId).then((data: any) => {
      setTestData(data)
    })
  }, [dialogObj.userId])

  return (
    <>
      <Transition.Root show as='div' className='w-screen'>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-[#14161F]  bg-opacity-40 transition-opacity' />
        </Transition.Child>

        <Dialog
          as='div'
          className='w-full z-10 relative'
          onClose={() => {return false}}
          id='basic'
        >
          <div className='fixed z-10 inset-0 overflow-y-auto'>
            <div className='flex items-end sm:items-center justify-center min-h-full text-center sm:p-0'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                leave='ease-in duration-200'
              >
                <Dialog.Panel className='relative w-full bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-screen-sm sm:w-full'>
                  <div className='bg-white'>
                    <div className='sm:flex sm:items-start'>
                      <div className='text-center sm:text-left w-full'>
                        <div className='flex flex-row justify-between mx-8 mt-8'>
                          <Dialog.Title as='h3' className='text-lg font-medium'>
                            Editar usuário
                          </Dialog.Title>
                          <img
                            id='close_profile'
                            className='cursor-pointer'
                            src={closeIcon}
                            alt='Imagem de um x'
                            onClick={handleClose}
                          />
                        </div>
                        <div className='mt-12 ml-8 flex flex-col content-start items-start'>
                          <Typography color='#5E6475' fontSize='0.875rem'>Usuário</Typography>
                          <Typography fontSize='0.875rem' textTransform='capitalize' mt={1}>{dialogObj.user}</Typography>
                        </div>
                        <div className='flex ml-8 mt-6 border-t border-[#EDEEF2] mr-8'>
                          {tabs.map((currentTab, index) => (
                            <Button
                              variant='text'
                              style={{
                                color:
                                  tab === currentTab.id ? '#083CA6' : '#404554',
                                fontSize: '1rem',
                                fontWeight: 600,
                                textTransform: 'none',
                                padding: '0px',
                                marginLeft: `${currentTab.tabMargin}px`,
                                width: `${currentTab.tabLength}px`,
                              }}
                              onClick={() => handleTabChange(currentTab.id)}
                              key={index}
                            >
                              {currentTab.label}
                            </Button>
                          ))}
                        </div>
                        <Box
                          sx={{
                            height: '3px',
                            maxWidth: '90%',
                            borderRadius: '6px',
                            background: '#B6C4EE',
                            marginLeft: '32px',
                          }}
                        >
                          <Box
                            sx={{
                              height: '3px',
                              width: `${getWidth(tab)}px`,
                              borderRadius: '6px',
                              marginLeft: `${calculateMargin(tab)}px`,
                              transition: 'all 0.3s ease-in-out',
                              background: '#083CA6',
                            }}
                          ></Box>
                        </Box>
                      </div>
                    </div>
                  </div>
                  {tab === 0 && (
                    <>
                      <div className='flex flex-col justify-start bg-white rounded-md'>
                        <div className='flex flex-col mt-6 ml-8 mr-10'>
                          <Typography color='#5E6475' fontSize='0.875rem'>E-mail</Typography>
                          <Typography fontSize='0.875rem' mt={1}>{email}</Typography>
                          
                        </div>
                      </div>
                    </>
                  )}
                  {tab === 1 ? (
                    <div className='flex flex-col'>
                      {testData.length === 1
                        ? testData[0].remover.map(
                            (profile: any, index: React.Key) => {
                              return profile.escolas.map(
                                (school: any, idx: React.Key) => {
                                  return (
                                    <div
                                      className='flex flex-col justify-start bg-white rounded-md'
                                      key={idx}
                                    >
                                      <div className='ml-8 mt-6 flex flex-row items-end'>
                                        <div className='flex flex-col w-1/3'>
                                          <span className='text-sm text-[#BEC0CC]'>
                                            Sistema
                                          </span>
                                          <select
                                            id='classes'
                                            className='mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded appearance-none first-of-type:text-[#9EA1B0] first-of-type:text-[#9EA1B0]'
                                            defaultValue='DEFAULT'
                                            disabled
                                          >
                                            <option
                                              value='DEFAULT'
                                              className='text-sm'
                                            >
                                              {profile.modulos.join(' ')}
                                            </option>
                                          </select>
                                        </div>

                                        <div className='flex flex-col w-1/3 ml-6'>
                                          <span className='text-sm text-[#BEC0CC]'>
                                            Função de usuário
                                          </span>
                                          <select
                                            id='profile'
                                            className='mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded appearance-none first-of-type:text-[#9EA1B0]'
                                            defaultValue={profile.id_perfil}
                                            disabled
                                          >
                                            <option
                                              value={profile.id_perfil}
                                              className='text-sm'
                                            >
                                              {profile.perfil.descricao_perfil}
                                            </option>
                                          </select>
                                        </div>

                                        <button
                                          className='flex flex-row py-2.5 px-4 items-center absolute right-8'
                                          onClick={() =>
                                            handleDelete(
                                              profile.id_perfil_usuario[idx],
                                              index,
                                              idx
                                            )
                                          }
                                        >
                                          <img
                                            src={negativeIcon}
                                            alt='Imagem de um menos vermelho'
                                          />
                                          <span className='ml-2.5 font-bold text-[#920000] text-sm'>
                                            Excluir
                                          </span>
                                        </button>
                                      </div>
                                      {school.uf ? (
                                        <div className='flex flex-col ml-8 mr-8'>
                                          <div className='flex flex-row'>
                                            <div className='flex flex-col grow'>
                                              <span className='text-sm text-[#BEC0CC]'>
                                                Estado
                                              </span>
                                              <select
                                                id='profile'
                                                className='mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded appearance-none first-of-type:text-[#9EA1B0]'
                                                defaultValue={'state'}
                                                disabled
                                              >
                                                <option
                                                  value={'state'}
                                                  className='text-sm'
                                                >
                                                  {school.uf}
                                                </option>
                                              </select>
                                            </div>

                                            {school.municipio ? (
                                              <div className='flex flex-col ml-6 grow'>
                                                <span className='text-sm text-[#BEC0CC]'>
                                                  Município
                                                </span>
                                                <select
                                                  id='profile'
                                                  className='mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded appearance-none first-of-type:text-[#9EA1B0]'
                                                  defaultValue={'city'}
                                                  disabled
                                                >
                                                  <option
                                                    value={'city'}
                                                    className='text-sm'
                                                  >
                                                    {school.municipio}
                                                  </option>
                                                </select>
                                              </div>
                                            ) : null}
                                          </div>                                          
                                          {school.nome_escola ? (
                                          <div className='flex flex-col grow'>
                                            <span className='text-sm text-[#BEC0CC]'>
                                              Escola
                                            </span>
                                            <select
                                              id='school'
                                              className='mt-2 px-4 py-2 text-user bg-[#F7F8FA] rounded appearance-none first-of-type:text-[#9EA1B0]'
                                              defaultValue={school.id_escola}
                                              disabled
                                            >
                                              <option
                                                value={school.id_escola}
                                                className='text-sm'
                                              >
                                                {school.nome_escola}
                                              </option>
                                            </select>
                                          </div>) : null}
                                        </div>
                                      ) : null}
                                    </div>
                                  )
                                }
                              )
                            }
                          )
                        : null}
                      <div
                        onClick={handleLink}
                        className='mt-6 py-3 mx-8 border border-dashed border-[#7995DB] flex flex-row justify-center cursor-pointer'
                      >
                        <img src={plusIcon} alt='Imagem de um mais' />
                        <p className='text-[#083CA6] font-bold text-sm ml-3'>
                          Adicionar sistema
                        </p>
                      </div>
                    </div>
                  ) : null}
                  <div className='bg-white mt-11 flex justify-between gap-4'>
                    <button
                      type='button'
                      className='w-auto inline-flex justify-center rounded px-4 py-2 bg-white text-sm font-bold mb-7 ml-8 border'
                      onClick={handleClose}
                    >
                      Descartar alterações
                    </button>
                    <button
                      type='button'
                      className='w-auto inline-flex justify-center rounded px-4 py-2 bg-[#083CA6] text-sm font-bold text-white mb-7 mr-8'
                      onClick={() => handleEdit()}
                    >
                      Salvar alterações
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
