import { IDataClass } from "../../../../interfaces/classes";
import { IClasses } from "../types";

export const serializeClass = {
  toBackendFormat: (data: IClasses) => {
    if (!Object.keys(data).length) return {} as IDataClass;
    return {
      nome_turma: data.name,
      ano_turma: data.year,
      etapa_ensino: "",
      modo_ensino: "",
      id_escola: data.schollId,
      id_turma: data.id,
    } as IDataClass;
  },
  toFrontendFormat: (data: IDataClass) => {
    if (!Object.keys(data).length) return {} as IClasses;
    return {
      year: data.ano_turma,
      name: data.nome_turma,
      teacher: "",
      id: data.id_turma,
      date: "",
      schollId: data.id_escola,
    } as IClasses;
  },
};
