import { createRoot } from 'react-dom/client'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import CloseIcon from '@mui/icons-material/Close'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

interface AlertProps {
  type: 'success' | 'error'
  message: string
  autoClose?: boolean
  timeout?: number
}

export function Alert(props: AlertProps) {
  const body = document.getElementsByTagName('body')[0]
  const div = document.createElement('div')

  Object.assign(div.style, {
    position: 'absolute',
    top: 0,
    right: '50%',
    transform: 'translate(50%, 0)',
    marginTop: '32px',
    zIndex: 9999,
  })

  body.appendChild(div)

  const root = createRoot(div)

  root.render(
    <CreateAlert
      type={props.type}
      message={props.message}
      autoClose={props.autoClose || false}
      timeout={props.timeout || 5000}
      root={root}
      div={div}
    />
  )
}

function removeAlert(root: any, div: any) {
  root.unmount()
  div.remove()
}

function CreateAlert(props: AlertProps & { root: any; div: any }) {
  window.scrollTo(0, 0)
  
  const handleDelete = () => {
    removeAlert(props.root, props.div)
  }

  if (props.autoClose) {
    setTimeout(() => {
      removeAlert(props.root, props.div)
    }, props.timeout)
  }

  return (
    <Box
      id='alert-component-0'
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px 10px',
        maxWidth: '504px',
        backgroundColor: `${props.type === 'success' ? '#F4FDF4' : '#FDF4F4'}`,
        border: `1px solid ${props.type === 'success' ? '#B2EDB0' : '#D32F2F'}`,
        borderRadius: '4px',
      }}
    >
      {props.type === 'success' ? (
        <CheckCircleOutlineIcon
          id='alert-component-1'
          fontSize='large'
          color='success'
          sx={{
            margin: '8px',
          }}
        />
      ) : (
        <ErrorOutlineIcon
          id='alert-component-2'
          fontSize='large'
          color='error'
          sx={{
            margin: '8px',
          }}
        />
      )}
      <span
        id='alert-component-3'
        style={{
          color: props.type === 'success' ? '#196416' : '#D32F2F',
        }}
      >
        {props.message}
      </span>
      <IconButton
        id='alert-component-4'
        aria-label='close'
        onClick={handleDelete}
      >
        <CloseIcon id='alert-component-5' color={props.type} />
      </IconButton>
    </Box>
  )
}
