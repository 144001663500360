import tw from 'tailwind-styled-components'
import { FormControlStyle } from '../types'

export const SelectTw = tw.select<FormControlStyle>`
  relative
  appearance-none
  select 
  form-select 
  form-select-sm
  rounded 
  w-full 
  h-10
  py-2 
  px-3
  ${(props: FormControlStyle) => (props.isInvalid ? " bg-negative-50": " bg-primitive-50")} 
  placeholder-primitive-500
  text-primitive-700
  leading-tight 
  focus:outline-none 
  focus:shadow-outline
  hover:${(props: FormControlStyle) => (props.isInvalid ? " bg-negative-50": " bg-primitive-50")}
  hover:opacity-100
  placeholder:not-italic
  mt-2
  `
export const SelectArrow = tw.svg<FormControlStyle>`
  
`
