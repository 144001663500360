import axios from 'axios'

import { getLocalToken, setLocalToken } from '../services/localStorage'
import { getAPI } from '../utils/currentApi'

const API_URL: string = getAPI()

const api = axios.create({
  baseURL: API_URL,
})

export const refreshToken = async () => {
  const token = getLocalToken()
  return await api
    .post('govbr/user/token/refresh/', {
      refresh: token,
    })
    .then((res) => {
      const success = res.status === 200

      if (success) {
        setLocalToken(res.data.access)
      }

      return success
    })
    .catch(() => {
      return false
    })
}
