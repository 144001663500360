import { styled } from "@mui/material";
import { Button, ToggleButton as MuiToggleButton, Typography} from "@mui/material";

export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '45%',
  height: 'calc(95% - 5px)',
  boxShadow: '0px 0px 24px rgba(106, 115, 151, 0.06)',
  overflowY: 'auto',
  borderRadius: '6px',
};

export const boxStyle = {
  backgroundColor: '#FFFFFF',
  p: '32px',
  fontFamily: 'Inter',
};

export const ModalTitle = styled(Typography)({
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '150%',
  fontFamily: 'Inter',
})

export const TextLabel = styled(Typography)({
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '21px',
  color: '#000000',
  fontFamily: 'Inter',
})

export const ReproveButton = styled(Button)({
  marginTop: '16px',
  fontSize: '14px',
  fontWeight: '700',
  fontFamily: 'Inter',
  textTransform: 'capitalize',
  color: '#A50000',
  border: '1px solid #DBDDE5',
  "&:hover": {
    backgroundColor: '#A50000',
    color: '#FFF'
  }
})

export const SaveButton = styled(Button)({
  marginTop: '16px',
  fontSize: '14px',
  fontWeight: '700',
  fontFamily: 'Inter',
  textTransform: 'capitalize',
  color: '#FFFFFF',
  backgroundColor: '#013090',
  borderRadius: '4px',
  padding: '8px 16px',
  "&:hover": {
    backgroundColor: '#013090',
  }
})

export const ApproveButton = styled(Button)({
  marginTop: '16px',
  fontSize: '14px',
  fontWeight: '700',
  fontFamily: 'Inter',
  textTransform: 'capitalize',
  color: '#FFFFFF',
  backgroundColor: '#2B9127',
  borderRadius: '4px',
  padding: '8px 16px',
  "&:hover": {
    backgroundColor: '#2B9127',
  }
})

export const BackButton = styled(Button)({
  color: '#404554',
  marginTop: '16px',
  fontSize: '14px',
  fontWeight: '700',
  fontFamily: 'Inter',
  textTransform: 'capitalize',
})

export const EditButton = styled(Button)({
  mt: '8px', 
  fontSize: '14px', 
  fontWeight: '700', 
  fontFamily: 'Inter', 
  lineHeight: '100%',
  textTransform: 'capitalize', 
  color: '#083CA6', 
  border: '1px solid #DBDDE5', 
  display: 'flex',
  gap: '8px',
  padding: '8px 16px',
})

export const ToggleButton = styled(MuiToggleButton)((props) => ({
  color: '#083CA6',
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#083CA6"
  }
}))
