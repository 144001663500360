import MainRoutes from './routes/index'

function App() {
  return (
    <div className='App'>
      <MainRoutes />
    </div>
  )
}

export default App
