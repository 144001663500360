import { styled } from '@mui/material/styles'
import { InputLabel, TextField, Button } from "@mui/material";

export const Label = styled(InputLabel)({
  color: '#5E6475',
  fontSize: '0.875rem',
  marginBottom: '0.5rem',

})

export const Input = styled(TextField)({
  width: '100%',
  backgroundColor: '#F7F8FA',
  marginBottom: '0.875rem',
})

export const ButtonStyled = styled(Button)({
  textTransform: 'none',
  backgroundColor: '#083CA6',
  color: 'white',
  fontWeight: 'bold',
})

export const CancelButton = styled(Button)({
  textTransform: 'none',
  color: '#404554',
  fontWeight: 'bold',

})