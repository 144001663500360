import { styled } from '@mui/material/styles';
import Pagination, { PaginationProps } from '@mui/material/Pagination';

export const CustomPagination = styled((props: PaginationProps) => (
  <Pagination {...props} />
))(({ }) => ({
  '& .MuiPaginationItem-page': {
    '&:hover': {
      backgroundColor: '#083CA6',
      color: 'white'
    },
    '&.Mui-selected': {
      backgroundColor: '#083CA6',
      color: 'white'
    },
  }
}));