import { useNavigate } from 'react-router-dom'

import { ArrowForward } from '@mui/icons-material'

interface HomeCardProps {
  headerIcon: any
  title: string
  text: string
  path: any
  params?: any
  external?: boolean
  id?: any
}

export default function HomeCard(props: HomeCardProps) {
  const navigate = useNavigate()
  const handleGoTo = (path: any, external = false, params: any = {}) => {
    if (!external) {
      navigate(path, params)
      return
    }

    window.location.href = path
  }

  return (
    <div
      id={props.id}
      className='w-[100%] max-w-[384px] rounded-[0.5rem] overflow-hidden md:w-[320px] lg:w-[320px] xl:w-[370px] flex flex-col  bg-white'
      style={{
        boxShadow: '5px 10px 15px rgba(0, 0, 10, 0.1)',
      }}
    >
      <div id='cardhome-component-1' className='w-full bg-[#083CA6] p-3 md:p-6'>
        <div
          id='cardhome-component-2'
          className='flex justify-center rounded-full bg-white w-[58px] h-[58px]'
        >
          <div
            id='cardhome-component-3'
            className=' flex items-center text-[#083CA6]'
          >
            {props.headerIcon}
          </div>
        </div>
      </div>
      <div id='cardhome-component-4' className='px-6 py-4'>
        <div className='text-2xl font-medium lg:text-card mb-2'>
          {props.title}
        </div>
        <p
          id='cardhome-component-5'
          className='text-gray-700 text-sm lg:text-profile pb-5'
        >
          {props.text}
        </p>
      </div>
      <div id='cardhome-component-6' className='grow'></div>
      <div
        id='cardhome-component-7'
        className='px-6 pt-4 mb-6 flex justify-center'
      >
        <button
          id={props.id+"-button"}
          type='button'
          onClick={() => handleGoTo(props.path, props.external, props.params)}
          className='flex justify-center items-center border-solid border-2 border-gray-200 rounded-lg w-full py-2'
        >
          <ArrowForward id='cardhome-component-9' />
          <div
            id='cardhome-component-10'
            className='ml-2 text-[#083CA6] font-semibold'
          >
            Ver mais
          </div>
        </button>
      </div>
    </div>
  )
}
