import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom"

interface FooterLinkProps {
  title: string;
  path: string;
  external?: boolean;
}

export function FooterLink({ title, path, external }: FooterLinkProps) {
  const navigate = useNavigate()
  
  return (
    <Typography
      is="p"
      component="p"
      onClick={() => !external ? navigate(path) : window.location.href = path}
    >
      {title}
    </Typography>
  )
}