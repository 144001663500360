import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'

const style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '16px',
  position: 'absolute',
  background: '#FFFFFF',
  boxShadow: '0px 0px 24px rgba(106, 115, 151, 0.06)',
  borderRadius: '6px',
}

type Props = {
  children: JSX.Element | JSX.Element[] | string | string[]
  title: string
  open: boolean
  close: (value: boolean) => void
}

export default function BasicModal(props: Props) {
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    props.close(false)
    setOpen(false)
  }

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  return (
    <div>
      <Modal
        id='modal-component-0'
        open={open}
        onClose={() => {return false}}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        className='flex justify-center items-center'
      >
        <Box
          id='modal-component-1'
          sx={style}
          className='w-[320px] sm:w-[450px] md:w-[664px] max-h-[500px]'
        >
          <Box
            id='modal-component-2'
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '1rem',
              width: '100%',
              height: '62px',
              background: '#FFFFFF',
              flex: 'none',
              order: 0,
              alignSelf: 'stretch',
              flexGrow: 0,
            }}
            className='justify-between	'
          >
            <div
              id='modal-component-3'
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '20px',
                lineHeight: '150%',
              }}
            >
              {props.title}
            </div>

            <CloseIcon
              id='modal-component-4'
              onClick={() => handleClose()}
              sx={{
                width: '24px',
                height: '24px',
                color: 'black',
                padding: '0px',
                margin: '0px',
                cursor: 'pointer',
              }}
            />
          </Box>

          <Box id='modal-component-5' className='w-[100%] overflow-y-scroll' sx={{
              padding: '1rem',
          }}>
            {props.children}
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
