import { useEffect, useState } from 'react'

import { Alert } from '../Alert'
import BasicModal from '../ModalManagement/BasicModal'

import { editUser } from '../../api/user'
import { refreshToken } from '../../api/token'

import { Label, Input, ButtonStyled, CancelButton } from './styles'
import { Box } from '@mui/material'

type EditContactProps = {
  userCPF: string
  userEmail: string
  setEmail: (email: string) => void
  userPhone: string
  setPhone: (email: string) => void
  close: () => void
  hidden: boolean
}

export const EditContact = (
  { userCPF, userEmail, userPhone, close, hidden, setEmail, setPhone}: EditContactProps ) => {

  const cpf = userCPF

  const [newPhone, setNewPhone] = useState(userPhone)
  const [newEmail, setNewEmail] = useState(userEmail)

  const handleSaveChanges = async () => {
    await refreshToken()

    await editUser({
      cpf,
      email: newEmail,
      phoneNumber: newPhone.replace(/\D/g, ''),
    }).then((res) => {
      setEmail(newEmail)
      setPhone(newPhone)

      Alert({
        type: 'success',
        message: 'Informações de contato atualizadas com sucesso',
        autoClose: true,
      })
    }).catch((err) => {
      Alert({
        type: 'error',
        message: 'Erro ao atualizar informações de contato. Tente novamente.',
        autoClose: true,
      })
    })
    close()
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(e.target.value)
  }
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value.replace(/\D/g, '')
    .replace(/(\d{1,2})(\d{4,5})(\d{4})/, '($1) $2-$3')

    setNewPhone(phone)
  }

  useEffect(() => {
    setNewEmail(userEmail)
    setNewPhone(userPhone)
  },[userEmail, userPhone])


  return(
    <BasicModal title="Editar informações de contato" open={hidden} close={close}>
      <Label>E-mail</Label>
      <Input placeholder="Email" value={newEmail} onChange={handleEmailChange}/>

      <Label>Telefone</Label>
      <Input placeholder="Telefone" value={newPhone} onChange={handlePhoneChange}/>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '1.5rem'
      }}>
        <CancelButton onClick={close}>Descartar alterações</CancelButton>
        <ButtonStyled variant='contained' onClick={handleSaveChanges}>Salvar</ButtonStyled>
      </Box>

    </BasicModal>
  )
}