import { useState, useEffect, CSSProperties } from 'react'

import { Bread } from '../../../components/Bread/bread'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import GroupIcon from '@mui/icons-material/Group'
import AccountBoxIcon from '@mui/icons-material/AccountBox'

import { filterUsersDataTabbed, getHasResource, getIsAdmin} from '../../../api/user'
import { filterProfilesDataTabbed } from '../../../api/profile'

import { Alert } from '../../../components/Alert'
import { MainHeader } from '../../../components/Header'
import { CardManagement } from './components/CardManagement'

import { CardRowDataProfile } from "../../AllManagement/Management/components/CardManagement/types";

import { AddUser } from './Users/AddUser'
import { EditUser } from './Users/EditUser'
import DeleteUser from './Users/DeleteUser'

import { DeleteProfile } from './Profiles/DeleteProfile'
import { EditProfile } from './Profiles/EditProfile'
import { AddProfile } from './Profiles/AddProfile'
import { Footer } from '../../../components/Footer'

import { AlertTitle, Typography } from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { getLocalUserData } from '../../../services/localStorage'

interface ManagementProps {
  username: string
  state?: any
}

export const statusUserAction = async (user: any) => {
  return true
}

export const editUserAction = async (user: any) => {
  const response: any = await EditUser(
    user.id,
    user.name,
    user.email,
    user.profiles
  )

  if (response) {
    Alert({
      type: response.changed || response.deleted ? 'success' : 'error',
      message:
        response.changed || response.deleted
          ? 'Usuário editado com sucesso.'
          : 'Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.',
      autoClose: true,
    })
  }

  return response
}

export const deleteUserAction = async (user: any) => {
  const response: any = await DeleteUser(user.id, user.name)

  if (response) {
    Alert({
      type: response.changed ? 'success' : 'error',
      message: response.changed
        ? 'Usuário excluído com sucesso.'
        : 'Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.',
      autoClose: true,
    })
  }

  return response
}

export const editProfileAction = async (row: any) => {
  const response: any = await EditProfile(row.id, row.name)

  if (response) {
    Alert({
      type: response.changed ? 'success' : 'error',
      message: response.changed
        ? 'Informações de perfil de usuário alteradas com sucesso.'
        : 'Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.',
      autoClose: true,
    })
  }

  return response
}

export const deleteProfileAction = async (row: any) => {
  const response: any = await DeleteProfile(row.id, row.name)

  if (response) {
    Alert({
      type: response.changed ? 'success' : 'error',
      message: response.changed
        ? 'Perfil de usuário excluído com sucesso.'
        : 'Ops! Ocorreu um erro ao salvar as alterações. Tente novamente mais tarde.',
      autoClose: true,
    })
  }

  return response
}

export function Management({ username }: ManagementProps | any) {
  const [tab, setTab] = useState(0)
  const [tabs] = useState([
    {
      id: 0,
      label: 'Cadastrados',
      tabMargin: 30,
      tabLength: 120,
      icon: (style: CSSProperties) => <GroupIcon style={style} />,
    }, {
      id: 1,
      label: 'Pendentes',
      tabMargin: 30,
      tabLength: 200,
      icon: (style: CSSProperties) => <GroupIcon style={style} />,
    }, {
      id: 2,
      label: 'Perfis de usuário',
      tabMargin: 30,
      tabLength: 200,
      icon: (style: CSSProperties) => <AccountBoxIcon style={style} />,
    }
  ])

  const rowsPerPage = 10

  const [users, setUsers] = useState([])
  const [guests, setGuests] = useState([])
  const [profiles, setProfiles] = useState<CardRowDataProfile[]>([])

  const [userFilter, setUserFilter] = useState('')
  const [profileFilter, setProfileFilter] = useState('')
  const [guestFilter, setGuestFilter] = useState('')

  const [userCount, setUserCount] = useState(0)
  const [profileCount, setProfileCount] = useState(0)
  const [guestCount, setGuestCount] = useState(0)

  const [userCurrentPage, setUserCurrentPage] = useState(0)
  const [profileCurrentPage, setProfileCurrentPage] = useState(0)
  const [guestCurrentPage, setGuestCurrentPage] = useState(0)

  const [hasNationalAccess, setHasNationalAccess] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  
  const navigate = useNavigate()

  /* Load data */
  useEffect(() => {
    document.title = 'Gerenciamento de usuários'

    getHasResource("79").then((res) => {
      return res ? navigate('/') : null
    })
    
    fetchUserData('', 0)
    fetchProfileData('', 0)
    fetchGuestData('', 0)
    getIsAdmin().then((response) => {setIsAdmin(true)}).catch((error) => {setIsAdmin(false)})
    setHasNationalAccess(getLocalUserData().dados[0].acesso_nacional)
  }, [])

  useEffect(() => {
    fetchUserData(userFilter, userCurrentPage - 1)
  }, [userFilter, userCurrentPage])

  useEffect(() => {
    fetchUserData(guestFilter, guestCurrentPage - 1)
  }, [guestFilter, guestCurrentPage])

  useEffect(() => {
    fetchProfileData(profileFilter, profileCurrentPage - 1)
  }, [profileFilter, profileCurrentPage])


  /* Fetch data */
  const fetchUserData = (filter: string, currentPage: number) => {
    filterUsersDataTabbed(filter, rowsPerPage, currentPage * rowsPerPage)
      .then((data) => {
        let newUsers: any = Array(data.count)
        newUsers = data.results.map((user: any) => {
          return {
            id: user.cpf,
            name: user.nome_usuario,
            email: user.email,
            accessSystems: user.modulos,
            profiles: user.perfil,
            inclusionDate: user.data_criacao,
            status: user.active,
            externo: user.externo,
            statusAction: statusUserAction,
            editAction: editUserAction,
            deleteAction: deleteUserAction,
          }
        })

        setUsers(newUsers)
        setUserCount(data.count)
      })
      .catch((error) => {
        Alert({
          type: 'error',
          message:
            'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
          autoClose: true,
        })
      })
  };

  const fetchGuestData = (filter: string, currentPage: number) => {
    filterUsersDataTabbed(filter, rowsPerPage, currentPage * rowsPerPage, true)
      .then((data) => {
        let newGuests: any = Array(data.count)
        newGuests = data.results.map((user: any) => {
          return {
            id: user.cpf,
            name: user.nome_usuario,
            email: user.email,
            accessSystems: user.modulos,
            profiles: user.perfil,
            inclusionDate: user.data_criacao,
            status: user.active,
            externo: user.externo,
            statusAction: statusUserAction,
            editAction: editUserAction,
            deleteAction: deleteUserAction,
          }
        })

        setGuests(newGuests)
        setGuestCount(data.count)
      })
      .catch((error) => {
        Alert({
          type: 'error',
          message:
            'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
          autoClose: true,
        })
      })
  };

  const fetchProfileData = (filter: string, currentPage: number) => {
    if(isAdmin) {
      filterProfilesDataTabbed(filter, rowsPerPage, currentPage * rowsPerPage)
      .then((data) => {
        const newProfiles = data.results.map((profile: any) => {
          return {
            id: profile.id_perfil.toString(),
            name: profile.descricao_perfil,
            accessSystems: profile.modulos,
            profiles: [],
            inclusionDate: '01/01/2020',
            externo: false,
            editAction: editProfileAction,
            deleteAction: deleteProfileAction,
          }
        })

        setProfiles(newProfiles)
        setProfileCount(data.count)
      })
      .catch((error) => {
        Alert({
          type: 'error',
          message:
            'Ops! Ocorreu um erro ao carregar os dados. Tente novamente mais tarde.',
          autoClose: true,
        })
      })
    }
  };
  
  /* Functions */
  const handleTabChange = (newValue: number) => {
    setTab(newValue)
  }

  const getWidth = (tab: number) => {
    return tabs.find((item: any) => item.id === tab)?.tabLength
  }

  const calculateMargin = (tab: number) => {
    if (tab === 0) {
      return 0
    } else {
      const index = tabs.findIndex((item: any) => item.id === tab)

      return tabs
        .slice(0, index)
        .reduce((accumulator: number, currentValue: any) => {
          return accumulator + currentValue.tabLength + currentValue.tabMargin
        }, 0)
    }
  }

  return (
    <>
      <MainHeader subtitles={['Gerenciamento de usuários']} />
      <Bread texto={'Gerenciamento de usuários'}/>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '24px 0 32px 0',
          background: '#f2f5fd',
          '@media (min-width: 768px)': {
            width: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '90%',
            marginBottom: '18px',
          }}
        >
          {tabs.map((currentTab, index) => {
            
            return isAdmin || index !== 2 ? (
              <Button
                key={`tab-${index}`}
                variant='text'
                startIcon={currentTab.icon({
                  width: '24px',
                  height: '24px',
                })}
                style={{
                  color: tab === currentTab.id ? '#083CA6' : '#000',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textTransform: 'none',
                  padding: '5px 10px',
                  marginRight: `${currentTab.tabMargin}px`,
                  width: currentTab.tabLength,
                }}
                onClick={() => handleTabChange(currentTab.id)}
              >
                {currentTab.label}
              </Button>
            ) : null;
          })}
        </Box>
        <Box
          sx={{
            height: '6px',
            width: '90%',
            borderRadius: '6px',
            background: '#B6C4EE',
          }}
        >
          <Box
            sx={{
              height: '6px',
              width: `${getWidth(tab)}px`,
              borderRadius: '6px',
              marginLeft: `${calculateMargin(tab)}px`,
              transition: 'all 0.3s ease-in-out',
              background: '#083CA6',
            }}
          ></Box>
        </Box>
        { !hasNationalAccess ? (
          <Box sx={{width: '90%', display: 'flex', padding: '24px', marginTop: '24px', backgroundColor: '#FFFFFF', borderRadius: '8px'}}>
            <Box sx={{height: 'fit-content', padding: '14px', backgroundColor: '#F2F5FD', borderRadius: '250px'}}>
              <InfoIcon sx={{color: '#083CA6', fontSize: '20px'}} />
            </Box>
            <Box sx= {{ marginLeft: '24px' }}>
              <AlertTitle sx={{fontSize: '14px', lineHeight: '150%', color: '#002577', fontFamily: 'Inter, sans-serif', fontWeight: 600}}>Atenção</AlertTitle>
              <Typography sx={{fontSize: '14px', lineHeight: '150%', color: '#002577', fontFamily: 'Inter, sans-serif'}}>
                É possível que coordenadores e/ou professores da sua escola já tenham acessado à plataforma e realizado seu cadastro. Basta verificar, através do campo de busca abaixo, se o cadastro já está ativo. A etiqueta <span style={{width: 'fit-content', height: 'fit-content',color: '#196416', fontSize: '12px', padding: '4px 8px', background: '#F4FDF4'}}>Externo</span> indica que o usuário ainda não foi associado à mesma escola que você. Clique em “Editar” para atualizar as informações do cadastro correspondente.
              </Typography>
            </Box>
          </Box>
        ) : null }
        <Box sx={{ marginTop: '21px' }}>
          {tab === 0 && (
            <CardManagement
              profile={false}
              pendency={false}
              rowsPerPage={rowsPerPage}
              buttonAction={async () => AddUser()}
              data={users}
              dataCount={userCount}
              isAdmin={isAdmin}
              handleFilter={setUserFilter}
              handleCurrentPage={setUserCurrentPage}
            />
          )}
          {tab === 1 && (
            <CardManagement
              profile={false}
              pendency={true}
              rowsPerPage={rowsPerPage}
              buttonAction={async () => AddUser()}
              data={guests}
              dataCount={guestCount}
              isAdmin={isAdmin}
              handleFilter={setGuestFilter}
              handleCurrentPage={setGuestCurrentPage}
            />
          )}
          {tab === 2 && (
            <CardManagement
              profile={true}
              pendency={false}
              rowsPerPage={rowsPerPage}
              buttonAction={async () => AddProfile()}
              data={profiles}
              dataCount={profileCount}
              isAdmin={isAdmin}
              handleFilter={setProfileFilter}
              handleCurrentPage={setProfileCurrentPage}
            />
          )}
        </Box>
      </Box>
      <Footer />
    </>
  )
}
