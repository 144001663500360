import { MainHeader } from "../../../components/Header";
import { Bread } from "../../../components/Bread/bread";
import TableManageClasses from "./components/TableManageClasses";
import Box from "@mui/material/Box";
import TopCardScholls from "./components/TopCardScholls";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../../components/Footer";

import { useEffect } from "react";
import { getHasResource } from "../../../api/user";

export default function ManageClasses() {  
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Gerenciar Turmas";
    getHasResource("79").then((res) => {
      return res ? navigate('/') : null
    })
  }, [])

  const { idEscola = "" } = useParams();
  return (
    <>
      <MainHeader
        subtitles={["Gerenciamento de turmas e alunos"]}
      />
      <Bread texto={"Gerenciamento de turmas e alunos"}/>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          padding: "24px 0 32px 0",
          background: "#f2f5fd",
          "@media (min-width: 768px)": {
            width: "100%",
          },
        }}
      >
        <Box sx={{ marginTop: "21px" }}>
          <TopCardScholls idEscola={idEscola} />
          <TableManageClasses idEscola={idEscola} />
        </Box>
      </Box>
      <Footer />
    </>
  );
}
