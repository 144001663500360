import React, { useState, useEffect } from 'react'

import BasicModal from '../../../../../components/ModalManagement/BasicModal'

import {
  FormControl,
  Button,
  Box,
  Stack,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@mui/material'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Error } from '@mui/icons-material'
import { deleteSchool } from '../../../../../api/school'
import { Alert } from '../../../../../components/Alert'

type Props = {
  open: boolean
  close: (value: boolean) => void
  update: () => void
  data: any
}

export default function DeleteSchool(props: Props) {
  const [openDelete, setOpenDelete] = useState(false)
  const formik = useFormik({
    initialValues: {
      accept: false,
    },
    validationSchema: yup.object({
      accept: yup
        .bool()
        .isTrue('É necessário confirmar para poder excluir a escola.'),
    }),
    onSubmit: () => {
      deleteSchool(props.data?.id_escola)
        .then((res) => {
          Alert({
            type: 'success',
            message: 'Escola excluída com sucesso!',
            autoClose: true,
          })
          props.close(false)
          props.update()
        })
        .catch((error) => {
          Alert({
            type: 'error',
            message: 'Ops! Ocorreu um erro ao tentar apagar a escolas.',
            autoClose: true,
          })
          props.close(false)
        })
    },
  })

  useEffect(() => {
    setOpenDelete(props.open)
  }, [props.open])

  useEffect(() => {
    props.close(openDelete)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!openDelete])

  return (
    <BasicModal
      title={`Tem certeza que deseja excluir a escola ${props.data?.nome_escola}?`}
      open={openDelete}
      close={setOpenDelete}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '16px',
          gap: '16px',
          width: '100%',
          alignSelf: 'stretch',
          flexGrow: 0,
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth>
            <Box>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Box
                  className=''
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '8px',
                    gap: '16px',

                    background: '#FEF2F2',
                    borderRadius: '4px',

                    color: '#920000',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '14px',
                  }}
                >
                  <Box>
                    <Error className='text-[40px]' />
                  </Box>
                  <Box>
                    <strong className='font-bold'>Atenção!</strong> Esta ação
                    não pode ser desfeita. Ao excluir esta escola, as turmas e
                    os alunos também serão excluídos das bases de dados da
                    plataforma.
                  </Box>
                </Box>
              </Stack>
            </Box>
            <FormControl
              sx={{ paddingX: '4px', marginTop: '24px' }}
              error={!!formik.errors.accept}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id='accept'
                    name='accept'
                    onChange={formik.handleChange}
                    value='true'
                    checked={formik.values.accept}
                  />
                }
                label='Confirmo que desejo excluir esta escola e estou ciente que esta é uma ação que não pode ser desfeita.'
              />
              <FormHelperText>
                {formik.touched.accept && formik.errors.accept}
              </FormHelperText>
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '32px',
              }}
            >
              <Button
                id='cancel-button-delete'
                variant='text'
                style={{
                  color: 'black',
                  textTransform: 'none',
                  fontWeight: '700',
                }}
                disableElevation
                type='reset'
                onClick={() => props.close(false)}
              >
                Cancelar
              </Button>

              <Button
                id='submit-button-delete'
                disableElevation
                style={{
                  backgroundColor: '#920000',
                  textTransform: 'none',
                  fontWeight: '700',
                  color: 'white',
                }}
                type='submit'
              >
                Excluir turma
              </Button>
            </Box>
          </FormControl>
        </form>
      </Box>
    </BasicModal>
  )
}
