import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SchollsService from '../../../../../services/scholls'
import { getHasResource } from '../../../../../api/user'

import { ITopCardScholls, IScholl} from './types'

import EditSchool from '../../../ManageSchools/components/EditSchoolForm'
import { FrameRoot, FlexRow, ButtonBase, Arrowleft, FlexColumn, 
  FlexColumn5, FlexRow1, FlexColumn1, Text1, Text2, FlexRow2, 
  ButtonBase1, Text4, ButtonLabel, Button1, Paragraph } from '../../../styles'

const TopCardScholls: React.FC<ITopCardScholls> = ({ idEscola }) => {
  const navigate = useNavigate()

  const [openEdit, setOpenEdit] = useState(false)

  const [scholl, setScholl] = useState({} as IScholl)
  const { getSchollById } = SchollsService()

  const [hasEditSchoolPermission, setHasEditSchoolPermission] = useState(false)
  
  useEffect(() => {
    if (!idEscola) return
    getSchollById(idEscola).then((res) => {
      setScholl(res.data)
    })

    getHasResource("62").then((res) => {
      setHasEditSchoolPermission(res)
    })
  }, [])

  const updateSchoolData = (idEscola: any) => {
    getSchollById(idEscola).then((res) => {
      setScholl(res.data)
    })
  }

  return (
    <FrameRoot>
      <FlexRow>
        <Button1 id='class-card-0' onClick={() => navigate(-1)}>
          <ButtonBase>
            <Arrowleft
              src={'https://file.rendit.io/n/ypvrNQ9X9TjTML4xtgS6.svg'}
            />
          </ButtonBase>
        </Button1>
        <FlexColumn className='space-y-[30px]'>
          <FlexRow1 alignItems={'center'}>
            <FlexColumn1 width={'550px'} id='class-card-1'>
              <Text1>Escola</Text1>
              <Text2>{scholl.nome_escola || '--'}</Text2>
            </FlexColumn1>
            <FlexRow2 alignItems={'center'}>
              <FlexColumn1 width={'350px'}>
                <Text1 id='class-card-2'>Cidade</Text1>
                <Text4 id='class-card-6'>{scholl.municipio || '--'}</Text4>
              </FlexColumn1>
              {
                hasEditSchoolPermission && (
                  <Button1 id='class-card-3' onClick={() => setOpenEdit(true)}>
                    <ButtonBase1 id='class-card-7'>
                      <Arrowleft
                        src={'https://file.rendit.io/n/NsBl866ZZ626jS4u3pvm.svg'}
                      />
                      <ButtonLabel>Editar escola</ButtonLabel>
                    </ButtonBase1>
                  </Button1>
              )}
            </FlexRow2>
          </FlexRow1>

          <FlexRow1 alignItems={'center'}>
            <FlexColumn1 width={'550px'}>
              <Text1>Endereço</Text1>
              <Paragraph id='class-card-5' alignSelf={'stretch'}>
                {scholl.endereco || '--'}
              </Paragraph>
            </FlexColumn1>
            <FlexRow2 alignItems={'flex-start'}>
              <FlexColumn1 width={'350px'}>
                <Text1>Gestor escolar</Text1>
                <Text4 id='class-card-8'>{scholl.gestor_escolar || '--'}</Text4>
              </FlexColumn1>
              <FlexColumn5>
                <Text1>Estado</Text1>
                <Paragraph id='class-card-9' alignSelf={'inherit'}>
                  {scholl.uf || '--'}
                </Paragraph>
              </FlexColumn5>
            </FlexRow2>
          </FlexRow1>
        </FlexColumn>
      </FlexRow>
      {/* EDIT MODAL */}
      <EditSchool
        open={openEdit}
        close={setOpenEdit}
        data={scholl}
        update={() => updateSchoolData(idEscola)}
      />
    </FrameRoot>
  )
}

export default TopCardScholls